export const SUPER_ADMIN = 'SUPER_ADMIN';
export const ADMIN_COMMUNITY = 'ADMIN_COMMUNITY';
export const ADMIN_BASIC = 'ADMIN_BASIC';
export const ADMIN = 'ADMIN';
export const HEADQUARTERS = 'HEADQUARTERS';
export const MORTGAGE_BANKER = 'MORTGAGE_BANKER';
export const TEAM_LEADER = 'TEAM_LEADER';
export const DEVELOPER_HEADQUARTERS = 'DEVELOPER_HEADQUARTERS';
export const DEVELOPER_BRANCH_LEADER = 'DEVELOPER_BRANCH_LEADER';
export const DEVELOPER_BRANCH_LEADER_SUPERVISOR = 'DEVELOPER_BRANCH_LEADER_SUPERVISOR';
export const DEVELOPER_BRANCH_LEADER_SUPERVISOR_II = 'DEVELOPER_BRANCH_LEADER_SUPERVISOR_II';
export const DEVELOPER_TEAM_LEADER = 'DEVELOPER_TEAM_LEADER';
export const DEVELOPER_EXECUTIVE = 'DEVELOPER_EXECUTIVE';
export const DEVELOPER_SITEPLAN = 'DEVELOPER_SITEPLAN';
export const AFFILIATE_I = 'AFFILIATE_I';
export const AFFILIATE_II = 'AFFILIATE_II';

export const ALLOWED_GROUPS = [
  SUPER_ADMIN,
  ADMIN,
  ADMIN_COMMUNITY,
  ADMIN_BASIC,
  DEVELOPER_BRANCH_LEADER,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR_II,
  DEVELOPER_EXECUTIVE,
  DEVELOPER_TEAM_LEADER,
  DEVELOPER_HEADQUARTERS,
  DEVELOPER_SITEPLAN,
];

export const HIGH_LEVEL_GROUPS = [
  SUPER_ADMIN,
  ADMIN,
  ADMIN_COMMUNITY,
  ADMIN_BASIC,
  DEVELOPER_BRANCH_LEADER,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR_II,
  DEVELOPER_HEADQUARTERS,
];

export const hasLimitedAccess = (auth) => {
  const hasAccess = (checkGroups = []) => {
    if (auth && auth.groups && auth.groups.length > 0) {
      return auth.groups.some(g => checkGroups.some(ag => ag === g.code));
    }
    return false;
  };

  // check for agent role
  const isAgent = auth
    && auth.affiliate
    && auth.affiliate.companyBranch
    && auth.affiliate.companyBranch.isExternal;

  // check for staff role
  const hasDevExecutive = hasAccess([DEVELOPER_EXECUTIVE]);
  const isStaff = !isAgent && hasDevExecutive;

  // check for site plan developer role
  const isSitePlanDeveloper = hasAccess([DEVELOPER_SITEPLAN]);

  if (isAgent || isSitePlanDeveloper || isStaff) {
    return true;
  }
  return false;
};

import Endpoints from '../../endpoints';
import fetchUrl from '../../utils/fetchUrl';
import { handleError, resJson } from '../../utils/error';
import { getToken } from '../helpers';

import Types from './types';

const currencySettingRequest = () => ({
  type: Types.CURRENCY_SETTING_REQUEST,
});

const currencySettingSuccess = ({ data, token }) => ({
  type: Types.CURRENCY_SETTING_SUCCESS,
  payload: {
    data,
    token,
  },
});

const currencySettingFailure = message => ({
  type: Types.CURRENCY_SETTING_FAILURE,
  payload: {
    statusText: message,
  },
});

const currencySettingReset = () => ({
  type: Types.CURRENCY_SETTING_RESET,
});

const setCurrencySettingLoading = (loading = false) => ({
  type: Types.CURRENCY_SETTING_SET_LOADING,
  payload: {
    loading,
  },
});

const fetchCurrencySetting = (companyID) => {
  const url = `${Endpoints.CURRENCY_SETTING(companyID)}/`;
  const options = {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let token;
  return (dispatch) => {
    dispatch(currencySettingRequest());
    return dispatch(fetchUrl(url, options))
      .then((res) => {
        token = getToken(res);
        return resJson(res);
      })
      .then((data) => {
        dispatch(currencySettingSuccess({ data, token }));
        return data;
      })
      .catch(err => dispatch(handleError(err, currencySettingFailure)));
  };
};

export default {
  setCurrencySettingLoading,

  currencySettingRequest,
  currencySettingSuccess,
  currencySettingFailure,

  currencySettingReset,

  fetchCurrencySetting,
};

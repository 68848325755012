/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { utils } from '@mhub/web-components';

import Selectors from '../redux/selectors';

import Button from './Button';

import icMagnifying from '../assets/icons/ic-magnifying.svg';
import iconClose from '../assets/icons/ic-close.svg';

import { HIGH_LEVEL_GROUPS, hasLimitedAccess } from '../constants/userGroups';
import { namespaces, TRANSLATION_FILES } from '../constants/translation';

import AppMenuButton from './AppMenuButton';
import DetailField from './DetailField';
import SpecificationField from './SpecificationField';
import SizeSuffix from './SizeSuffix';
import LoanAmount from './LoanAmount';
import LoadImage from './LoadImage';
import ImageViewer from './ImageViewer';

import { spaFullNumber } from '../utils/amount';
import { translateModels } from '../utils/translation';

import './UnitDetail.css';
import './Modal.css';
import { isForeignCompany } from '../utils/companies';

class UnitDetail extends PureComponent {
  static mapStateToProps = state => ({
    auth: Selectors.getAuthenticateData(state),
    currencySetting: Selectors.getCurrencySettingData(state),
    project: Selectors.getProjectData(state),
    unit: Selectors.getProjectUnitData(state),
    unitLoading: Selectors.getProjectUnitLoading(state),
    unitLayout: Selectors.getProjectUnitLayoutData(state),
    unitLayoutLoading: Selectors.getProjectUnitLayoutLoading(state),
    pictures: Selectors.getProjectUnitLayoutPicturesData(state),
    picturesLoading: Selectors.getProjectUnitLayoutPicturesLoading(state),
  })
  static propTypes = {
    auth: PropTypes.shape({
      groups: PropTypes.array,
      awsUsername: PropTypes.string,
    }),
    currencySetting: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    project: PropTypes.shape({
      id: PropTypes.string,
      ownershipType: PropTypes.string,
      propertyCategoryID: PropTypes.string,
      propertyCategory: PropTypes.shape({
        name: PropTypes.string,
      }),
      sizeSuffix: PropTypes.string,
      company: PropTypes.shape({
        countryCode: PropTypes.string,
        currencySymbol: PropTypes.string,
      }),
    }),
    unit: PropTypes.shape({
      reference: PropTypes.string,
      size: PropTypes.number,
      landArea: PropTypes.number,
      isBumi: PropTypes.bool,
      hsd: PropTypes.string,
      ptd: PropTypes.string,
      zoneOrBlock: PropTypes.string,
      streetNumberOrLevel: PropTypes.string,
      streetOrFacing: PropTypes.string,
      specifications: PropTypes.arrayOf(PropTypes.object),
    }),
    unitLayout: PropTypes.shape({
      name: PropTypes.string,
      specifications: PropTypes.arrayOf(PropTypes.object),
      virtualTourURL: PropTypes.string,
    }),
    pictures: PropTypes.arrayOf(PropTypes.object),
    headerNode: PropTypes.func,
    footerNode: PropTypes.func,
    loaderNode: PropTypes.func,
    onClose: PropTypes.func,
    active: PropTypes.bool,
    side: PropTypes.oneOf(['left', 'right']),
    unitLoading: PropTypes.bool,
    unitLayoutLoading: PropTypes.bool,
    picturesLoading: PropTypes.bool,
  }
  static contextTypes = {
    store: PropTypes.object,
  }
  static defaultProps = {
    auth: null,
    currencySetting: null,
    project: null,
    unit: null,
    unitLayout: null,
    pictures: [],
    headerNode: ({ t, unit }) => <h4>{unit ? unit.reference : `${t('Loading', { ns: TRANSLATION_FILES.translation })}...`}</h4>,
    footerNode: null,
    loaderNode: ({ t }) => <p>{t('Loading', { ns: TRANSLATION_FILES.translation })}...</p>,
    onClose: null,
    active: false,
    side: 'right',
    unitLoading: false,
    unitLayoutLoading: false,
    picturesLoading: false,
  }
  state = {
    showImageViewer: false,
  }
  getUnitClassName = (status) => {
    switch (status) {
      case 'new':
        return 'new';
      case 'reserved':
      case 'developer_reserved':
        return 'reserved';
      case 'booked':
      case 'developer_booked':
        return 'booked';
      case 'sold_spa_signed':
      case 'developer_sold':
        return 'sold';
      default:
        return 'delisted';
    }
  }
  getUnitStatusText = (status, project, auth) => {
    const isSimpleMode = project && project.siteplanSettings && project.siteplanSettings.siteplanSimpleMode;
    if (hasLimitedAccess(auth) && isSimpleMode) {
      switch (status) {
        case 'new':
          return 'Available';
        case 'reserved':
        case 'developer_reserved':
        case 'booked':
        case 'developer_booked':
        case 'sold_spa_signed':
        case 'developer_sold':
          return 'Unavailable';
        default:
          return 'Unavailable';
      }
    }
    switch (status) {
      case 'new':
        return 'Available';
      case 'reserved':
      case 'developer_reserved':
        return 'Reserved';
      case 'booked':
      case 'developer_booked':
        return 'Booked';
      case 'sold_spa_signed':
      case 'developer_sold':
        return 'Sold';
      default:
        return 'Delisted';
    }
  }
  getUnitPrice = (unit, project, auth) => {
    const isSimpleMode = project && project.siteplanSettings && project.siteplanSettings.siteplanSimpleMode;
    if (hasLimitedAccess(auth) && isSimpleMode && unit && unit.status) {
      const statusText = this.hasAccess(HIGH_LEVEL_GROUPS) ? unit.status.name : this.getUnitStatusText(unit.statusID, project, auth);
      if (statusText === 'Unavailable') return 0;
    }
    return spaFullNumber(unit);
  }
  modalRef = React.createRef();
  // specification filter
  filterSpecification = (spec) => {
    switch (spec.name.toLowerCase()) {
      case 'bathrooms':
      case 'bedrooms':
      case 'parking lots':
        return true;
      default:
        return false;
    }
  }
  hasAccess = (checkGroups = []) => {
    const { auth } = this.props;
    if (auth && auth.groups && auth.groups.length > 0) {
      return auth.groups.some(g => checkGroups.some(ag => ag === g.code));
    }
    return false;
  }
  handleOnPreview = () => this.setState({ showImageViewer: true });
  handleCloseImageViewer = () => this.setState({ showImageViewer: false })
  renderStatus = (unit, project, auth) => {
    const { t } = this.props;
    if (unit && unit.status) {
      const statusText = t(this.hasAccess(HIGH_LEVEL_GROUPS) ? unit.status.name : this.getUnitStatusText(unit.statusID, project, auth), { ns: TRANSLATION_FILES.constants.unitStatus });
      return (
        <div>
          <div className={`status ${this.getUnitClassName(unit.statusID)}`}>
            <div className="box" />
            {statusText || `${t('Loading', { ns: TRANSLATION_FILES.translation })}...`}
          </div>
          {this.renderLaunchMode(unit)}
        </div>
      );
    }
    return null;
  }
  renderLaunchMode = (unit) => {
    const { t } = this.props;
    if (
      this.props.auth &&
      unit &&
      unit.project &&
      unit.project.isLaunchMode &&
      moment().isBefore(moment(unit.reservedTimeoutExpireAt)) &&
      unit.reservedByAwsUser !== this.props.auth.awsUsername
    ) {
      return (
        <div className="status">
          <img alt="Unit is currently viewed by someone." src="https://book-dev.mhub.my/assets/images/icons/eye_icon.png" style={{ paddingRight: '0.25em', height: '12px' }} />
          {t('Currently being viewed by someone')}.
        </div>
      );
    }
    return null;
  }
  renderHeader = () => {
    const { headerNode, unit, unitLayout, onClose, t } = this.props;
    return (
      <div className="header">
        {
          window.FlutterApp && window.FlutterApp.postMessage
            ? <div className="placeholder" />
            : null
        }
        {headerNode({ t, unit, unitLayout, onClose })}
        <AppMenuButton
          icon={iconClose}
          onClick={onClose}
        />
      </div>
    );
  }
  renderFooter = () => {
    const { footerNode, unit, unitLayout, onClose } = this.props;
    if (footerNode) {
      const content = footerNode({ unit, unitLayout, onClose });
      if (content) {
        return (
          <div className="footer">{content}</div>
        );
      }
    }
    return null;
  }
  renderSpecifications = (data) => {
    const { t } = this.props;
    const specifications = data ? data.filter(this.filterSpecification) : [];
    if (utils.arrayHasItems(specifications)) {
      return (
        <Fragment>
          <DetailField header>{t('Layout Information')}</DetailField>
          {
            specifications.map(spec => (
              <SpecificationField
                key={spec.name}
                name={spec.name}
                value={spec.value}
              />
            ))
          }
        </Fragment>
      );
    }
    return null;
  }
  renderLoader = () => {
    const { loaderNode, t } = this.props;
    return (
      <div className="content loading">
        {loaderNode({ t })}
      </div>
    );
  }
  renderContent = () => {
    const { project, unit, unitLayout, pictures, unitLoading, unitLayoutLoading, picturesLoading, currencySetting, t } = this.props;
    const projectCompany = project && project.company ? project.company : null;
    if (unitLoading || unitLayoutLoading || picturesLoading) {
      return this.renderLoader();
    }
    if (project && unit && unitLayout) {
      const isLanded = project.propertyCategoryID === 'home_land';
      const price = this.getUnitPrice(unit, project, this.props.auth);
      return (
        <div className="content" ref={this.modalRef}>
          {this.renderStatus(unit, project, this.props.auth)}
          {unitLayout && utils.arrayHasItems(unitLayout.specifications) && this.renderSpecifications(unitLayout.specifications)}
          <DetailField header>{t('Unit Information')}</DetailField>
          <DetailField capital label={t('Category')}>
            {t(isLanded ? 'Landed' : project.propertyCategory.name, { ns: TRANSLATION_FILES.constants.propertyCategory })}
          </DetailField>
          <DetailField label={translateModels(t, 'size', 'projectUnit')}>
            <SizeSuffix
              size={unit.size}
              suffix={project.sizeSuffix}
            />
          </DetailField>
          {
            unit.landArea !== 0 ? (
              <DetailField label={translateModels(t, 'landArea', 'projectUnit')}>
                <SizeSuffix
                  size={unit.landArea}
                  suffix={project.sizeSuffix}
                />
              </DetailField>
            ) : null
          }
          {
            price > 0 ? (
              <DetailField label={t('Price')}>
                <LoanAmount
                  format="0,000,000.00"
                  prefix={projectCompany && isForeignCompany(projectCompany) && projectCompany.currencySymbol ? projectCompany.currencySymbol : 'RM'}
                  amount={price}
                  currencySetting={currencySetting}
                  isCurrencySettingFormat={isForeignCompany(projectCompany)}
                />
              </DetailField>
            ) : null
          }
          {unit.isBumi ? (<DetailField capital label={translateModels(t, 'isBumi', 'projectUnit')}>{t('Yes', { ns: TRANSLATION_FILES.translation })}</DetailField>) : null}
          {unit.hsd && <DetailField label={translateModels(t, 'hsd', 'projectUnit')}>{unit.hsd}</DetailField>}
          {unit.ptd && <DetailField label={translateModels(t, 'ptd', 'projectUnit')}>{unit.ptd}</DetailField>}
          {unit.zoneOrBlock && <DetailField label={t(isLanded ? 'Zone' : 'Block')}>{unit.zoneOrBlock}</DetailField>}
          {unit.streetNumberOrLevel && <DetailField label={t(isLanded ? 'Street No.' : 'Level')}>{unit.streetNumberOrLevel}</DetailField>}
          {unit.streetOrFacing && <DetailField label={t(isLanded ? 'Street' : 'Facing')}>{unit.streetOrFacing}</DetailField>}
          <DetailField capital preview label={translateModels(t, 'projectUnitLayoutID', 'projectUnit')} className="floorplan layoutType">
            {
              utils.arrayHasItems(pictures) ? (
                <Fragment>
                  <button
                    className="preview"
                    onClick={this.handleOnPreview}
                  >
                    {unitLayout.name}
                    <img src={icMagnifying} alt="Zoom In" />
                  </button>
                  {
                      unitLayout.virtualTourURL ? (
                        <a href={unitLayout.virtualTourURL} target="_blank" rel="noopener noreferrer"><Button>{translateModels(t, 'virtualTourURL', 'projectUnit')}</Button></a>
                      ) : null
                  }
                  <div className="pictures">
                    <LoadImage
                      parent={this.modalRef.current}
                      key={pictures[0].id}
                      pictureKey={pictures[0].key}
                      size="medium"
                      alt={pictures[0].label}
                      onClick={this.handleOnPreview}
                    />
                  </div>
                </Fragment>
              ) : (
                <div className="unitLayoutNameOnly">
                  {unitLayout.name}
                  {
                    unitLayout.virtualTourURL ? (
                      <a href={unitLayout.virtualTourURL} target="_blank" rel="noopener noreferrer"><Button>{translateModels(t, 'virtualTourURL', 'projectUnit')}</Button></a>
                    ) : null
                  }
                </div>
              )
            }
          </DetailField>
          {
            unit.specifications && utils.arrayHasItems(unit.specifications) ? (
              <Fragment>
                <DetailField header>{t('Unit Specifications')}</DetailField>
                {
                unit.specifications.slice(0, 10).map((unitSpecs, idx) => (
                  <DetailField key={unitSpecs.id || idx} label={unitSpecs.name}>
                    {unitSpecs.value}
                  </DetailField>
                ))
                }
              </Fragment>
            ) : null
          }
        </div>
      );
    }
    return this.renderLoader();
  }
  render() {
    const { active, onClose, side } = this.props;
    const { showImageViewer } = this.state;
    return (
      <Fragment>
        <Modal
          isOpen={active}
          onRequestClose={onClose}
          closeTimeoutMS={500}
          className={`modal side ${side}`}
          overlayClassName="modal-overlay"
          ariaHideApp={false}
        >
          <div className="UnitDetail">
            {this.renderHeader()}
            {this.renderContent()}
            {this.renderFooter()}
          </div>
        </Modal>
        <ImageViewer
          active={showImageViewer}
          onClose={this.handleCloseImageViewer}
        />
      </Fragment>
    );
  }
}

export default withTranslation([
  ...namespaces,
  TRANSLATION_FILES.models.projectUnit,
  TRANSLATION_FILES.constants.unitStatus,
  TRANSLATION_FILES.constants.propertyCategory,
])(connect(UnitDetail.mapStateToProps)(UnitDetail));

import { createReducer } from '@mhub/web-redux-utilities';
import Types from './types';

const initialState = {
  isOK: true,
};

export default createReducer(initialState, {
  [Types.NETWORK_OK]: state => (
    Object.assign({}, state, {
      isOK: true,
    })
  ),
  [Types.NETWORK_FAILURE]: state => (
    Object.assign({}, state, {
      isOK: false,
    })
  ),
});

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './AppMenuButton.css';

const colors = ['default', 'white', 'grey'];

export default class AppMenuButton extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string.isRequired,
    basic: PropTypes.bool,
    disabled: PropTypes.bool,
    color: PropTypes.oneOf(colors),
  }
  static defaultProps = {
    className: null,
    onClick: null,
    basic: false,
    disabled: false,
    color: colors[0],
  }
  classNames = () => {
    const { className, basic, color } = this.props;
    const names = ['AppMenuButton'];
    if (className) {
      names.push(className);
    }
    if (basic) {
      names.push('basic');
    }
    if (colors.some(c => c === color) && color !== colors[0]) {
      names.push(color);
    }
    return names.join(' ');
  }
  handleOnClick = (e) => {
    const { onClick, disabled } = this.props;
    if (!disabled && onClick) {
      e.persist();
      onClick(e);
    }
  }
  render() {
    const { basic, icon } = this.props;
    const classNames = this.classNames();
    if (basic) {
      return (
        <div
          className={classNames}
          onClick={this.handleOnClick}
        >
          <img src={icon} alt="Button icon" />
        </div>
      );
    }
    return (
      <button
        className={classNames}
        onClick={this.handleOnClick}
      >
        <img src={icon} alt="Button icon" />
      </button>
    );
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Dropdown, Label } from '@mhub/web-components';

import Selectors from '../redux/selectors';

import './CategoryFilter.css';
import UNIT_CATEGORY from '../constants/unitCategory';
import { namespaces, TRANSLATION_FILES } from '../constants/translation';

class CategoryFilter extends PureComponent {
  static mapStateToProps = state => ({
    auth: Selectors.getAuthenticateData(state),
  })
  static propTypes = {
    auth: PropTypes.shape({
      groups: PropTypes.array,
    }),
    onChange: PropTypes.func,
    value: PropTypes.string,
    floating: PropTypes.bool,
  }
  static contextTypes = {
    store: PropTypes.object,
  }
  static defaultProps = {
    auth: null,
    onChange: null,
    value: null,
    floating: false,
  }
  constructor(props) {
    super(props);
    this.state = {
      options: UNIT_CATEGORY,
    };
  }
  classNames = () => {
    const { floating, value } = this.props;
    const names = ['CategoryFilter'];
    if (value) {
      names.push(value);
    }
    if (floating) {
      names.push('floating');
    }
    return names.join(' ');
  }
  render() {
    const { onChange, value, floating, t } = this.props;
    const { options } = this.state;
    const classNames = this.classNames();
    const placeholder = t(floating ? 'Select {{name}}' : 'None', { name: t('Category') })
    const child = (
      <Dropdown
        className={classNames}
        bordered
        fluid={!floating}
        withPortal
        name="category"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        options={[
          {
            title: placeholder,
            value: null,
          },
        ].concat(options.map(s => ({
            className: s.value,
            title: t(s.text, { ns: TRANSLATION_FILES.constants.unitCategory }),
            value: s.value,
        })))}
      />
    );
    if (floating) {
      return child;
    }
    return (
      <Label content={t('Category')} noHelper>
        {child}
      </Label>
    );
  }
}

export default withTranslation([
  ...namespaces,
  TRANSLATION_FILES.constants.unitCategory,
])(connect(CategoryFilter.mapStateToProps)(CategoryFilter));

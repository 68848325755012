import React, { Component } from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import config from '../config';

import Actions from '../redux/actions';
import Selectors from '../redux/selectors';

import Button from '../components/Button';
import SVGViewer from '../components/SVGViewer';

import { getItem, setItem } from '../utils/storage';

import './MHubSiteplanWidget.css';
import UnitDetail from '../components/UnitDetail';

class MHubSiteplanWebpackWidget extends Component {
  static mapStateToProps = state => ({
    marketplaceURL: Selectors.getAppMarketplaceURL(state),
    project: Selectors.getProjectData(state),
    projectIsError: Selectors.getProjectIsError(state),
    projectUnitsRefreshAt: Selectors.getProjectUnitsRefreshAt(state),
    unitIsLoading: Selectors.getProjectUnitLoading(state),
    isAuthenticated: Selectors.getAuthenticateIsAuthenticated(state),
  })
  static propTypes = {
    projectID: PropTypes.string,
    project: PropTypes.shape({
      siteplanID: PropTypes.string,
      siteplanAppEnabled: PropTypes.bool,
      salesChartType: PropTypes.string,
    }),
    isAuthenticated: PropTypes.bool,
    projectIsError: PropTypes.bool,
    projectUnitsRefreshAt: PropTypes.string,
    unitIsLoading: PropTypes.bool,
    marketplaceURL: PropTypes.string,
  }
  static contextTypes = {
    store: PropTypes.object,
  }
  static defaultProps = {
    project: null,
    projectID: '',
    projectIsError: false,
    projectUnitsRefreshAt: null,
    unitIsLoading: false,
    isAuthenticated: false,
    marketplaceURL: '',
  }
  constructor(props) {
    super(props);
    const projectID = props.projectID || '';
    this.state = {
      prevProps: { projectID },
      projectID,
      selectedUnitID: null,
      selectedUnitLayoutID: null,
      autoReload: false,
      autoReloadTimeout: 20000,
      showUnitDetail: false,
    };
  }
  static getDerivedStateFromProps(props, state) {
    const { prevProps } = state;
    // Compare the incoming prop to previous prop
    const projectID = props.projectID || '';
    return {
      prevProps: { projectID },
      projectID,
      svgLoaded: prevProps.projectID === projectID && state.svgLoaded,
    };
  }

  async componentDidMount() {
    const localAutoReloadValue = getItem(`${config.app.name}.sitePlanAutoReload`);
    if (localAutoReloadValue === 'true') {
      await this.setComponentState({ autoReload: true });
    }

    const projectID = this.props.projectID || this.state.projectID;
    if (projectID && this.props.isAuthenticated) {
      this.clearAutoReloadTimer();
      await this.handleResetProject();
      await this.handleResetUnitDetail();
      this.handleRefresh(projectID);
      this.setAutoReloadTimer();
    }
  }
  async componentDidUpdate(prevProps, prevState) {
    const { projectID, svgLoaded } = this.state;
    if (this.props.isAuthenticated) {
      if (projectID && projectID !== prevState.projectID) {
        await this.handleResetProject();
        this.handleRefresh(projectID);
      }
      if (svgLoaded && !prevState.svgLoaded) {
        this.setAutoReloadTimer();
      }
    }
  }
  componentWillUnmount() {
    this.handleResetProject();
    if (this.state.selectedUnitID) {
      this.handleResetUnitDetail();
    }
  }
  setComponentState = state => new Promise((res) => {
    this.setState(state, () => {
      res(state);
    });
  });
  // auto reload
  setAutoReloadTimer = () => {
    this.clearAutoReloadTimer();
    const { autoReload, autoReloadTimeout, svgLoaded } = this.state;
    if (autoReload) {
      this.reloadTimeout = setTimeout(() => {
        if (svgLoaded) {
          this.handleFetchUnits();
          this.setAutoReloadTimer();
        }
      }, autoReloadTimeout);
    }
  }
  clearAutoReloadTimer = () => {
    if (this.reloadTimeout) {
      clearTimeout(this.reloadTimeout);
    }
  }
  // fetch handlers
  handleReserveUnit = () => {
    const { marketplaceURL } = this.props;
    const { projectID, selectedUnitID } = this.state;

    if (projectID && selectedUnitID) {
      window.parent.postMessage(`${selectedUnitID}`, marketplaceURL);
    }
  }
  handleCloseUnitDetail = () => {
    this.setState({
      selectedUnitID: null,
      selectedUnitLayoutID: null,
      showUnitDetail: false,
    }, () => {
      this.handleResetUnitDetail();
    });
  }
  handleFetchUnits(projectID = this.state.projectID) {
    if (projectID) {
      const { store } = this.context;
      const options = { limit: 0 };
      const { projectUnitsRefreshAt, marketplaceURL } = this.props;
      if (projectUnitsRefreshAt) {
        options.refreshAt = projectUnitsRefreshAt;
      }
      if (marketplaceURL !== '') {
        options.isMarketplace = true;
      }
      store.dispatch(Actions.fetchProjectUnits(projectID, options))
        .catch(() => {});
    }
  }
  handleFetchUnitDetail = (projectID = this.state.projectID) => {
    const { store } = this.context;
    const { selectedUnitID, selectedUnitLayoutID } = this.state;
    if (projectID && selectedUnitID) {
      store.dispatch(Actions.fetchProjectUnit(projectID, selectedUnitID))
        .catch(() => {});
      if (selectedUnitLayoutID) {
        store.dispatch(Actions.fetchProjectUnitLayout(projectID, selectedUnitLayoutID))
          .catch(() => {});
        store.dispatch(Actions.fetchProjectUnitLayoutPictures(projectID, selectedUnitLayoutID))
          .catch(() => {});
      }
    }
  }
  handleOnAutoReload = (autoReload) => {
    setItem(`${config.app.name}.sitePlanAutoReload`, autoReload ? 'true' : 'false');
    // if autoReload is disabled when there is an ongoing timeout then..
    // ..clear the timeout also.
    this.setState({ autoReload }, () => {
      if (autoReload) {
        this.handleFetchUnits();
        this.setAutoReloadTimer();
      } else if (this.reloadTimeout) {
        clearTimeout(this.reloadTimeout);
      }
    });
  }
  handleOnSVGLoaded = () => {
    this.setState({ svgLoaded: true });
  }
  handleRefresh = (projectID = this.state.projectID) => {
    const { store } = this.context;
    store.dispatch(Actions.fetchProject(projectID))
      .catch(() => {});
    store.dispatch(Actions.fetchProjectUnitLayouts(projectID))
      .catch(() => {});
    this.handleFetchUnits(projectID);
  }
  handleResetProject = () => {
    const { store } = this.context;
    store.dispatch(Actions.projectReset());
    store.dispatch(Actions.projectUnitsReset());
    store.dispatch(Actions.siteplanReset());
  }
  handleResetUnitDetail = () => {
    const { store } = this.context;
    store.dispatch(Actions.projectUnitReset());
    store.dispatch(Actions.projectUnitLayoutReset());
    store.dispatch(Actions.projectUnitLayoutPicturesReset());
  }
  handleSelectUnit = (unit) => {
    this.setState({
      selectedUnitID: unit.id,
      selectedUnitLayoutID: unit.projectUnitLayoutID,
      showUnitDetail: true,
    }, () => {
      this.handleFetchUnitDetail();
    });
  }
  renderUnitDetailModalFooter = ({ unit }) => {
    const { unitIsLoading } = this.props;
    if (unit && unit.statusID === 'new') {
      return (
        <Button
          loading={unitIsLoading}
          onClick={this.handleReserveUnit}
        >
          Reserve
        </Button>
      );
    }
    return null;
  }
  render() {
    const { projectIsError, project } = this.props;
    const { projectID, autoReload, showUnitDetail } = this.state;
    if (
      projectIsError ||
      !projectID ||
      (project && !(_.has(project, 'siteplanAppEnabled') ? project.siteplanAppEnabled : project.salesChartType === 'saleschart-app')) ||
      (project && !project.siteplanID)
    ) {
      return <Redirect to="/not_found/" />;
    }
    return (
      <div className="MHubSiteplanWidget">
        <SVGViewer
          onSVGLoaded={this.handleOnSVGLoaded}
          unitOnClick={this.handleSelectUnit}
          unitClickable
          autoReload={autoReload}
          onAutoReload={this.handleOnAutoReload}
        />
        <UnitDetail
          active={showUnitDetail}
          onClose={this.handleCloseUnitDetail}
          footerNode={this.renderUnitDetailModalFooter}
        />
      </div>
    );
  }
}

export default connect(MHubSiteplanWebpackWidget.mapStateToProps)(MHubSiteplanWebpackWidget);

export default {
  SITEPLAN_SET_COUNT: 'SITEPLAN_SET_COUNT',
  SITEPLAN_SET_FILTER_STATUS: 'SITEPLAN_SET_FILTER_STATUS',
  SITEPLAN_SET_FILTER_LAYOUT: 'SITEPLAN_SET_FILTER_LAYOUT',
  SITEPLAN_SET_FILTER_BLOCK: 'SITEPLAN_SET_FILTER_BLOCK',
  SITEPLAN_SET_FILTER_LEVEL: 'SITEPLAN_SET_FILTER_LEVEL',
  SITEPLAN_SET_SHOW_DETAILS: 'SITEPLAN_SET_SHOW_DETAILS',
  SITEPLAN_SET_FILTER_CATEGORY: 'SITEPLAN_SET_FILTER_CATEGORY',

  SITEPLAN_ADD_PROJECT_UNIT_ID: 'SITEPLAN_ADD_PROJECT_UNIT_ID',

  SITEPLAN_RESET: 'SITEPLAN_RESET',
};

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Actions from '../redux/actions';
import Selectors from '../redux/selectors';

import iconClose from '../assets/icons/ic-close.svg';
import { TRANSLATION_FILES } from '../constants/translation';

import StatusFilter from './StatusFilter';
import LayoutFilter from './LayoutFilter';
import BlockFilter from './BlockFilter';
import LevelFilter from './LevelFilter';
import CategoryFilter from './CategoryFilter';
import Button from './Button';
import ToggleButton from './ToggleButton';
import FilterButton from './FilterButton';
import AppMenuButton from './AppMenuButton';
import './FilterMenu.css';
import './Modal.css';

class FilterMenu extends PureComponent {
  static mapStateToProps = state => ({
    marketplaceURL: Selectors.getAppMarketplaceURL(state),
    pathname: state.router.location.pathname,
    bookApiURL: Selectors.getAppBookApiURL(state),
    project: Selectors.getProjectData(state),
    projectIsError: Selectors.getProjectIsError(state),
    filterStatus: Selectors.getSiteplanFilterStatus(state),
    filterLayout: Selectors.getSiteplanFilterLayout(state),
    filterBlock: Selectors.getSiteplanFilterBlock(state),
    filterLevel: Selectors.getSiteplanFilterLevel(state),
    filterCategory: Selectors.getSiteplanFilterCategory(state),
    showDetails: Selectors.getSiteplanShowDetails(state),
    companyCode: Selectors.getAppCompanyCode(state),
  })
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    bookApiURL: PropTypes.string,
    project: PropTypes.shape({
      id: PropTypes.string,
      salesChartEnabled: PropTypes.bool,
      siteplanAppEnabled: PropTypes.bool,
    }),
    projectIsError: PropTypes.bool,
    filterStatus: PropTypes.string,
    filterLayout: PropTypes.string,
    filterBlock: PropTypes.string,
    filterLevel: PropTypes.string,
    filterCategory: PropTypes.string,
    showDetails: PropTypes.bool,
    floating: PropTypes.bool,
    marketplaceURL: PropTypes.string,
    companyCode: PropTypes.string,
  }
  static contextTypes = {
    store: PropTypes.object,
  }
  static defaultProps = {
    bookApiURL: '',
    project: {
      id: '',
      salesChartEnabled: false,
      siteplanAppEnabled: false,
    },
    projectIsError: false,
    filterStatus: null,
    filterLayout: null,
    filterBlock: null,
    filterLevel: null,
    filterCategory: null,
    showDetails: false,
    floating: false,
    marketplaceURL: '',
    companyCode: '',
  }
  constructor(props) {
    super(props);
    const { filterStatus, filterLayout, filterBlock, filterLevel, filterCategory } = props;
    this.state = {
      active: false,
      filterStatus,
      filterLayout,
      filterBlock,
      filterLevel,
      filterCategory,
    };
  }
  state = {
    active: false,
  }
  static getDerivedStateFromProps(props, state) {
    const { active } = state;
    // Compare the incoming prop to previous prop
    const {
      filterStatus,
      filterLayout,
      filterBlock,
      filterLevel,
      filterCategory,
    } = props;
    return {
      filterStatus: active ? state.filterStatus : filterStatus,
      filterLayout: active ? state.filterLayout : filterLayout,
      filterBlock: active ? state.filterBlock : filterBlock,
      filterLevel: active ? state.filterLevel : filterLevel,
      filterCategory: active ? state.filterCategory : filterCategory,
    };
  }
  classNames = () => {
    const { floating } = this.props;
    const names = ['Controls'];
    if (floating) {
      names.push('floating');
    }
    return names.join(' ');
  }
  handleSetActive = (active = !this.state.active) => () => {
    this.setState({ active });
    if (window.FlutterApp && window.FlutterApp.postMessage) {
      window.FlutterApp.postMessage(`${active ? 'hide' : 'show'}Menu`);
    }
  };
  handleToggleMenu = this.handleSetActive()
  handleOnClose = this.handleSetActive(false)
  handleToggleDetails = (e, active) => {
    const { store } = this.context;
    store.dispatch(Actions.setSiteplanShowDetails(active));
  }
  handleOnChange = (e, data) => {
    let {
      filterStatus,
      filterLayout,
      filterBlock,
      filterLevel,
      filterCategory,
    } = this.state;
    if (data) {
      const { name, value } = data;
      switch (name) {
        case 'status':
          filterStatus = value;
          break;
        case 'layout':
          filterLayout = value;
          break;
        case 'zoneOrBlock':
          filterBlock = value;
          break;
        case 'streetNumberOrLevel':
          filterLevel = value;
          break;
        case 'category':
          filterCategory = value;
          break;
        default:
          break;
      }
      this.setState({
        filterStatus,
        filterLayout,
        filterBlock,
        filterLevel,
        filterCategory,
      });
    }
  }
  handleUpdateStore = () => {
    const { store } = this.context;
    const {
      filterStatus,
      filterLayout,
      filterBlock,
      filterLevel,
      filterCategory,
    } = this.state;
    if (filterStatus !== this.props.filterStatus) {
      store.dispatch(Actions.setSiteplanFilterStatus(filterStatus));
    }
    if (filterLayout !== this.props.filterLayout) {
      store.dispatch(Actions.setSiteplanFilterLayout(filterLayout));
    }
    if (filterBlock !== this.props.filterBlock) {
      store.dispatch(Actions.setSiteplanFilterBlock(filterBlock));
    }
    if (filterLevel !== this.props.filterLevel) {
      store.dispatch(Actions.setSiteplanFilterLevel(filterLevel));
    }
    if (filterCategory !== this.props.filterCategory) {
      store.dispatch(Actions.setSiteplanFilterCategory(filterCategory));
    }
  }
  handleClearFilter = () => {
    this.setState({
      filterStatus: null,
      filterLayout: null,
      filterBlock: null,
      filterLevel: null,
      filterCategory: null,
    });
  }
  handleApplyFilter = () => {
    this.handleUpdateStore();
    this.handleOnClose();
  }
  renderMenuHeader = () => {
    const { t } = this.props;
    return (
      <div className="header">
        {
          window.FlutterApp && window.FlutterApp.postMessage
            ? <div className="placeholder" />
            : null
        }
        <div className="title">
          <h4>{t('Filter')}</h4>
        </div>
        <AppMenuButton
          icon={iconClose}
          onClick={this.handleOnClose}
        />
      </div>
    );
  }
  renderMenuContent = () => {
    const {
      filterStatus,
      filterLayout,
      filterBlock,
      filterLevel,
      filterCategory,
    } = this.state;
    return (
      <div className="content">
        <StatusFilter
          onChange={this.handleOnChange}
          value={filterStatus}
        />
        <LayoutFilter
          onChange={this.handleOnChange}
          value={filterLayout}
        />
        <BlockFilter
          onChange={this.handleOnChange}
          value={filterBlock}
        />
        <LevelFilter
          onChange={this.handleOnChange}
          value={filterLevel}
        />
        <CategoryFilter
          onChange={this.handleOnChange}
          value={filterCategory}
        />
      </div>
    );
  }
  renderMenuFooter = () => {
    const { t } = this.props;
    return (
      <div className="footer">
        <Button
          small
          text
          onClick={this.handleClearFilter}
        >
          {t('Clear all')}
        </Button>
        <Button
          onClick={this.handleApplyFilter}
        >
          {t('Apply')}
        </Button>
      </div>
    );
  }
  render() {
    const {
      companyCode, t,
      bookApiURL, pathname, project, projectIsError, showDetails, marketplaceURL,
      filterStatus, filterLayout, filterBlock, filterLevel, filterCategory,
    } = this.props;
    const { active } = this.state;
    if (
      (!pathname.includes('/preview/') &&
        !pathname.includes('/siteplan-widget/') &&
        !pathname.includes('/saleschart/')
      ) || projectIsError || pathname.includes('/not_found/')) {
      return null;
    }
    const classNames = this.classNames();
    return (
      <Fragment>
        <Modal
          isOpen={active}
          onRequestClose={this.handleOnClose}
          closeTimeoutMS={500}
          className="modal side right"
          overlayClassName="modal-overlay"
          ariaHideApp={false}
        >
          <div className="FilterMenu">
            {this.renderMenuHeader()}
            {this.renderMenuContent()}
            {this.renderMenuFooter()}
          </div>
        </Modal>
        <div className={classNames}>
          {
            !marketplaceURL && bookApiURL && !pathname.includes('/saleschart/') && project && project.id && project.salesChartEnabled && (
              <Link
                style={{ fontSize: '.9em' }}
                to={`${companyCode}/saleschart/?projectID=${project.id}`}
              >
                {t('Storey Plan')}
              </Link>
            )
          }
          {
            !marketplaceURL && !pathname.includes('/preview/') && project && project.id && project.siteplanAppEnabled && (
              <Link
                style={{ fontSize: '.9em' }}
                to={`${companyCode}/preview/?projectID=${project.id}`}
              >
                {t('Site Plan')}
              </Link>
            )
          }
          {
            !pathname.includes('/saleschart/') && (
              <ToggleButton
                active={showDetails}
                onChange={this.handleToggleDetails}
              >
                {t('Details')}
              </ToggleButton>
            )
          }
          {
            !pathname.includes('/saleschart/') && (
              <FilterButton
                hasFilter={
                  filterStatus !== null ||
                  filterLayout !== null ||
                  filterBlock !== null ||
                  filterLevel !== null ||
                  filterCategory !== null
                }
                onClick={this.handleToggleMenu}
              />
            )
          }
        </div>
      </Fragment>
    );
  }
}

export default withTranslation(TRANSLATION_FILES.screens)(connect(FilterMenu.mapStateToProps)(FilterMenu));

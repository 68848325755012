import { createReducer } from '@mhub/web-redux-utilities';
import Types from './types';

const initialState = {
  loading: false,
  statusText: null,
  isError: false,
  data: [],
  pagination: {},
  token: null,
};

export default createReducer(initialState, {
  [Types.PROJECT_UNIT_LAYOUT_PICTURES_SET_LOADING]: (state, payload) => (
    Object.assign({}, state, {
      loading: payload.loading,
    })
  ),
  [Types.PROJECT_UNIT_LAYOUT_PICTURES_REQUEST]: state => (
    Object.assign({}, state, {
      loading: true,
      isError: false,
      statusText: null,
    })
  ),
  [Types.PROJECT_UNIT_LAYOUT_PICTURES_SUCCESS]: (state, payload) => (
    Object.assign({}, state, {
      loading: false,
      data: payload.pagination.offset === 0 ? payload.data : state.data.concat(payload.data),
      pagination: payload.pagination,
      token: payload.token,
    })
  ),
  [Types.PROJECT_UNIT_LAYOUT_PICTURES_FAILURE]: (state, payload) => (
    Object.assign({}, state, {
      loading: false,
      isError: true,
      statusText: payload.statusText,
    })
  ),
  [Types.PROJECT_UNIT_LAYOUT_PICTURES_RESET]: () => Object.assign({}, initialState),
});

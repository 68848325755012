import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Actions from '../redux/actions';
import Selectors from '../redux/selectors';

import { TRANSLATION_FILES } from '../constants/translation';

import StatusFilter from './StatusFilter';
import LayoutFilter from './LayoutFilter';
import CategoryFilter from './CategoryFilter';
import BlockFilter from './BlockFilter';
import LevelFilter from './LevelFilter';

import './FilterDetails.css';

class FilterDetails extends PureComponent {
  static mapStateToProps = state => ({
    pathname: state.router.location.pathname,
    summary: Selectors.getProjectUnitsSummaryData(state),
    nonMHubUnitCount: Selectors.getSiteplanCount(state),
    project: Selectors.getProjectData(state),
    projectIsError: Selectors.getProjectIsError(state),
    filterStatus: Selectors.getSiteplanFilterStatus(state),
    filterLayout: Selectors.getSiteplanFilterLayout(state),
    filterCategory: Selectors.getSiteplanFilterCategory(state),
    filterBlock: Selectors.getSiteplanFilterBlock(state),
    filterLevel: Selectors.getSiteplanFilterLevel(state),
    showDetails: Selectors.getSiteplanShowDetails(state),
  })
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    floating: PropTypes.bool,
    summary: PropTypes.shape({
      count: PropTypes.number,
    }),
    nonMHubUnitCount: PropTypes.number,
    project: PropTypes.shape({
      id: PropTypes.string,
    }),
    projectIsError: PropTypes.bool,
    filterStatus: PropTypes.string,
    filterLayout: PropTypes.string,
    filterBlock: PropTypes.string,
    filterLevel: PropTypes.string,
    filterCategory: PropTypes.string,
    showDetails: PropTypes.bool,
  }
  static contextTypes = {
    store: PropTypes.object,
  }
  static defaultProps = {
    summary: null,
    nonMHubUnitCount: 0,
    floating: false,
    project: null,
    projectIsError: false,
    filterStatus: null,
    filterLayout: null,
    filterBlock: null,
    filterLevel: null,
    filterCategory: null,
    showDetails: false,
  }
  getTotalCount = () => {
    const { summary, nonMHubUnitCount } = this.props;
    let count = 0;
    if (summary) {
      ({ count } = summary);
    }
    return (count || 0) + (nonMHubUnitCount || 0);
  }
  classNames = () => {
    const { floating, showDetails } = this.props;
    const names = ['FilterDetails'];
    if (floating) {
      names.push('floating');
    }
    if (showDetails) {
      names.push('active');
    }
    return names.join(' ');
  }
  handleOnChange = (e, data) => {
    const { store } = this.context;
    const {
      filterStatus,
      filterLayout,
      filterBlock,
      filterLevel,
      filterCategory,
    } = this.props;
    if (data) {
      const { name, value } = data;
      switch (name) {
        case 'status':
          if (value !== filterStatus) {
            store.dispatch(Actions.setSiteplanFilterStatus(value));
          }
          break;
        case 'layout':
          if (value !== filterLayout) {
            store.dispatch(Actions.setSiteplanFilterLayout(value));
          }
          break;
        case 'zoneOrBlock':
          if (value !== filterBlock) {
            store.dispatch(Actions.setSiteplanFilterBlock(value));
          }
          break;
        case 'streetNumberOrLevel':
          if (value !== filterLevel) {
            store.dispatch(Actions.setSiteplanFilterLevel(value));
          }
          break;
        case 'category':
          if (value !== filterCategory) {
            store.dispatch(Actions.setSiteplanFilterCategory(value));
          }
          break;
        default:
          break;
      }
    }
  }
  render() {
    const {
      project, projectIsError, pathname, t,
      filterStatus, filterLayout, filterBlock, filterLevel, filterCategory,
    } = this.props;
    if (
      (!pathname.includes('/preview/') &&
        !pathname.includes('/siteplan-widget/')
      ) || !project || projectIsError ||
      pathname.includes('/not_found/')
    ) {
      return null;
    }
    const classNames = this.classNames();
    return (
      <div className={classNames}>
        <div className="total">
          {t('Total')}
          <span>{this.getTotalCount()}</span>
        </div>
        <StatusFilter
          floating
          onChange={this.handleOnChange}
          value={filterStatus}
        />
        <LayoutFilter
          floating
          onChange={this.handleOnChange}
          value={filterLayout}
        />
        <CategoryFilter
          floating
          onChange={this.handleOnChange}
          value={filterCategory}
        />
        <BlockFilter
          floating
          onChange={this.handleOnChange}
          value={filterBlock}
        />
        <LevelFilter
          floating
          onChange={this.handleOnChange}
          value={filterLevel}
        />
      </div>
    );
  }
}

export default withTranslation(TRANSLATION_FILES.screens)(connect(FilterDetails.mapStateToProps)(FilterDetails));

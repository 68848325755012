const getBookApiURL = state => state.bookApiURL;

const getShowroomURL = state => state.showroomURL;

const getMarketplaceURL = state => state.marketplaceURL;

const getCompanyCode = state => state.companyCode;

const getS3Bucket = state => state.s3Bucket;

const getS3Region = state => state.s3Region;

export default {
  getBookApiURL,
  getShowroomURL,
  getMarketplaceURL,
  getCompanyCode,
  getS3Bucket,
  getS3Region,
};

/* eslint react/forbid-prop-types: 0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';

import Selectors from '../redux/selectors';
import './App.css';
import '../components/Button.css';
import MHubSiteplanWebpackWidget from './MHubSiteplanWebpackWidget';

class WidgetApp extends Component {
  static mapStateToProps = state => ({
    isAuthenticated: Selectors.getAuthenticateIsAuthenticated(state),
  })
  static propTypes = {
    history: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool,
    projectID: PropTypes.string,
  }
  static contextTypes = {
    store: PropTypes.object,
  }
  static defaultProps = {
    isAuthenticated: false,
    projectID: '',
  }
  render() {
    const { isAuthenticated, ...props } = this.props;
    return (
      isAuthenticated &&
      <MHubSiteplanWebpackWidget {...props} />
    );
  }
}

export default connect(WidgetApp.mapStateToProps)(WidgetApp);

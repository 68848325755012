/* eslint react/jsx-filename-extension: 0 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from '@aws-amplify/core';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import './reset.css';
import './index.css';
import { store, history } from './store';
import Root from './Root';
import config from './config';
import amplifyConfig from './amplifyConfig';
import * as serviceWorker from './serviceWorker';
import WebpackWidgetRoot from './WebpackWidgetRoot';

Bugsnag.start({
  apiKey: '5b785ee161e5e5d3884ca910c9748ab2',
  releaseStage: config.bugsnag.RELEASE_STAGE,
  appVersion: config.app.version,
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

if (!config.app.shareMode) {
  Amplify.configure(amplifyConfig);
}

// Attach siteplan to global window object
window.siteplan = (props, el) => {
  if (el) {
    ReactDOM.render(
      <WebpackWidgetRoot store={store} history={history} {...props} />,
      el,
    );
  }
};

ReactDOM.render(
  <ErrorBoundary>
    <Root store={store} history={history} />
  </ErrorBoundary>,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import qs from 'qs';
import config from '../../config';
import Endpoints from '../../endpoints';
import fetchUrl from '../../utils/fetchUrl';
import { handleError, resJson } from '../../utils/error';
import { getToken, getCount, getData, getHasMore } from '../helpers';

import Types from './types';

const projectUnitLayoutPicturesRequest = () => ({
  type: Types.PROJECT_UNIT_LAYOUT_PICTURES_REQUEST,
});

const projectUnitLayoutPicturesSuccess = ({ data, pagination, token }) => ({
  type: Types.PROJECT_UNIT_LAYOUT_PICTURES_SUCCESS,
  payload: {
    data,
    pagination,
    token,
  },
});

const projectUnitLayoutPicturesFailure = message => ({
  type: Types.PROJECT_UNIT_LAYOUT_PICTURES_FAILURE,
  payload: {
    statusText: message,
  },
});

const projectUnitLayoutPicturesReset = () => ({
  type: Types.PROJECT_UNIT_LAYOUT_PICTURES_RESET,
});

const setProjectUnitLayoutPicturesLoading = (loading = false) => ({
  type: Types.PROJECT_UNIT_LAYOUT_PICTURES_SET_LOADING,
  payload: {
    loading,
  },
});

const fetchProjectUnitLayoutPictures = (projectID, layoutID, query = {}) => {
  const { limit } = config.query || 0;
  const q = Object.assign({
    limit,
    offset: 0,
  }, query);
  const url = `${Endpoints.PROJECT_UNIT_LAYOUT_PICTURES(projectID, layoutID)}/?${qs.stringify(q)}`;
  const options = {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  let token;
  let count = 0;
  return (dispatch) => {
    dispatch(projectUnitLayoutPicturesRequest());
    return dispatch(fetchUrl(url, options))
      .then((res) => {
        token = getToken(res);
        count = getCount(res);
        return resJson(res);
      })
      .then((payload) => {
        const data = getData(payload, q.limit);
        const hasMore = getHasMore(data, q.limit);
        const pagination = {
          count,
          hasMore,
          offset: q.offset,
          limit: q.limit,
        };
        dispatch(projectUnitLayoutPicturesSuccess({ data, pagination, token }));
        return { data, pagination, token };
      })
      .catch(err => dispatch(handleError(err, projectUnitLayoutPicturesFailure)));
  };
};

export default {
  setProjectUnitLayoutPicturesLoading,

  projectUnitLayoutPicturesRequest,
  projectUnitLayoutPicturesSuccess,
  projectUnitLayoutPicturesFailure,

  projectUnitLayoutPicturesReset,

  fetchProjectUnitLayoutPictures,
};

import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import icBath from '../assets/icons/ic-bath.svg';
import icBed from '../assets/icons/ic-bed.svg';
import icCar from '../assets/icons/ic-car.svg';

import { TRANSLATION_FILES } from '../constants/translation';

import DetailField from './DetailField';
import './SpecificationField.css';

class SpecificationField extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
  }
  static defaultProps = {
    className: null,
    name: '',
    value: null,
  }
  getIcon = (name) => {
    switch (name) {
      case 'bathrooms':
        return icBath;
      case 'bedrooms':
        return icBed;
      case 'parking lots':
        return icCar;
      default:
        return null;
    }
  }
  classNames = () => {
    const { className } = this.props;
    const names = ['SpecificationField'];
    if (className) {
      names.push(className);
    }
    return names.join(' ');
  }
  render() {
    const { name, value, t } = this.props;
    const className = this.classNames();
    return (
      <DetailField
        icon={this.getIcon(name.toLowerCase())}
        className={className}
        label={t(name.toLowerCase())}
      >
        {value}
      </DetailField>
    );
  }
}

export default withTranslation(TRANSLATION_FILES.screens)(SpecificationField);

/* eslint no-param-reassign: 0 */

import Bugsnag from '@bugsnag/js';

export class ExtendableError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = (new Error(message)).stack;
    }
  }
}

export class FetchError extends ExtendableError {
  constructor(message, res) {
    super(message);
    this.res = res;
  }
}

export const resJson = async (res) => {
  try {
    return await res.json();
  } catch (err) {
    throw new FetchError(err.message, res);
  }
};

export function handleError(err, nextAction) {
  return (dispatch) => {
    const isAwsErr = err.message.match(/(no current user|not authenticated)/i);
    const isFailedToFetch = err.message.match(/failed to fetch/i);
    const isJSONErr = err.message.match(/unexpected token . in json/i);
    const oriErr = err.message;

    let message = 'An error occurred';
    if (err.res && !err.res.ok) {
      const contentType = err.res.headers.get('Content-Type');
      if (contentType.includes('text/')) {
        err.res.text().then((text) => {
          const notify = !(
            err.res.status === 400 ||
            err.res.status === 401 ||
            err.res.status === 403 ||
            err.res.status === 404 ||
            err.res.status === 412
          );
          if (!notify && contentType.includes('text/plain')) {
            message = text;
          } else if (!isAwsErr && !isFailedToFetch) {
            Bugsnag.notify(err, (event) => {
              event.addMetadata('error', 'original', oriErr);
              event.addMetadata('error', 'response.ok', err.res.ok);
              event.addMetadata('error', 'response.status', err.res.status);
              event.addMetadata('error', 'response.url', err.res.url);
              event.addMetadata('error', 'response.type', err.res.type);
              event.addMetadata('error', 'response.body', err.res.body);
              if (isJSONErr) {
                event.addMetadata('error', 'response.text', text);
              }
            });
          }
          err.message = message.trim();
          if (nextAction) {
            dispatch(nextAction(message));
          }
        });
        return;
      }
    }

    if (!isAwsErr && !isFailedToFetch) {
      Bugsnag.notify(err, (event) => {
        event.addMetadata('error', 'original', oriErr);

        if (err.res) {
          event.addMetadata('error', 'response.ok', err.res.ok);
          event.addMetadata('error', 'response.status', err.res.status);
          event.addMetadata('error', 'response.url', err.res.url);
          event.addMetadata('error', 'response.type', err.res.type);
          event.addMetadata('error', 'response.body', err.res.body);
        }
      });
    }
    err.message = message;
    if (nextAction) {
      dispatch(nextAction(message));
    }
  };
}

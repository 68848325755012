import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { TRANSLATION_FILES } from '../constants/translation';

import './SizeSuffix.css';

class SizeSuffix extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    suffix: PropTypes.string,
  }
  static defaultProps = {
    className: null,
    size: null,
    suffix: 'square_feet',
  }
  classNames = () => {
    const { className } = this.props;
    const names = ['SizeSuffix'];
    if (className) {
      names.push(className);
    }
    return names.join(' ');
  }
  checkSizeSuffix = (suffix) => {
    const { t } = this.props;
    switch (suffix) {
      case 'square_metres':
        return t('sqm');
      case 'square_feet':
        return t('sqft');
      default:
        return '';
    }
  }
  render() {
    const { size, suffix } = this.props;
    const className = this.classNames();
    return (
      <span className={className}>
        {
          size ?
          `${parseInt(size, 10)} ${this.checkSizeSuffix(suffix)}`
          : '-'
        }
      </span>
    );
  }
}

export default withTranslation(TRANSLATION_FILES.screens)(SizeSuffix);

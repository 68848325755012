const getData = state => state.data;

const getIsError = state => state.isError;

const getLoading = state => state.loading;

const getToken = state => state.token;

export default {
  getData,
  getIsError,
  getLoading,
  getToken,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Image } from '@mhub/web-components';

import Selectors from '../redux/selectors';

import './LoadImage.css';

const pictureSizes = [
  'small',
  'medium',
  'large',
  'full',
  'fluid',
];

class LoadImage extends PureComponent {
  static mapStateToProps = state => ({
    bucket: Selectors.getAppS3Bucket(state),
    region: Selectors.getAppS3Region(state),
  })
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(pictureSizes),
    src: PropTypes.string,
    pictureKey: PropTypes.string,
    bucket: PropTypes.string,
    region: PropTypes.string,
    onClick: PropTypes.func,
    alt: PropTypes.string,
  }
  static defaultProps = {
    className: null,
    size: 'fluid',
    src: null,
    pictureKey: null,
    bucket: null,
    region: null,
    onClick: null,
    alt: '',
  }

  constructor(props) {
    super(props);
    const { src, pictureKey, size } = props;
    this.state = {
      prevProps: { src, pictureKey, size },
      src: LoadImage.getImageSrc(props),
    };
  }
  static getDerivedStateFromProps(props, state) {
    const { prevProps } = state;
    // Compare the incoming prop to previous prop
    const { src, pictureKey, size } = props;
    return {
      // Store the previous props in state
      prevProps: { src, pictureKey, size },
      src: (
        prevProps.src !== src ||
        prevProps.pictureKey !== pictureKey ||
        prevProps.size !== size
      ) ? LoadImage.getImageSrc(props) : state.src,
    };
  }
  static getImageSrc(props) {
    const { pictureKey, src, size, bucket, region } = props;
    if (src) {
      return src;
    }
    if (pictureKey && bucket && region) {
      let pictureSize;
      if (size) {
        switch (size) {
          case 'small':
            pictureSize = '010';
            break;
          case 'medium':
            pictureSize = '030';
            break;
          case 'large':
            pictureSize = '070';
            break;
          default:
            pictureSize = '100';
        }
      }
      return Image.s3File(`${pictureKey}${pictureSize}.jpg`, { bucket, region });
    }
    return null;
  }

  classNames = () => {
    const { className, size, onClick } = this.props;
    const { isLoaded } = this.state;
    const names = ['LoadImage'];
    if (className) {
      names.push(className);
    }
    if (isLoaded) {
      names.push('loaded');
    }
    if (onClick) {
      names.push('clickable');
    }
    if (pictureSizes.some(s => s === size)) {
      names.push(size);
    }
    return names.join(' ');
  }
  render() {
    const { onClick, alt } = this.props;
    const { src } = this.state;
    const className = this.classNames();
    return (
      <Image
        className={className}
        onClick={onClick}
        src={src}
        alt={alt}
      />
    );
  }
}

export default connect(LoadImage.mapStateToProps)(LoadImage);

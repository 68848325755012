import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import iconFilter from '../assets/icons/ic-filter.svg';
import './FilterButton.css';

export default class FilterButton extends PureComponent {
  static propTypes = {
    hasFilter: PropTypes.bool,
    onClick: PropTypes.func,
  }
  static defaultProps = {
    hasFilter: false,
    onClick: null,
  }
  classNames = () => {
    const { hasFilter } = this.props;
    const names = ['FilterButton'];
    if (hasFilter) {
      names.push('active');
    }
    return names.join(' ');
  }
  render() {
    const { onClick } = this.props;
    const classNames = this.classNames();
    return (
      <button
        className={classNames}
        onClick={onClick}
      >
        <img
          src={iconFilter}
          alt="Filter"
        />
      </button>
    );
  }
}

const UNIT_STATUSES = [
  { text: 'Available', value: 'new' },
  { text: 'Reserved', value: 'reserved' },
  { text: 'Booked', value: 'booked' },
  { text: 'Sold', value: 'sold' },
  { text: 'Delisted', value: 'delisted' },
];

export const ALL_UNIT_STATUSES = [
  { text: 'Total', value: 'total' },
  { text: 'Available', value: 'new' },
  { text: 'Reserved', value: 'reserved' },
  { text: 'Booked', value: 'booked' },
  { text: 'Sold', value: 'sold' },
  { text: 'Delisted', value: 'delisted' },
];

export default UNIT_STATUSES;

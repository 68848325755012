import qs from 'qs';

export function getQuery() {
  function get(a) {
    if (a === '') return {};
    const b = {};
    a.forEach((ai) => {
      const p = ai.split('=', 2);
      if (p.length === 1) {
        b[p[0]] = '';
      } else {
        b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
      }
    });
    return b;
  }
  const query = get(window.location.search.substr(1).split('&'));
  return query;
}

export function decodeSearch(search) {
  if (search.startsWith('?_q=')) {
    return qs.parse(atob(search.substring(4)), { comma: true });
  }
  return qs.parse(search.substring(1), { comma: true });
}

export function encodeSearch(query, hide = false) {
  if (hide) {
    return `_q=${btoa(qs.stringify(query))}`;
  }
  return qs.stringify(query);
}

export function parseParams(url, query = {}, hash = {}) {
  let parsedURL = url;
  if (query && Object.keys(query).length > 0) {
    parsedURL += `?${qs.stringify(query)}`;
  }
  if (hash && Object.keys(hash).length > 0) {
    parsedURL += `#${qs.stringify(hash)}`;
  }
  return parsedURL;
}

export function getSharePath() {
  const paths = window.location.pathname.split('/').filter(p => !!p);
  if (paths.length > 0) {
    return paths[0].toUpperCase();
  }
  return '';
}

export function isIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function isBrowser() {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return false;
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
  ) {
    return false;
  }
  return true;
}

export function encodeRedirect(url = '/') {
  let nextBase64 = '';
  if (url) {
    try {
      nextBase64 = btoa(url);
    } catch (e) {
      window.bugsnagClient.notify(e);
    }
  }
  return nextBase64;
}

export function decodeRedirect(nextBase64) {
  let url = '';
  if (nextBase64) {
    try {
      url = atob(nextBase64);
    } catch (e) {
      window.bugsnagClient.notify(e);
    }
  }
  return url;
}

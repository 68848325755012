export default {
  PROJECT_UNITS_SET_LOADING: 'PROJECT_UNITS_SET_LOADING',

  PROJECT_UNITS_REQUEST: 'PROJECT_UNITS_REQUEST',
  PROJECT_UNITS_FAILURE: 'PROJECT_UNITS_FAILURE',
  PROJECT_UNITS_SUCCESS: 'PROJECT_UNITS_SUCCESS',

  PROJECT_UNITS_RESET: 'PROJECT_UNITS_RESET',

  PROJECT_UNITS_REFRESH: 'PROJECT_UNITS_REFRESH',
};

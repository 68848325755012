import qs from 'qs';
import config from '../../config';
import Endpoints from '../../endpoints';
import fetchUrl from '../../utils/fetchUrl';
import { handleError, resJson } from '../../utils/error';
import { getToken, getCount, getData, getHasMore } from '../helpers';

import Types from './types';

const projectUnitLayoutsRequest = () => ({
  type: Types.PROJECT_UNIT_LAYOUTS_REQUEST,
});

const projectUnitLayoutsSuccess = ({ data, pagination, token }) => ({
  type: Types.PROJECT_UNIT_LAYOUTS_SUCCESS,
  payload: {
    data,
    pagination,
    token,
  },
});

const projectUnitLayoutsFailure = message => ({
  type: Types.PROJECT_UNIT_LAYOUTS_FAILURE,
  payload: {
    statusText: message,
  },
});

const projectUnitLayoutsReset = () => ({
  type: Types.PROJECT_UNIT_LAYOUTS_RESET,
});

const setProjectUnitLayoutsLoading = (loading = false) => ({
  type: Types.PROJECT_UNIT_LAYOUTS_SET_LOADING,
  payload: {
    loading,
  },
});

const fetchProjectUnitLayouts = (projectID, query = {}) => {
  const { limit } = config.query || 0;
  const q = Object.assign({
    limit,
    offset: 0,
  }, query);
  const url = `${Endpoints.PROJECT_UNIT_LAYOUTS(projectID)}/?${qs.stringify(q)}`;
  const options = {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  let token;
  let count = 0;
  return (dispatch) => {
    dispatch(projectUnitLayoutsRequest());
    return dispatch(fetchUrl(url, options))
      .then((res) => {
        token = getToken(res);
        count = getCount(res);
        return resJson(res);
      })
      .then((payload) => {
        const data = getData(payload, q.limit);
        const hasMore = getHasMore(data, q.limit);
        const pagination = {
          count,
          hasMore,
          offset: q.offset,
          limit: q.limit,
        };
        dispatch(projectUnitLayoutsSuccess({ data, pagination, token }));
        return { data, pagination, token };
      })
      .catch(err => dispatch(handleError(err, projectUnitLayoutsFailure)));
  };
};


export default {
  setProjectUnitLayoutsLoading,

  projectUnitLayoutsRequest,
  projectUnitLayoutsSuccess,
  projectUnitLayoutsFailure,

  projectUnitLayoutsReset,

  fetchProjectUnitLayouts,
};

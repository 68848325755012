const storage = {};
let hasLocalStorage = false;

if (localStorage) {
  try {
    const x = 'testStorage';
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    hasLocalStorage = true;
  } catch (e) {
    if (e.name !== 'QuotaExceededError') {
      // Bugsnag.notifyException(e);
    }
    hasLocalStorage = false;
  }
}

export function setItem(key, data) {
  if (hasLocalStorage) {
    localStorage.setItem(key, data);
    return true;
  }
  storage[key] = data;
  return false;
}

export function getItem(key) {
  if (hasLocalStorage) {
    return localStorage.getItem(key);
  }
  return storage[key] || null;
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Dropdown, Label, utils } from '@mhub/web-components';

import Selectors from '../redux/selectors';

import UNIT_STATUSES from '../constants/unitStatuses';
import { HIGH_LEVEL_GROUPS, hasLimitedAccess } from '../constants/userGroups';
import { namespaces, TRANSLATION_FILES } from '../constants/translation';
import { decodeSearch, isIframe } from '../utils/url';

import './StatusFilter.css';

class StatusFilter extends PureComponent {
  static mapStateToProps = state => ({
    search: state.router.location.search,
    pathname: state.router.location.pathname,
    auth: Selectors.getAuthenticateData(state),
    project: Selectors.getProjectData(state),
    summary: Selectors.getProjectUnitsSummaryData(state),
    nonMHubUnitCount: Selectors.getSiteplanCount(state),
    marketplaceURL: Selectors.getAppMarketplaceURL(state),
  })
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    auth: PropTypes.shape({
      groups: PropTypes.array,
    }),
    onChange: PropTypes.func,
    value: PropTypes.string,
    floating: PropTypes.bool,
    // redux
    project: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    summary: PropTypes.shape({
      newCount: PropTypes.number,
      reservedCount: PropTypes.number,
      developerReservedCount: PropTypes.number,
      bookedCount: PropTypes.number,
      developerBookedCount: PropTypes.number,
      soldCount: PropTypes.number,
      developerSoldCount: PropTypes.number,
      delistCount: PropTypes.number,
    }),
    nonMHubUnitCount: PropTypes.number,
    search: PropTypes.string,
    marketplaceURL: PropTypes.string,
  }
  static contextTypes = {
    store: PropTypes.object,
  }
  static defaultProps = {
    auth: null,
    onChange: null,
    value: null,
    floating: false,
    // redux
    project: null,
    summary: null,
    nonMHubUnitCount: 0,
    marketplaceURL: '',
  }
  static checkStatusVisibility(props, status) {
    const { auth, pathname, project } = props;
    const siteplanSettings = StatusFilter.getProjectSettings(project);
    const higherLevelAccess = StatusFilter.hasAccess(auth, HIGH_LEVEL_GROUPS);
    const limitedAccessRole = hasLimitedAccess(auth);
    const {
      hideAvailableLegend,
      hideReservedLegend,
      hideBookedLegend,
      hideSoldLegend,
      hideDelistLegend,
      siteplanSimpleMode,
    } = siteplanSettings;
    const isSimpleMode = siteplanSimpleMode || isIframe() || pathname.includes('/siteplan-widget/');
    const simpleModeAndShowAll = isSimpleMode && higherLevelAccess;
    switch (status) {
      case 'new':
        return !hideAvailableLegend;
      case 'reserved':
        return (!isSimpleMode || simpleModeAndShowAll) && !hideReservedLegend;
      case 'booked':
        return (!isSimpleMode || simpleModeAndShowAll) && !hideBookedLegend;
      case 'sold':
        return (!isSimpleMode || simpleModeAndShowAll) && !hideSoldLegend;
      case 'delisted':
        return (!isSimpleMode || simpleModeAndShowAll) &&
          !hideDelistLegend &&
          (higherLevelAccess || limitedAccessRole);
      default:
        return false;
    }
  }
  static hasAccess(auth, checkGroups = []) {
    if (auth && auth.groups && auth.groups.length > 0) {
      return auth.groups.some(g => checkGroups.some(ag => ag === g.code));
    }
    return false;
  }
  constructor(props) {
    super(props);
    const { project, search } = props;
    const query = decodeSearch(search);
    const projectID = project ? project.id : null;
    let limitUnits = [];
    if (query && query.unitIDs) {
      limitUnits = typeof query.unitIDs === 'string' ? [query.unitIDs] : query.unitIDs;
    }
    this.state = {
      prevProps: { projectID },
      siteplanSettings: StatusFilter.getProjectSettings(project),
      options: UNIT_STATUSES.filter(s => StatusFilter.checkStatusVisibility(props, s.value)),
      limitUnits,
    };
  }
  static getDerivedStateFromProps(props, state) {
    const { prevProps } = state;
    let { limitUnits } = state;
    // Compare the incoming prop to previous prop
    const { project, search } = props;
    const projectID = project ? project.id : null;
    const query = decodeSearch(search);
    if (query && query.unitIDs) {
      limitUnits = typeof query.unitIDs === 'string' ? [query.unitIDs] : query.unitIDs;
    }
    return {
      // Store the previous props in state
      prevProps: { projectID },
      siteplanSettings: prevProps.projectID !== projectID
        ? StatusFilter.getProjectSettings(project)
        : state.siteplanSettings,
      options: prevProps.projectID !== projectID
        ? UNIT_STATUSES.filter(s => StatusFilter.checkStatusVisibility(props, s.value))
        : state.options,
      limitUnits,
    };
  }
  static getProjectSettings(project) {
    return Object.assign({
      hideAvailableLegend: false,
      hideReservedLegend: false,
      hideBookedLegend: false,
      hideSoldLegend: false,
      hideDelistLegend: false,
      overlayMode: 'text',
      siteplanSimpleMode: false,
    }, project && project.siteplanSettings);
  }
  getStatusCount = (status) => {
    const { summary, nonMHubUnitCount } = this.props;
    if (summary) {
      const {
        newCount, delistCount,
        reservedCount, developerReservedCount,
        bookedCount, developerBookedCount,
        soldCount, developerSoldCount,
      } = summary;
      switch (status) {
        case 'new':
          return (newCount || 0);
        case 'reserved':
          return (reservedCount || 0) + (developerReservedCount || 0);
        case 'booked':
          return (bookedCount || 0) + (developerBookedCount || 0);
        case 'delisted':
          return (delistCount || 0) + (nonMHubUnitCount || 0);
        case 'sold':
          return (soldCount || 0) + (developerSoldCount || 0);
        default:
          return 0;
      }
    }
    return 0;
  }
  classNames = () => {
    const { value, floating } = this.props;
    const names = ['StatusFilter'];
    if (value) {
      names.push(value);
    }
    if (floating) {
      names.push('floating');
    }
    return names.join(' ');
  }
  render() {
    const {
      onChange, value, floating, marketplaceURL, t,
    } = this.props;
    const { options, limitUnits } = this.state;
    // NOTE: temporary solution
    const hideCount = !!marketplaceURL && limitUnits.length > 0;
    if (hideCount && limitUnits.some(unitID => unitID === 'none')) {
      return null;
    }
    if (!utils.arrayHasItems(options)) {
      return null;
    }
    const classNames = this.classNames();
    const placeholder = t(floating ? 'Select {{name}}' : 'None', { name: t('Status') });
    const child = (
      <Dropdown
        className={classNames}
        bordered
        fluid={!floating}
        withPortal
        name="status"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        options={[
          {
            title: placeholder,
            value: null,
          },
        ].concat(options.map(s => ({
            className: s.value,
            title: hideCount ? t(s.text, { ns: TRANSLATION_FILES.constants.unitStatus }) : `${t(s.text, { ns: TRANSLATION_FILES.constants.unitStatus })} (${this.getStatusCount(s.value)})`,
            value: s.value,
        })))}
      />
    );
    if (floating) {
      return child;
    }
    return (
      <Label content={t('Status')} noHelper>
        {child}
      </Label>
    );
  }
}

export default withTranslation([
  ...namespaces,
  TRANSLATION_FILES.constants.unitStatus,
])(connect(StatusFilter.mapStateToProps)(StatusFilter));

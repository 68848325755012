const MODELS = {
  projectUnit: 'models/projectUnit',
};

const CONSTANTS = {
  projectStatus: 'constants/projectStatus',
  propertyCategory: 'constants/propertyCategory',
  unitCategory: 'constants/unitCategory',
  unitStatus: 'constants/unitStatus',
};

export const TRANSLATION_FILES = {
  screens: 'screens',
  translation: 'translation',
  constants: CONSTANTS,
  models: MODELS,
};

export const namespaces = [
  TRANSLATION_FILES.screens,
  TRANSLATION_FILES.translation,
];

import qs from 'qs';
import config from '../../config';
import Endpoints from '../../endpoints';
import fetchUrl from '../../utils/fetchUrl';
import { handleError, resJson } from '../../utils/error';
import { getToken, getCount, getData, getHasMore, getCompanyID } from '../helpers';

import Types from './types';

const projectsRequest = () => ({
  type: Types.PROJECTS_REQUEST,
});

const projectsSuccess = ({
  data, pagination, token, companyID,
}) => ({
  type: Types.PROJECTS_SUCCESS,
  payload: {
    data,
    pagination,
    token,
    companyID,
  },
});

const projectsFailure = message => ({
  type: Types.PROJECTS_FAILURE,
  payload: {
    statusText: message,
  },
});

const projectsReset = () => ({
  type: Types.PROJECTS_RESET,
});

const setProjectsLoading = (loading = false) => ({
  type: Types.PROJECTS_SET_LOADING,
  payload: {
    loading,
  },
});

const fetchProjects = (query = {}) => {
  const { limit } = config.query || 0;
  const q = Object.assign({
    limit,
    offset: 0,
  }, query);
  const url = `${Endpoints.PROJECTS}/?${qs.stringify(q)}`;
  const options = {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  let companyID;
  let token;
  let count = 0;
  return (dispatch) => {
    dispatch(projectsRequest());

    return dispatch(fetchUrl(url, options))
      .then((res) => {
        companyID = getCompanyID(res);
        token = getToken(res);
        count = getCount(res);
        return resJson(res);
      })
      .then((payload) => {
        const data = getData(payload, q.limit);
        const hasMore = getHasMore(data, q.limit);
        const pagination = {
          count,
          hasMore,
          offset: q.offset,
          limit: q.limit,
        };
        dispatch(projectsSuccess({
          data, pagination, token, companyID,
        }));
        return { data, pagination, token };
      })
      .catch(err => dispatch(handleError(err, projectsFailure)));
  };
};

export default {
  setProjectsLoading,

  projectsRequest,
  projectsSuccess,
  projectsFailure,

  projectsReset,

  fetchProjects,
};

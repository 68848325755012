const getData = state => state.data;

const getIsAuthenticated = state => state.isAuthenticated;

const getIsError = state => state.isError;

const getLoading = state => state.loading;

const getIsAdfs = state => state.isAdfs;

const getAdfsProvider = state => state.adfsProvider;

export default {
  getData,
  getIsAuthenticated,
  getIsError,
  getLoading,
  getIsAdfs,
  getAdfsProvider,
};

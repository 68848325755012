import qs from 'qs';
// import config from '../../config';
import Endpoints from '../../endpoints';
import fetchUrl from '../../utils/fetchUrl';
import { handleError, resJson } from '../../utils/error';
import { getToken } from '../helpers';

import Types from './types';

const projectUnitRequest = () => ({
  type: Types.PROJECT_UNIT_REQUEST,
});

const projectUnitSuccess = ({ data, token }) => ({
  type: Types.PROJECT_UNIT_SUCCESS,
  payload: {
    data,
    token,
  },
});

const projectUnitFailure = message => ({
  type: Types.PROJECT_UNIT_FAILURE,
  payload: {
    statusText: message,
  },
});

const projectUnitReset = () => ({
  type: Types.PROJECT_UNIT_RESET,
});

const setProjectUnitLoading = (loading = false) => ({
  type: Types.PROJECT_UNIT_SET_LOADING,
  payload: {
    loading,
  },
});

const fetchProjectUnit = (projectID, unitID, query = {}) => {
  const q = Object.assign({}, query);
  const url = `${Endpoints.PROJECT_UNIT(projectID, unitID)}/?${qs.stringify(q)}`;
  const options = {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  let token;
  return (dispatch) => {
    dispatch(projectUnitRequest());

    return dispatch(fetchUrl(url, options))
      .then((res) => {
        token = getToken(res);
        return resJson(res);
      })
      .then((data) => {
        dispatch(projectUnitSuccess({ data, token }));
        return data;
      })
      .catch(err => dispatch(handleError(err, projectUnitFailure)));
  };
};

export default {
  setProjectUnitLoading,

  projectUnitRequest,
  projectUnitSuccess,
  projectUnitFailure,

  projectUnitReset,

  fetchProjectUnit,
};

import { createReducer } from '@mhub/web-redux-utilities';
import Types from './types';

const initialState = {
  loading: false,
  statusText: null,
  isError: false,
  data: null,
  isAuthenticated: false,
  isAdfs: false,
  adfsProvider: '',
};

export default createReducer(initialState, {
  [Types.AUTHENTICATE_SET_LOADING]: (state, payload) => (
    Object.assign({}, state, {
      loading: payload.loading,
    })
  ),
  [Types.AUTHENTICATE_SET_IS_AUTHENTICATED]: (state, payload) => (
    Object.assign({}, state, {
      isAuthenticated: payload.isAuthenticated,
    })
  ),
  [Types.AUTHENTICATE_REQUEST]: state => (
    Object.assign({}, state, {
      loading: true,
      isError: false,
      statusText: null,
      isAdfs: false,
      adfsProvider: '',
      isAuthenticated: false,
    })
  ),
  [Types.AUTHENTICATE_SUCCESS]: (state, payload) => (
    Object.assign({}, state, {
      loading: false,
      data: Object.assign({}, payload.data),
      isAuthenticated: true,
    })
  ),
  [Types.AUTHENTICATE_FAILURE]: (state, payload) => (
    Object.assign({}, state, {
      loading: false,
      isError: true,
      statusText: payload.statusText,
    })
  ),
  [Types.AUTHENTICATE_LOGOUT_REQUEST]: state => (
    Object.assign({}, state, {
      loading: true,
      isError: false,
      statusText: null,
    })
  ),
  [Types.AUTHENTICATE_LOGOUT_SUCCESS]: state => (
    Object.assign({}, state, {
      loading: false,
      data: null,
      isAdfs: false,
      adfsProvider: '',
      isAuthenticated: false,
    })
  ),
  [Types.AUTHENTICATE_LOGOUT_FAILURE]: (state, payload) => (
    Object.assign({}, state, {
      loading: false,
      isError: true,
      statusText: payload.statusText,
    })
  ),
  [Types.AUTHENTICATE_SET_ADFS]: (state, payload) => (
    Object.assign({}, state, {
      isAdfs: !!payload.provider,
      adfsProvider: payload.provider,
    })
  ),
});

/* eslint react/forbid-prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import config from './config';
import RouteApplied from './components/RouteApplied';
import RouteAuthenticated from './components/RouteAuthenticated';

import {
  AsyncHome,
  AsyncPreview,
  AsyncNotFound,
  AsyncWidget,
  AsyncSalesChart,
} from './RouteLoaders';

const isShareMode = config.app.shareMode;

const Routes = ({ childProps }) => {
  if (isShareMode) {
    return (
      <Switch>
        <Route path="/not_found/" component={AsyncNotFound} />
        <RouteApplied path="/:shortCode/siteplan-widget/" exact component={AsyncWidget} props={childProps} />
        <RouteApplied path="/:shortCode/saleschart/" exact component={AsyncSalesChart} props={childProps} />
        <RouteApplied path="/:shortCode/preview/" exact component={AsyncPreview} props={childProps} />
        <RouteApplied path="/:shortCode/" exact component={AsyncHome} props={childProps} />
        <Redirect to="/not_found/" />
      </Switch>
    );
  }
  return (
    <Switch>
      <Route path="/not_found/" component={AsyncNotFound} />
      <RouteApplied path="/siteplan-widget/" exact component={AsyncWidget} props={childProps} />
      {/* <RouteApplied path="/auth" exact component={AsyncAuth} props={childProps} /> */}
      <RouteAuthenticated path="/saleschart/" exact component={AsyncSalesChart} props={childProps} />
      <RouteAuthenticated path="/preview/" exact component={AsyncPreview} props={childProps} />
      <RouteApplied path="/" exact component={AsyncHome} props={childProps} />
      <Redirect to="/not_found/" />
    </Switch>
  );
};

Routes.propTypes = {
  childProps: PropTypes.object,
};

Routes.defaultProps = {
  childProps: null,
};

export default Routes;

// @flow

import Types from './types';

const networkOK = () => ({
  type: Types.NETWORK_OK,
});

const networkError = () => ({
  type: Types.NETWORK_FAILURE,
});

export default {
  networkOK,
  networkError,
};

import numeral from 'numeral';

function replaceAll(string, search, replacement) {
  return string.split(search).join(replacement);
}

function amountIsValid(amountFull, iAmount) {
  if (amountFull !== iAmount) {
    return false;
  }
  return true;
}

export default function amountToString(
  amountDecimal,
  amountFlat,
  amountFull,
  amountTimes,
) {
  // save as string
  let amount = '';
  let sDecimal = '00';
  let sflat = '';
  let sTimes = '';
  if (amountDecimal) sDecimal = amountDecimal.toString();
  if (amountFlat) sflat = amountFlat.toString();
  if (amountTimes) sTimes = amountTimes.toString();
  // correct decimal
  let fsDecimal = sDecimal;
  if (sTimes.substr(1).length > sDecimal.length) {
    fsDecimal = '0'.repeat(sTimes.substr(1).length - sDecimal.length) + sDecimal;
  }
  // join flat plus corrected decimal
  amount = `${sflat}.${fsDecimal}`;
  // convert float64 to int
  const iAmount = parseInt(replaceAll(amount, '.', ''), 10);
  // check if amountfull matches corrected decimal amount
  if (!amountIsValid(amountFull, iAmount)) {
    return '0';
  }
  return amount;
}

function formatAmount(amount = '-', format) {
  let fAmount;
  if (format) {
    fAmount = numeral(amount).format(format);
  } else {
    fAmount = ((amount * 1) >= 1000000 && (amount * 1) < 100000000 ?
      numeral(amount).format('0.0a') :
      numeral(amount).format('0a'));
  }
  return fAmount;
}

export function spaFullString(item, format) {
  const amountDecimal = item.spaDecimal || 0;
  const amountFlat = item.spaValue || 0;
  const amountTimes = item.spaTimes || 100;
  const amountFull = item.spaFull || item.spaValue * amountTimes;
  if (format) {
    return formatAmount(amountToString(amountDecimal, amountFlat, amountFull, amountTimes), format);
  }
  return amountToString(amountDecimal, amountFlat, amountFull, amountTimes);
}

export function spaFullNumber(item) {
  const times = item.spaTimes || 100;
  return parseFloat(spaFullString(item)).toFixed(times.toString().length - 1);
}

export function fullToAmount(item, fullAmount) {
  const i = item;
  const amount = fullAmount.toString();
  if (amount.includes('.')) {
    let decimal = amount.split('.')[1];
    const intDecimal = parseInt(decimal, 10);
    if (intDecimal < 10) {
      if (decimal.length === 1) {
        decimal = `${intDecimal}0`;
      } else {
        decimal = `0${intDecimal}`;
      }
    }
    i.spaDecimal = decimal;
    i.spaValue = amount.split('.')[0];
    i.spaTimes = `1${'0'.repeat(decimal.length)}`;
    i.spaFull = amount.replace('.', '');
  } else {
    i.spaDecimal = 0;
    i.spaValue = amount;
    i.spaTimes = 100;
    i.spaFull = amount * i.spaTimes;
  }
  i.spaDecimal = parseInt(i.spaDecimal, 10);
  i.spaValue = parseInt(i.spaValue, 10);
  i.spaTimes = parseInt(i.spaTimes, 10);
  i.spaFull = parseInt(i.spaFull, 10);
  return i;
}

export function amountToFixed(amount, times) {
  const t = times || 100;
  return parseFloat(amount).toFixed(t.toString().length - 1);
}

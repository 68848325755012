import qs from 'qs';
import config from '../../config';
import Endpoints from '../../endpoints';
import fetchUrl from '../../utils/fetchUrl';
import { handleError, resJson } from '../../utils/error';
import { getToken, getCount, getData, getHasMore, getRefreshAt } from '../helpers';

import Types from './types';

const projectUnitsRequest = () => ({
  type: Types.PROJECT_UNITS_REQUEST,
});

const projectUnitsSuccess = ({
  data, pagination, token, refreshAt,
}) => ({
  type: Types.PROJECT_UNITS_SUCCESS,
  payload: {
    data,
    pagination,
    token,
    refreshAt,
  },
});

const projectUnitsRefresh = ({
  data, pagination, token, refreshAt,
}) => ({
  type: Types.PROJECT_UNITS_REFRESH,
  payload: {
    data,
    pagination,
    token,
    refreshAt,
  },
});

const projectUnitsFailure = message => ({
  type: Types.PROJECT_UNITS_FAILURE,
  payload: {
    statusText: message,
  },
});

const projectUnitsReset = () => ({
  type: Types.PROJECT_UNITS_RESET,
});

const setProjectUnitsLoading = (loading = false) => ({
  type: Types.PROJECT_UNITS_SET_LOADING,
  payload: {
    loading,
  },
});

const fetchProjectUnits = (projectID, query = {}) => {
  const { limit } = config.query || 0;
  const q = Object.assign({
    limit,
    offset: 0,
  }, query);
  const url = `${Endpoints.PROJECT_UNITS(projectID)}/?${qs.stringify(q)}`;
  const options = {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  let token;
  let refreshAt;
  let count = 0;
  return (dispatch) => {
    dispatch(projectUnitsRequest());
    return dispatch(fetchUrl(url, options))
      .then((res) => {
        token = getToken(res);
        count = getCount(res);
        refreshAt = getRefreshAt(res);
        return resJson(res);
      })
      .then((payload) => {
        const data = getData(payload, q.limit);
        const hasMore = getHasMore(data, q.limit);
        const pagination = {
          count,
          hasMore,
          offset: q.offset,
          limit: q.limit,
        };
        const newPayload = {
          data,
          pagination,
          token,
          refreshAt,
        };
        if (q.refreshAt) {
          dispatch(projectUnitsRefresh(newPayload));
        } else {
          dispatch(projectUnitsSuccess(newPayload));
        }
        return newPayload;
      })
      .catch(err => dispatch(handleError(err, projectUnitsFailure)));
  };
};

export default {
  setProjectUnitsLoading,

  projectUnitsRequest,
  projectUnitsSuccess,
  projectUnitsFailure,
  projectUnitsRefresh,
  projectUnitsReset,

  fetchProjectUnits,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './ToggleButton.css';

export default class ToggleButton extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    active: false,
    onChange: null,
  };

  constructor(props) {
    super(props);
    const { active } = props;
    this.state = {
      active,
      prevPropsActive: active,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { active } = props;
    const { prevPropsActive } = state;
    return {
      active: active !== prevPropsActive ? active : state.active,
      prevPropsActive: active,
    };
  }

  classNames = () => {
    const { disabled } = this.props;
    const { active } = this.state;
    const names = ['ToggleButton'];
    if (disabled) {
      names.push('disabled');
    }
    if (active) {
      names.push('active');
    }
    return names.join(' ');
  }

  handleOnChange = (e) => {
    const { disabled, onChange } = this.props;
    if (disabled) {
      e.preventDefault();
      return;
    }

    const active = !this.state.active;
    this.setState({ active }, () => {
      if (onChange) {
        onChange(e, active);
      }
    });
  }

  render() {
    const { children } = this.props;
    const { active } = this.state;
    const classNames = this.classNames();
    return (
      <label className={classNames}>
        <input
          type="checkbox"
          onChange={this.handleOnChange}
          checked={active}
        />
        <div className="slider" />
        {children}
      </label>
    );
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Dropdown, Label, utils } from '@mhub/web-components';

import Selectors from '../redux/selectors';
import { TRANSLATION_FILES } from '../constants/translation';

import './BlockFilter.css';

class BlockFilter extends PureComponent {
  static mapStateToProps = state => ({
    auth: Selectors.getAuthenticateData(state),
    project: Selectors.getProjectData(state),
    projectUnits: Selectors.getProjectUnitsData(state),
  })
  static propTypes = {
    auth: PropTypes.shape({
      groups: PropTypes.array,
    }),
    onChange: PropTypes.func,
    value: PropTypes.string,
    floating: PropTypes.bool,
    // redux
    project: PropTypes.shape({
      id: PropTypes.string,
      siteplanSettings: PropTypes.object,
      propertyCategory: PropTypes.shape({
        category: PropTypes.string,
      }),
    }),
    projectUnits: PropTypes.arrayOf(PropTypes.object),
    projectUnitIDs: PropTypes.arrayOf(PropTypes.string),
  }
  static contextTypes = {
    store: PropTypes.object,
  }
  static defaultProps = {
    auth: null,
    onChange: null,
    value: null,
    floating: false,
    // redux
    project: null,
    projectUnits: [],
    projectUnitIDs: [],
  }
  constructor(props) {
    super(props);
    const { project } = props;
    const projectID = project ? project.id : null;
    this.state = {
      prevProps: { projectID },
      siteplanSettings: BlockFilter.getProjectSettings(project),
    };
  }
  static getDerivedStateFromProps(props, state) {
    const { prevProps } = state;
    // Compare the incoming prop to previous prop
    const { project } = props;
    const projectID = project ? project.id : null;
    return {
      // Store the previous props in state
      prevProps: { projectID },
      siteplanSettings: prevProps.projectID !== projectID ? BlockFilter.getProjectSettings(project) : state.siteplanSettings,
    };
  }
  static getProjectSettings(project) {
    return Object.assign({
      hideTowersAndLevels: false,
      overlayMode: 'text',
    }, project && project.siteplanSettings);
  }
  static getFilteredArray = (arr, field, floating = false) => {
    const results = arr.reduce((accumulate, item, idx) => {
      if (item[field] !== '' && !accumulate.some(r => r === item[field])) {
        return [...accumulate, item[field]];
      }
      return accumulate;
    }, []);
    results.sort();
    return results;
  }
  classNames = () => {
    const { floating } = this.props;
    const names = ['BlockFilter'];
    if (floating) {
      names.push('floating');
    }
    return names.join(' ');
  }
  render() {
    const { project, projectUnits, projectUnitIDs, onChange, value, floating, t } = this.props;
    const { siteplanSettings } = this.state;
    const { hideTowersAndLevels } = siteplanSettings;
    const isHighRise = project && project.propertyCategory && project.propertyCategory.category === 'high_rise';
    const blocks = BlockFilter.getFilteredArray(projectUnits.filter((unit) => projectUnitIDs.some((id) => id === unit.id) && unit.statusID !== 'delisted'), 'zoneOrBlock', floating);
    const placeholder = t(floating ? 'Select {{name}}' : 'None', { name: t('Tower')});
    if (!isHighRise || hideTowersAndLevels || !utils.arrayHasItems(blocks)) {
      return null;
    }
    const classNames = this.classNames();
    const child = (
      <Dropdown
        className={classNames}
        bordered
        fluid={!floating}
        withPortal
        name="zoneOrBlock"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        options={[
          {
            title: placeholder,
            value: null,
          },
        ].concat(blocks.map(b => ({
          title: b,
          value: b,
        })))}
      />
    );
    if (floating) {
      return child;
    }
    return (
      <Label content={t('Tower')} noHelper>
        {child}
      </Label>
    );
  }
}

export default withTranslation(TRANSLATION_FILES.screens)(connect(BlockFilter.mapStateToProps)(BlockFilter));

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';

import logo from '../assets/logos/logo-horizontal.svg';
import iconMenu from '../assets/icons/ic-menu.svg';
import iconClose from '../assets/icons/ic-close.svg';
import iconNext from '../assets/icons/ic-next.svg';
import iconBack from '../assets/icons/ic-back.svg';
import iconLogout from '../assets/icons/ic-logout.svg';
import iconProfile from '../assets/icons/ic-profile.svg';

import Actions from '../redux/actions';
import Selectors from '../redux/selectors';
import { ALLOWED_GROUPS, SUPER_ADMIN, ADMIN_BASIC, ADMIN_COMMUNITY, DEVELOPER_TEAM_LEADER, ADMIN, DEVELOPER_SITEPLAN } from '../constants/userGroups';
import { LOAN_TRACKER, SHOWROOM } from '../constants/apps';
import { TRANSLATION_FILES } from '../constants/translation';
import { redirectToLogin } from '../utils/fetchUrl';
import { getSharePath } from '../utils/url';

import config from '../config';

import AppMenuButton from './AppMenuButton';
import './AppMenu.css';
import './Modal.css';

const MAIN = 'MAIN';
const PROJECTS = 'PROJECTS';
const isShareMode = config.app.shareMode;

const roles = [
  { text: 'Headquarters', value: '10,6' },
  { text: 'Supervisor', value: '10,11,12,13' },
  { text: 'Sales Manager', value: '10,11,13' },
  { text: 'Sales Admin', value: '10,11' },
  { text: '', value: '10' },
];

class AppMenu extends PureComponent {
  static mapStateToProps = state => ({
    pathname: state.router.location.pathname,
    isAuthenticated: Selectors.getAuthenticateIsAuthenticated(state),
    auth: Selectors.getAuthenticateData(state),
    project: Selectors.getProjectData(state),
    projects: Selectors.getProjectsData(state),
    companyCode: Selectors.getAppCompanyCode(state),
  })
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    auth: PropTypes.shape({
      preferredName: PropTypes.string,
      groups: PropTypes.array,
      affiliate: PropTypes.shape({
        company: PropTypes.shape({
          name: PropTypes.string,
          apps: PropTypes.array,
        }),
        companyBranch: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    }),
    isAuthenticated: PropTypes.bool,
    project: PropTypes.shape({
      id: PropTypes.string,
    }),
    projects: PropTypes.arrayOf(PropTypes.object),
    companyCode: PropTypes.string,
  }
  static contextTypes = {
    store: PropTypes.object,
  }
  static defaultProps = {
    auth: null,
    project: null,
    projects: null,
    companyCode: '',
    isAuthenticated: false,
  }
  state = {
    active: false,
    currentMenu: MAIN,
  };
  getRoleName = (groups) => {
    const rolesArr = [];
    if (groups) {
      if (groups.some(g => g.code === SUPER_ADMIN)) {
        return 'Super Admin';
      }
      if (groups.some(g => g.code === ADMIN_COMMUNITY)) {
        return 'Admin Community';
      }
      if (groups.some(g => g.code === ADMIN)) {
        return 'Admin';
      }
      if (groups.some(g => g.code === DEVELOPER_TEAM_LEADER)) {
        return 'Team Leader';
      }
      groups.forEach((g) => {
        rolesArr.push(g.id);
      });
      rolesArr.sort();
      const role = roles.find(g => g.value === String(rolesArr.toString()));
      if (role && role.text) {
        return role.text;
      }
    }
    return null;
  }
  getRedirectURL = (appID, type = 'web') => {
    const { auth } = this.props;
    const apps = auth && auth.affiliate && auth.affiliate.company && auth.affiliate.company.apps ?
      auth.affiliate.company.apps.filter(app => app.hasAccess) : [];
    let url;
    const isAdmin = this.hasAccess([SUPER_ADMIN, ADMIN_BASIC, ADMIN_COMMUNITY]);
    if (isAdmin) {
      url = config.gateways.START_URL;
    } else {
      const app = apps.find(a => a.appID === appID);
      if (app) {
        url = type === 'api' ? app.apiURL : app.webURL;
      }
    }
    return url;
  }
  getProjectRoute = (data) => {
    const { companyCode, pathname } = this.props;
    if (data) {
      const hasSiteplan = (_.has(data, 'siteplanAppEnabled') ? data.siteplanAppEnabled : data.salesChartType === 'saleschart-app') && data.siteplanID;
      const hasSalesChart = (_.has(data, 'salesChartEnabled') ? data.salesChartEnabled : (data.salesChartType !== 'saleschart-app' && data.salesChartType !== 'disabled'));
      let path = '';
      if (hasSiteplan) {
        path = 'preview';
      }
      if (hasSalesChart) {
        path = 'saleschart';
      }
      if (!pathname.includes(path)) {
        if (path === 'preview' && hasSalesChart) {
          path = 'saleschart';
        } else if (path === 'saleschart' && hasSiteplan) {
          path = 'preview';
        }
      }
      if (path) {
        return `${companyCode}/${path}/?projectID=${data.id}`;
      }
    }
    return '/';
  }
  hasAccess = (checkGroups = []) => {
    const { auth } = this.props;
    if (auth && auth.groups && auth.groups.length > 0) {
      return auth.groups.some(g => checkGroups.some(ag => ag === g.code));
    }
    return false;
  }
  handleSetActive = (active = !this.state.active) => () => {
    let { currentMenu } = this.state;
    if (active) {
      currentMenu = MAIN;
    }
    this.setState({ active, currentMenu });
  }
  handleToggleMenu = this.handleSetActive()
  handleOnClose = this.handleSetActive(false)
  handleLogin = (e) => {
    e.preventDefault();
    const { store } = this.context;
    store.dispatch(redirectToLogin());
  }
  handleLogout = (e) => {
    e.preventDefault();
    this.handleOnClose();
    const { store } = this.context;
    store.dispatch(Actions.logoutAndRedirect());
  }
  handleChangeMenu = currentMenu => (e) => {
    e.preventDefault();
    this.setState({ currentMenu });
  }
  renderProfile = () => {
    const { auth, t } = this.props;
    if (auth) {
      const { groups, preferredName } = auth;
      const roleName = this.getRoleName(groups);
      return (
        <div className="profile">
          <img
            src={iconProfile}
            alt="Profile"
          />
          <div className="name">
            <h4>{preferredName}</h4>
            {roleName && <div className="role">{roleName}</div>}
          </div>
        </div>
      );
    }
    return <h4>{t('Menu')}</h4>;
  }
  renderMenuHeader = () => {
    const { currentMenu } = this.state;
    const { t } = this.props;
    const isProjectMenu = currentMenu === PROJECTS;
    return (
      <div className={`header ${isProjectMenu ? 'extended' : ''}`}>
        {
          isProjectMenu && (
            <AppMenuButton
              icon={iconBack}
              onClick={this.handleChangeMenu(MAIN)}
            />
          )
        }
        {
          isProjectMenu ? (
            <div className="title">
              <h4>{t('Projects')}</h4>
            </div>
          ) : (
            <img
              className="logo"
              src={logo}
              alt="Logo"
            />
          )
        }
        <AppMenuButton
          icon={iconClose}
          onClick={this.handleOnClose}
        />
      </div>
    );
  }
  renderMenuContent = () => {
    const { project, projects, auth, pathname, companyCode, t } = this.props;
    const { currentMenu } = this.state;
    if (currentMenu === PROJECTS) {
      return (
        <div className="content projects">
          {
            projects.filter(p => !(project && p.id === project.id)).map(p => (
              <Link
                key={p.id}
                onClick={this.handleOnClose}
                to={this.getProjectRoute(p)}
              >
                {p.name}
              </Link>
            ))
          }
        </div>
      );
    }
    const hasAccess = this.hasAccess(ALLOWED_GROUPS);
    const apps = auth && auth.affiliate && auth.affiliate.company && auth.affiliate.company.apps ?
      auth.affiliate.company.apps.filter(app => app.hasAccess) : [];
    let loanTrackerWebURL;
    let showroomWebURL;
    if (hasAccess && apps.some(app => app.appID === LOAN_TRACKER || app.appID === SHOWROOM)) {
      loanTrackerWebURL = this.getRedirectURL(LOAN_TRACKER);
      showroomWebURL = this.getRedirectURL(SHOWROOM);
    }
    const isSiteplanUser = this.hasAccess([DEVELOPER_SITEPLAN]);
    return (
      <div className="content">
        {
          (!getSharePath().includes('not_found') && pathname !== `${companyCode}/`) && <Link onClick={this.handleOnClose} to={`${companyCode}/`}>{t('Home')}</Link>
        }
        {
          (pathname.includes('/preview/') || pathname.includes('/saleschart/')) && projects && projects.filter(p => !(project && p.id === project.id)).length > 0 && (
            <button onClick={this.handleChangeMenu(PROJECTS)}>
              {t('View more projects')}
              <AppMenuButton
                icon={iconNext}
                basic
              />
            </button>
          )
        }
        {!isSiteplanUser && loanTrackerWebURL && <a href={loanTrackerWebURL}>Loan Tracker</a>}
        {!isShareMode && !isSiteplanUser && showroomWebURL && <a href={showroomWebURL}>Showroom</a>}
      </div>
    );
  }
  renderMenuFooter = () => {
    const { isAuthenticated } = this.props;
    const versionElem = (<div className="version">Version {config.app.version}</div>);
    return (
      <div className="footer">
        {
          isShareMode ? versionElem : (
            <div className="row">
              {this.renderProfile()}
              {versionElem}
            </div>
          )
        }
        {
          !isShareMode && isAuthenticated && (
            <button
              className="logout"
              onClick={this.handleLogout}
            >
              <img
                src={iconLogout}
                alt="Logout"
              />
            </button>
          )
        }
      </div>
    );
  }
  render() {
    const { currentMenu, active } = this.state;
    return (
      <Fragment>
        <Modal
          isOpen={active}
          onRequestClose={this.handleOnClose}
          closeTimeoutMS={500}
          className="modal side left"
          overlayClassName="modal-overlay"
          ariaHideApp={false}
        >
          <div className="AppMenu">
            {this.renderMenuHeader()}
            {this.renderMenuContent()}
            {currentMenu !== PROJECTS && this.renderMenuFooter()}
          </div>
        </Modal>
        <AppMenuButton
          icon={iconMenu}
          onClick={this.handleToggleMenu}
        />
      </Fragment>
    );
  }
}

export default withTranslation(TRANSLATION_FILES.screens)(connect(AppMenu.mapStateToProps)(AppMenu));

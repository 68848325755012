import 'eligrey-classlist-js-polyfill';
import _ from 'underscore';
import React, { Component, Fragment } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InlineSVG from 'react-inlinesvg';
import { withTranslation } from 'react-i18next';

import icZoomIn from '../assets/icons/ic-zoom-in-grey.svg';
import icZoomOut from '../assets/icons/ic-zoom-out-grey.svg';
import icMax from '../assets/icons/ic-max-grey.svg';
import icRefresh from '../assets/icons/ic-refresh-grey.svg';

import Button from './Button';

import UNIT_STATUSES from '../constants/unitStatuses';
import { HIGH_LEVEL_GROUPS, hasLimitedAccess } from '../constants/userGroups';
import {
  SVGNS,
  XLINKNS,
  SVG_MAIN,
  SVG_SITEPLAN,
  SVG_IMAGE,
  SVG_UNITS,
  SVG_UNIT_PREFIX,
  MHUB_IDENTIFIER,
} from '../constants/svgElements';
import { TRANSLATION_FILES } from '../constants/translation';

import Actions from '../redux/actions';
import Selectors from '../redux/selectors';

import { zoomIn, zoomOut, getMatrixPosLimit } from '../utils/zoom';
import { isNodeTag } from '../utils/element';

import Endpoints from '../endpoints';

import './SVGViewer.css';
import LevelFilter from './LevelFilter';

const ZOOM_VALUE = 1.2;
const BUTTON_ZOOM_VALUE = ZOOM_VALUE ** 5;

let timer;

const debounce = func => (event) => {
  if (timer) clearTimeout(timer);
  timer = setTimeout(func, 500, event);
};

const getAxis = (e, axis = 'x') => {
  const type = `client${axis.toUpperCase()}`;
  if (e.changedTouches && e.changedTouches.length > 0) {
    return e.changedTouches[0][type];
  }
  return e[type];
};

const getUploadedAt = (uploadedAt) => {
  if (uploadedAt) {
    return `_${moment(uploadedAt).tz('Asia/Kuala_Lumpur').format('YYYYMMDDHHmmss')}`;
  }
  return '';
};

const getUnitId = (id) => {
  const colonIndex = id.indexOf(':');
  const directionIndex = id.indexOf(':', colonIndex + 1);

  const identifier = id.substring(MHUB_IDENTIFIER.length, colonIndex);

  let type;
  if (identifier === 'id') {
    type = identifier;
  }

  let direction;
  let value = id.substring(colonIndex + 1, id.length);
  if (directionIndex > 0) {
    value = id.substring(colonIndex + 1, directionIndex);
    direction = id.substring(directionIndex + 1, id.length);
  }

  return {
    type,
    value: value.toLowerCase(),
    direction,
    elementID: id,
  };
};

class SVGViewer extends Component {
  static mapStateToProps = state => ({
    auth: Selectors.getAuthenticateData(state),
    project: Selectors.getProjectData(state),
    projectUnits: Selectors.getProjectUnitsData(state),
    unitsIsLoading: Selectors.getProjectUnitsLoading(state),
    projectUnitsRefreshAt: Selectors.getProjectUnitsRefreshAt(state),
    nonMHubUnitCount: Selectors.getSiteplanCount(state),
    filterStatus: Selectors.getSiteplanFilterStatus(state),
    filterLayout: Selectors.getSiteplanFilterLayout(state),
    filterBlock: Selectors.getSiteplanFilterBlock(state),
    filterLevel: Selectors.getSiteplanFilterLevel(state),
    filterCategory: Selectors.getSiteplanFilterCategory(state),
  })
  static propTypes = {
    auth: PropTypes.shape({
      groups: PropTypes.array,
    }),
    children: PropTypes.node,
    svgData: PropTypes.node,
    imgData: PropTypes.string,
    project: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      siteplan: PropTypes.object,
      propertyCategory: PropTypes.shape({
        category: PropTypes.string,
      }),
    }),
    projectUnits: PropTypes.arrayOf(PropTypes.object).isRequired,
    unitsIsLoading: PropTypes.bool,
    projectUnitsRefreshAt: PropTypes.string,
    hideButton: PropTypes.bool,
    isMarketplace: PropTypes.bool,
    onSelect: PropTypes.func,
    onSVGLoaded: PropTypes.func,
    limitUnits: PropTypes.arrayOf(PropTypes.string),
    // unit
    unitOnHover: PropTypes.func,
    unitOnClick: PropTypes.func,
    unitClickable: PropTypes.bool,
    // autoReload
    autoReload: PropTypes.bool,
    onAutoReload: PropTypes.func,
    // redux
    nonMHubUnitCount: PropTypes.number,
    filterStatus: PropTypes.string,
    filterLayout: PropTypes.string,
    filterBlock: PropTypes.string,
    filterLevel: PropTypes.string,
    filterCategory: PropTypes.string,
  }
  static contextTypes = {
    store: PropTypes.object,
  }
  static defaultProps = {
    auth: null,
    children: null,
    svgData: null,
    imgData: null,
    project: null,
    unitsIsLoading: false,
    projectUnitsRefreshAt: null,
    hideButton: false,
    isMarketplace: false,
    onSelect: null,
    onSVGLoaded: null,
    limitUnits: [],
    // unit
    unitOnHover: null,
    unitOnClick: null,
    unitClickable: false,
    // autoReload
    autoReload: false,
    onAutoReload: null,
    // redux
    nonMHubUnitCount: 0,
    filterStatus: null,
    filterLayout: null,
    filterBlock: null,
    filterLevel: null,
    filterCategory: null,
  }

  constructor(props) {
    super(props);
    const oriMatrix = [1, 0, 0, 1, 0, 0];
    const {
      project, svgData, imgData, autoReload,
    } = props;
    const projectID = null;
    this.state = {
      prevProps: {
        svgData, autoReload, projectID, imgData,
      },
      projectID,
      siteplanSettings: SVGViewer.getProjectSettings(project),
      // svg
      oriMatrix,
      matrix: [].concat(oriMatrix),
      startX: 0,
      startY: 0,
      svgWidth: 0,
      svgHeight: 0,
      width: 0,
      height: 0,
      hasImage: false, // TODO: add className when has no image and svg will have different style
      // component state
      svgData,
      imgData,
      selected: null,
      hovered: null,
      isLoaded: false,
      isError: false,
      autoReload,
      isMatrixInitialied: false,
      // mouse event
      dragging: false,
      zooming: false,
      // image
      imgLoaded: false,
      imgError: false,
      tpCache: [],
      hasInitialized: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { prevProps } = state;
    // Compare the incoming prop to previous prop
    const {
      svgData, imgData, autoReload, project,
    } = props;
    const projectID = project ? project.id : null;
    return {
      // Store the previous props in state
      prevProps: {
        svgData, autoReload, projectID, imgData,
      },
      svgData: prevProps.svgData !== svgData ? svgData : (prevProps.projectID !== projectID ? svgData : state.svgData),
      imgData: prevProps.imgData !== imgData ? imgData : (prevProps.projectID !== projectID ? imgData : state.imgData),
      autoReload: prevProps.autoReload !== autoReload ? autoReload : state.autoReload,
      projectID,
      siteplanSettings: prevProps.projectID !== projectID ? SVGViewer.getProjectSettings(project) : state.siteplanSettings,
      isLoaded: prevProps.projectID === projectID && state.isLoaded,
      isError: prevProps.projectID === projectID && state.isError,
      imgLoaded: prevProps.projectID === projectID && state.imgLoaded,
      imgError: prevProps.projectID === projectID && state.imgError,
      isMatrixInitialied: prevProps.projectID === projectID && state.isMatrixInitialied,
    };
  }

  async componentDidMount() {
    if (this.props.project) {
      await this.setSvgData();
    }
    if (this.props.projectUnits) {
      await this.updateMHubUnitsSVG();
    }
    const svgContent = this.state.svgData || this.props.svgData || this.props.children;
    if (svgContent) {
      await this.setupSVG();
    }
    document.addEventListener('mouseup', this.handleDragStop, false);
    document.addEventListener('mousemove', this.handleDragMove, false);
    document.addEventListener('touchmove', this.handleDragMove, false);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      unitsIsLoading, filterLayout, filterBlock, filterLevel, filterCategory, project,
    } = this.props;
    const {
      projectID, matrix, isLoaded, dragging, zooming, hasInitialized,
    } = this.state;
    if (projectID !== prevState.projectID) {
      this.setSvgData();
    }
    if (matrix !== prevState.matrix) {
      this.updateSVG(!dragging && !zooming);
    }
    if (
      (!unitsIsLoading && prevProps.unitsIsLoading) ||
      filterLayout !== prevProps.filterLayout ||
      filterBlock !== prevProps.filterBlock ||
      filterLevel !== prevProps.filterLevel ||
      filterCategory !== prevProps.filterCategory ||
      (isLoaded && !prevState.isLoaded)
    ) {
      this.updateMHubUnitsSVG();
    }
    if (project && project.propertyCategory && project.propertyCategory.category === 'high_rise' && !filterLevel && !hasInitialized) {
    //   this.handleFilterDefaultLevel();
    }
    if (filterBlock !== prevProps.filterBlock || filterLevel !== prevProps.filterLevel) {
      this.zoomToBlockOrLevel();
    }
  }

  componentWillUnmount() {
    const svg = document.getElementById(SVG_MAIN);
    if (svg) {
      svg.removeEventListener('dblclick', this.handleZoomIn, false);
      svg.removeEventListener('mousedown', this.handleDragStart, false);
      svg.removeEventListener('touchstart', this.handleDragStart, false);
      svg.removeEventListener('touchend', this.handleDragStop, false);
      svg.removeEventListener('touchcancel', this.handleTouchCancel, false);
      svg.removeEventListener('wheel', this.handleMouseWheel, false);

      // removeEventListener from each unit
      if (this.props.unitClickable) {
        const unitGroup = document.getElementById(SVG_UNITS);
        if (unitGroup && unitGroup.childNodes && unitGroup.childNodes.length > 0) {
          const unitElements = Array.from(unitGroup.childNodes);
          unitElements.forEach((child) => {
            if (this.checkIsMHubUnit(child.id)) {
              child.onclick = null;
              child.onmouseover = null;
              child.onmouseout = null;
            }
          });
        }
      }

      window.removeEventListener('resize', debounce(this.resizeSVG), false);
    }

    document.removeEventListener('mouseup', this.handleDragStop, false);
    document.removeEventListener('mousemove', this.handleDragMove, false);
    document.removeEventListener('touchmove', this.handleDragMove, false);
  }

  static getProjectSettings(project) {
    return Object.assign({
      overlayMode: 'text',
      defaultFilter: false,
    }, project && project.siteplanSettings);
  }

  getUnitData = (id, props = this.props) => {
    const { type, value } = getUnitId(id);
    const { projectUnits } = props;
    if (type === 'id' && projectUnits && projectUnits.length > 0) {
      return projectUnits.find(unit => unit.id === value);
    }
    return null;
  }
  getUnitClassName = (status) => {
    switch (status) {
      case 'new':
        return 'new';
      case 'reserved':
      case 'developer_reserved':
        return 'reserved';
      case 'booked':
      case 'developer_booked':
        return 'booked';
      case 'sold_spa_signed':
      case 'developer_sold':
        return 'sold';
      default:
        return 'delisted';
    }
  }
  getAngleFromPolygon = (id, el) => {
    const { direction } = getUnitId(id);
    if (direction) {
      return direction;
    }
    if (el) {
      if (isNodeTag(el, 'polygon')) {
        let longestLine = {
          startPt: { x: 0, y: 0 },
          endPt: { x: 100, y: 0 },
          length: 0,
        };

        const { points } = el;
        if (points && points.numberOfItems > 0) {
          for (let i = 0; i < points.numberOfItems - 1; i += 1) {
            const startPt = points.getItem(i);
            const endPt = points.getItem(i + 1);
            const length = Math.sqrt(((startPt.x - endPt.x) ** 2) + ((startPt.y - endPt.y) ** 2));
            if (length > longestLine.length) {
              longestLine = { startPt, endPt, length };
            }
          }
        }
        /* determine angle of longest line segement relative to x axis */
        const dX = longestLine.startPt.x - longestLine.endPt.x;
        const dY = longestLine.startPt.y - longestLine.endPt.y;
        let angleInDegrees = ((Math.atan2(dY, dX) / Math.PI) * 180.0);
        // text angle between -90 to 90;
        if (angleInDegrees >= 89) {
          angleInDegrees -= 180;
        } else if (angleInDegrees < -92) {
          angleInDegrees += 180;
        }
        return angleInDegrees;
      }
      if (isNodeTag(el, 'rect')) {
        // Get the new x and y coordinates
        let degree = 0;

        // get transform value
        const transform = el.getAttribute('transform');
        if (transform) {
          // get rotate value
          const rotateReg = /rotate\((-?\d+(\.\d+)?)\)/i;
          if (transform.match(rotateReg)) {
            degree = parseFloat(transform.match(rotateReg)[1]);
          }
        }
        if (degree) {
          if (degree >= 89) {
            return degree - 180;
          } else if (degree < -92) {
            return degree + 180;
          }
          return degree;
        }
        const { width, height } = el.getBBox();
        return height > width ? -90 : 0;
      }
    }
    return 0;
  }


  setComponentState = state => new Promise((res) => {
    this.setState(state, () => {
      res(state);
    });
  });
  setSvgData = (props = this.props) => {
    const { project } = props;
    if (project && project.siteplan && project.siteplan.id) {
      const { siteplan } = project;
      const svgData = Endpoints.S3_FILE(`${siteplan.key}.svg`);
      const svgImageUrl = Endpoints.S3_FILE(`${siteplan.key}.jpg`);
      this.loadSiteplanImage(svgImageUrl);
      this.loadSiteplanSvg(svgData);
    }
  }
  setupSVG = () => {
    const svg = document.getElementById(SVG_MAIN);
    if (svg) {
      svg.setAttribute('xmlns', SVGNS);

      // remove all title
      const titles = document.getElementsByTagNameNS(SVGNS, 'title');
      if (titles && titles.length > 0) {
        for (let i = 0; i < titles.length; i += 1) {
          const parent = titles[i].parentNode;
          if (parent) {
            parent.removeChild(titles[i]);
          }
        }
      }

      // set mhub-units as top layer
      const unitGroup = document.getElementById(SVG_UNITS);
      if (unitGroup) {
        const parent = unitGroup.parentNode;
        if (parent && unitGroup !== parent.firstChild) {
          parent.insertBefore(unitGroup, null);
        }
      }

      let svgWidth = svg.getAttribute('width');
      let svgHeight = svg.getAttribute('height');

      // set width height
      const svgViewBox = svg.getAttribute('viewBox');
      if (svgViewBox) {
        const viewBox = svgViewBox.split(' ');
        [,, svgWidth, svgHeight] = viewBox; // array destructuring
        // svgWidth = viewBox[2];
        // svgHeight = viewBox[3];
      }

      // set svg to 100% width and height
      svg.setAttribute('width', '100%');
      svg.setAttribute('height', '100%');

      // remove viewBox attribute
      svg.removeAttribute('viewBox');

      // setup image
      const { project } = this.props;
      const { store } = this.context;
      if (project && project.siteplan && project.siteplan.id) {
        if (project.siteplanSettings && project.siteplanSettings.defaultFilter) {
          store.dispatch(Actions.setSiteplanFilterStatus('new'));
        }
      }

      window.addEventListener('resize', debounce(this.resizeSVG), false);

      svg.addEventListener('dblclick', this.handleZoomIn, false);
      svg.addEventListener('mousedown', this.handleDragStart, false);
      svg.addEventListener('touchstart', this.handleDragStart, false);
      svg.addEventListener('touchend', this.handleDragStop, false);
      svg.addEventListener('touchcancel', this.handleTouchCancel, false);
      svg.addEventListener('wheel', this.handleMouseWheel, false);

      this.setState({
        svgWidth: parseFloat(svgWidth),
        svgHeight: parseFloat(svgHeight),
        isLoaded: true,
      }, () => {
        this.resizeSVG();
        this.setupSVGImage();

        const { onSVGLoaded } = this.props;
        if (onSVGLoaded) {
          onSVGLoaded();
        }
      });
    }
  }
  setupSVGImage = (width = this.state.svgWidth, height = this.state.svgHeight) => {
    let imgURL;
    const { project } = this.props;
    const { imgData } = this.state;

    if (project && project.siteplan && project.siteplan.id) {
      imgURL = imgData;
    }
    const svg = document.getElementById(SVG_MAIN);
    if (svg) {
      let svgImg = document.getElementById(SVG_IMAGE);
      if (!svgImg) {
        // use createElementNS to create svg element
        svgImg = document.createElementNS(SVGNS, 'image');
        svgImg.setAttribute('id', SVG_IMAGE);

        const siteplan = document.getElementById(SVG_SITEPLAN);
        if (siteplan) {
          siteplan.insertBefore(svgImg, siteplan.firstChild);
        }
      }

      svgImg.setAttribute('xmlns:xlink', XLINKNS);
      svgImg.setAttribute('x', 0);
      svgImg.setAttribute('y', 0);
      svgImg.setAttribute('width', width);
      svgImg.setAttribute('height', height);
      // set image file from s3
      if (imgURL) {
        svgImg.setAttribute('href', imgURL);
        // xlink:href for safari
        svgImg.setAttributeNS(XLINKNS, 'xlink:href', imgURL);
      }
    }
  }
  loadSiteplanImage = (url, isRetry = false) => {
    const { project } = this.props;
    const image = new Image();
    image.src = url;
    image.onload = () => {
      this.handleOnImgLoad(url);
    };
    image.onerror = () => {
      if (!isRetry) {
        const imgUrl = Endpoints.S3_FILE(`${project.siteplan.key}${getUploadedAt(project.siteplan.imageUploadedAt)}.jpg`);
        // retry once
        this.loadSiteplanImage(imgUrl, true);
        return;
      }
      this.handleOnImgError();
      this.setComponentState({ imgData: '' });
    };
  };

  loadSiteplanSvg = (url, isRetry = false) => {
    const { project } = this.props;
    const request = new XMLHttpRequest();
    request.open('GET', url);
    request.setRequestHeader('Content-Type', 'image/svg+xml');
    request.onload = () => {
      if (request.status !== 200) {
        if (!isRetry) {
          const svgUrl = Endpoints.S3_FILE(`${project.siteplan.key}${getUploadedAt(project.siteplan.svgUploadedAt)}.svg`);
          // retry once
          this.loadSiteplanSvg(svgUrl, true);
          return;
        }
        this.setComponentState({ svgData: '' });
        return;
      }
      this.setComponentState({ svgData: url });
    };
    request.onerror = () => {
      this.setComponentState({ svgData: '' });
    };
    request.send();
  };

  wrapperRef = React.createRef();

  classNames = () => {
    const {
      filterStatus,
      filterLayout,
      filterBlock,
      filterLevel,
      auth,
    } = this.props;
    const { isLoaded, imgLoaded } = this.state;
    const names = ['SVGViewer'];
    const limitedAccessRole = hasLimitedAccess(auth);
    const { overlayMode } = this.state.siteplanSettings;
    const isColorOverlay = overlayMode === 'color';
    if (isColorOverlay) {
      names.push('color');
    }
    if (isLoaded && imgLoaded) {
      names.push('loaded');
    }
    if (!this.hasAccess(HIGH_LEVEL_GROUPS) && !limitedAccessRole) {
      names.push('basic');
    }
    if (filterStatus) {
      names.push(filterStatus);
      if (isColorOverlay) {
        names.push('color-filtered');
      }
    }
    if (filterLayout || filterBlock || filterLevel) {
      names.push('filtered');
    }
    return names.join(' ');
  }
  hasAccess = (checkGroups = []) => {
    const { auth } = this.props;
    if (auth && auth.groups && auth.groups.length > 0) {
      return auth.groups.some(g => checkGroups.some(ag => ag === g.code));
    }
    return false;
  }
  handleOnImgLoad = (url) => {
    this.setState({ imgData: url, imgLoaded: true }, () => {
      this.setupSVGImage();
    });
  }
  handleOnImgError = () => {
    this.setState({ imgError: true });
  }


  // check unit
  checkIsMHubUnit = (id) => {
    if (id && id.startsWith(SVG_UNIT_PREFIX)) {
      const { projectUnits, limitUnits } = this.props;
      if (limitUnits && limitUnits.length > 0 && limitUnits.some(unitID => unitID === 'none')) {
        return false;
      }

      if (projectUnits && projectUnits.length > 0) {
        const { type, value } = getUnitId(id);
        if (type === 'id') {
          if (projectUnits.some(unit => unit.id === value)) {
            if (limitUnits && limitUnits.length > 0) {
              return limitUnits.some(unitID => unitID === value);
            }
            return true;
          }
        }
      }
    }
    return false;
  }
  checkUnitWasSelectable = el => UNIT_STATUSES.some(s => el.classList.contains(s.value));
  checkUnitSelectable = (status) => {
    const { isMarketplace } = this.props;
    if (isMarketplace) {
      return status === 'new';
    }
    switch (status) {
      case 'new':
      case 'reserved':
      case 'developer_reserved':
      case 'booked':
      case 'developer_booked':
      case 'sold_spa_signed':
      case 'developer_sold':
        return true;
      default:
        return false;
    }
  }
  updateMHubUnitsSVG = (props = this.props) => {
    if (props.unitClickable) {
      const unitGroup = document.getElementById(SVG_UNITS);
      if (unitGroup && unitGroup.childNodes && unitGroup.childNodes.length > 0) {
        const unitElements = Array.from(unitGroup.childNodes);
        const textEls = Array.from(document.getElementsByTagNameNS(SVGNS, 'text'));
        const count = unitElements.reduce((total, child) => {
          if (isNodeTag(child, '#text') || isNodeTag(child, 'title') || isNodeTag(child, 'text')) {
            // ignore empty text tag
            return total;
          }
          let currentEl = child;
          if (!isNodeTag(currentEl, 'g')) {
            const wrapper = document.createElementNS(SVGNS, 'g');
            // move id to wrapper
            const { id } = currentEl;
            if (id) {
              wrapper.setAttribute('id', id);
              currentEl.removeAttribute('id');
            }
            // set the wrapper as child (instead of the element)
            const childParent = currentEl.parentNode;
            childParent.replaceChild(wrapper, currentEl);
            // set element as child of wrapper
            wrapper.appendChild(currentEl);
            // change currentEl to wrapper;
            currentEl = wrapper;
          }
          let statusText;
          const { overlayMode } = this.state.siteplanSettings;
          const isTextOverlay = overlayMode === 'text';
          if (isTextOverlay) {
            statusText = textEls.find(el => el.parentNode === child);
          }
          if (this.checkIsMHubUnit(currentEl.id)) {
            this.updateUnitStatus(currentEl, statusText, props);
            return total;
          }
          if (isTextOverlay && !statusText && this.hasAccess(HIGH_LEVEL_GROUPS)) {
            // put delist text for non unit box
            this.addStatusText(currentEl);
          }
          return total + 1;
        }, 0);
        if (count !== props.nonMHubUnitCount) {
          const { store } = this.context;
          store.dispatch(Actions.setSiteplanCount(count));
        }
      }
    }
  }
  updateUnitStatus = (unitElement, statusText, props = this.props) => {
    // get unit data from projectUnits list
    const unit = this.getUnitData(unitElement.id, props);
    // check unit status
    if (unit) {
      const { store } = this.context;
      const { auth } = this.props;
      store.dispatch(Actions.siteplanAddProjectUnitID(unit.id));

      const hasAccess = this.hasAccess(HIGH_LEVEL_GROUPS);
      const unitIsSelectable = this.checkUnitSelectable(unit.statusID);
      const hasSelectableClass = unitElement.classList.contains('selectable');
      const limitedAccessRole = hasLimitedAccess(auth);

      // edit class
      if (!hasAccess && !limitedAccessRole) {
        if (!hasSelectableClass && unitIsSelectable) {
          unitElement.classList.add('selectable');
        } else if (!unitIsSelectable && hasSelectableClass) {
          unitElement.classList.remove('selectable');
        }
      } else if (!hasSelectableClass) {
        unitElement.classList.add('selectable');
      }
      // add events
      if (unitIsSelectable || hasAccess || limitedAccessRole) {
        unitElement.onclick = this.handleUnitClick;
        unitElement.onmouseout = this.handleUnitMouseOut;
        if (!window.navigator.userAgent.match(/firefox/i)) {
          unitElement.onmouseover = this.handleUnitOver;
        }
      } else {
        unitElement.onclick = null;
        unitElement.onmouseover = null;
        unitElement.onmouseout = null;
      }

      // layout filter
      const {
        filterLayout, filterBlock, filterLevel, filterCategory,
      } = props;

      const hasLayoutFilter = filterLayout && unit.projectUnitLayoutID;
      const hasBlockFilter = filterBlock && unit.zoneOrBlock;
      const hasLevelFilter = filterLevel && unit.streetNumberOrLevel;

      if (hasLayoutFilter || hasBlockFilter || hasLevelFilter) {
        if (unitElement.classList.contains('filtered')) {
          if ((hasLayoutFilter && unit.projectUnitLayoutID !== filterLayout) ||
            (hasBlockFilter && unit.zoneOrBlock !== filterBlock) ||
            (hasLevelFilter && unit.streetNumberOrLevel !== filterLevel)) {
            unitElement.classList.remove('filtered');
          }
        } else if (
          (!hasLayoutFilter || (hasLayoutFilter && unit.projectUnitLayoutID === filterLayout)) &&
          (!hasBlockFilter || (hasBlockFilter && unit.zoneOrBlock === filterBlock)) &&
          (!hasLevelFilter || (hasLevelFilter && unit.streetNumberOrLevel === filterLevel))
        ) {
          unitElement.classList.add('filtered');
        }
      }
      // change unit status
      const newClass = this.getUnitClassName(unit.statusID);
      if (!unitElement.classList.contains(newClass)) {
        if (this.checkUnitWasSelectable(unitElement)) {
          unitElement.classList.remove(...UNIT_STATUSES.map(s => s.value));
        }
        unitElement.classList.add(newClass);
      }
      if ((!hasAccess && !limitedAccessRole) && newClass === 'delisted' && unitElement.classList.length > 0) {
        unitElement.classList.remove(...UNIT_STATUSES.map(s => s.value));
      }

      // check bumi and tenanted status for label guide
      const specification = unit.specifications
        ? unit.specifications.some(spec => spec.name.toLowerCase() === 'rent-to-own' && spec.value.toLowerCase() === 'yes')
        : '';
      const categoryClass = unitElement.classList;
      switch (filterCategory) {
        case null:
          categoryClass.remove('tenanted-filter', 'bumi-filter', 'rentToOwn-filter');
          break;
        case 'bumi':
          if (unit.isBumi === true) {
            categoryClass.add('bumi-filter');
          }
          if (categoryClass.contains('tenanted-filter') || categoryClass.contains('rentToOwn-filter')) {
            categoryClass.remove('tenanted-filter', 'rentToOwn-filter');
          }
          break;
        case 'tenanted':
          if (unit.isTenanted === true) {
            categoryClass.add('tenanted-filter');
          }
          if (categoryClass.contains('bumi-filter') || categoryClass.contains('rentToOwn-filter')) {
            categoryClass.remove('bumi-filter', 'rentToOwn-filter');
          }
          break;
        case 'rent_to_own':
          if (specification === true) {
            categoryClass.add('rentToOwn-filter');
          }
          if (categoryClass.contains('tenanted-filter') || categoryClass.contains('bumi-filter')) {
            categoryClass.remove('bumi-filter', 'tenanted-filter');
          }
          break;
        default:
          break;
      }

      if (isNodeTag(unitElement, 'g')) {
        // add title
        const childNodes = Array.from(unitElement.childNodes);
        if (childNodes.length > 0 && !childNodes.some(c => isNodeTag(c, 'title') && c.textContent === unit.reference)) {
          const title = document.createElementNS(SVGNS, 'title');
          title.textContent = unit.reference;
          unitElement.appendChild(title);
        }
        const { overlayMode } = this.state.siteplanSettings;
        const isTextOverlay = overlayMode === 'text';
        if (isTextOverlay) {
          // add status text
          if (!statusText) {
            if (newClass !== 'new' && ((hasAccess || limitedAccessRole) || (!hasAccess && newClass !== 'delisted'))) {
              this.addStatusText(unitElement, newClass);
            }
          } else if (newClass !== 'new') {
            // update status text
            const capNewClass = newClass.charAt(0).toUpperCase() + newClass.slice(1)
            statusText.textContent = props.t(capNewClass);
          } else {
            unitElement.removeChild(statusText);
          }
        }
      }
    }
  }
  addStatusText = (el, text = 'delisted') => {
    const { project, t } = this.props;
    const tagNames = ['path', 'polygon', 'rect', 'ellipse', 'circle'];
    const childNodes = Array.from(el.childNodes);
    const hasMainID = childNodes.some(child => child.id === 'main');
    const graphicEl = childNodes.reduce((result, item) => {
      if (tagNames.some(tag => isNodeTag(item, tag))) {
        if (hasMainID) {
          return item.id === 'main' ? item : result;
        }
        return item;
      }
      return result;
    }, null);
    const bbox = el.getBBox();
    const centerX = bbox.x + (bbox.width / 2);
    const centerY = bbox.y + (bbox.height / 2);
    const statusText = document.createElementNS(SVGNS, 'text');
    const capitalizeText = text.charAt(0).toUpperCase() + text.slice(1)
    statusText.textContent = t(capitalizeText);
    statusText.classList.add('status');
    if (project && project.propertyCategory && project.propertyCategory.category === 'high_rise') {
      statusText.classList.add('high-rise');
    }
    statusText.setAttribute('x', centerX);
    statusText.setAttribute('y', centerY);
    statusText.setAttribute('text-anchor', 'middle');
    statusText.setAttribute('dominant-baseline', 'middle');
    const angle = this.getAngleFromPolygon(el.id, graphicEl);
    statusText.setAttribute('transform', `rotate(${angle} ${centerX} ${centerY})`);
    el.appendChild(statusText);
  }

  // svg handlers
  resizeSVG = () => {
    const { isMarketplace } = this.props;
    const { isMatrixInitialied } = this.state;

    // MP-816 - no auto resize in marketplace
    if (isMatrixInitialied && isMarketplace) {
      return;
    }

    let { width, height } = this.state;
    const container = this.wrapperRef && this.wrapperRef.current ? this.wrapperRef.current : null;
    if (container) {
      const viewBox = container.getBoundingClientRect();
      ({ width } = viewBox); // width = viewBox.width
      ({ height } = viewBox); // height = viewBox.height
    }

    // skip if no width and height or DOM element not rendered in viewport
    if (width <= 0 || height <= 0) {
      return;
    }

    const oriMatrix = [1, 0, 0, 1, 0, 0];
    const svgMap = document.getElementById(SVG_SITEPLAN);
    if (svgMap) {
      if (!svgMap.getAttribute('transform')) {
        svgMap.setAttribute('transform', 'matrix(1 0 0 1 0 0)');
      }
    }
    const unitGroup = document.getElementById(SVG_UNITS);
    if (unitGroup) {
      const groupBox = unitGroup.getBBox();
      // center the map
      oriMatrix[4] = ((width - groupBox.width) / 2) - groupBox.x;
      oriMatrix[5] = ((height - groupBox.height) / 2) - groupBox.y;

      // scale
      const maxScale = 0.75;
      const minSize = _.min([
        (((width * maxScale) - groupBox.width) / groupBox.width),
        (((height * maxScale) - groupBox.height) / groupBox.height),
      ]);
      const scale = 1 + minSize;
      for (let i = 0; i < oriMatrix.length; i += 1) {
        oriMatrix[i] *= scale;
      }
      oriMatrix[4] += (1 - scale) * (width / 2);
      oriMatrix[5] += (1 - scale) * (height / 2);
    }
    const matrix = this.dragMoveLimit(oriMatrix, null, null, width, height);
    this.setState({
      zooming: false,
      dragging: false,
      width,
      height,
      oriMatrix,
      matrix,
      isMatrixInitialied: true,
    }, () => {
      const { selected } = this.state;
      if (selected) {
        const selectedEl = document.getElementById(this.state.selected);
        this.zoomToUnit(selectedEl);
      }
    });
  }
  updateSVG = (hasTransition = false) => {
    const { matrix } = this.state;
    const group = document.getElementById(SVG_SITEPLAN);
    if (group) {
      if (hasTransition) {
        if (!group.classList.contains('animated')) {
          group.classList.add('animated');
        }
      } else if (group.classList.contains('animated')) {
        group.classList.remove('animated');
      }
      group.setAttribute('transform', `matrix(${matrix.join(' ')})`);
    }
  }
  handleRecenterSVG = () => {
    const matrix = this.dragMoveLimit(this.state.oriMatrix);
    this.setState({ matrix, zooming: false });
  }
  handleClearSelected = () => {
    if (this.state.selected) {
      const selectedEl = document.getElementById(this.state.selected);
      if (selectedEl) {
        selectedEl.classList.remove('selected');
      }
      if (this.props.onSelect) {
        this.props.onSelect(null);
      }
      this.setState({ selected: null });
    }
    this.handleRecenterSVG();
  }
  handleOnSVGLoaded = () => {
    this.setupSVG();
  }
  handleOnSVGError = () => {
    this.setState({ isError: true });
  }
  handleAutoReload = () => {
    const { onAutoReload } = this.props;
    const autoReload = !this.state.autoReload;
    this.setState({ autoReload }, () => {
      if (onAutoReload) {
        onAutoReload(autoReload);
      }
    });
  }

  // drag handlers
  handleDragStart = (e) => {
    if (e.touches && e.touches.length >= 2) {
      // initiate for pinch zoom
      this.hasTouches = true;
      this.handleTouchStart(e);
      return;
    }
    if (!(e.changedTouches && e.changedTouches.length > 0) && e.button !== 0) {
      // only allow left click
      return;
    }
    // Find start position of drag based on touch/mouse coordinates.
    const startX = getAxis(e, 'x');
    const startY = getAxis(e, 'y');

    // Update state with above coordinates, and set dragging to true.
    this.setState({
      dragging: true,
      startX,
      startY,
    });
  }
  handleDragStop = () => {
    this.hasTouches = false;
    this.isZooming = false;
    this.isDragging = false;
    this.setState({ dragging: false, tpCache: [] });
  }
  handleDragMove = (e) => {
    if (e && e.type === 'touchmove' && e.touches && e.touches.length >= 2) {
      this.hasTouches = true;
      this.handlePinchZoom(e);
      return;
    }
    // First check if the state is dragging, if not we can just return
    // so we do not move unless the user wants to move
    if (!this.state.dragging) {
      return;
    }
    e.preventDefault();

    // Get the new x and y coordinates
    const x = getAxis(e, 'x');
    const y = getAxis(e, 'y');

    const { startX, startY } = this.state;

    // Take the delta where we are minus where we came from.
    const dx = (x - startX);
    const dy = (y - startY);

    if (Math.abs(dx) > 5 || Math.abs(dy) > 5) {
      // Prevent emulated mouse events
      e.stopImmediatePropagation();
    }

    // Pan using the deltas
    let { matrix } = this.state;
    const newX = matrix[4] + dx;
    const newY = matrix[5] + dy;
    matrix = this.dragMoveLimit(matrix, newX, newY);
    // Update the new startX and startY position
    // because a drag is likely a continuous movement
    this.isDragging = true;
    this.setState({
      startX: x,
      startY: y,
      matrix,
    });
  }
  handleTouchCancel = (e) => {
    e.preventDefault();
    this.hasTouches = false;
    this.setState({ dragging: false, tpCache: [] });
  }
  handleTouchStart = (e) => {
    e.preventDefault();
    const tpCache = [];
    if (e && e.touches && e.touches.length > 0) {
      for (let i = 0; i < e.touches.length; i += 1) {
        tpCache.push(e.touches[i]);
      }
    }
    this.setComponentState({ tpCache });
  }
  dragMoveLimit = (matrix, x, y, width = this.state.width, height = this.state.height) => {
    const { svgWidth, svgHeight } = this.state;
    const newMatrix = [].concat(matrix);
    const scale = newMatrix[0];
    newMatrix[4] = getMatrixPosLimit(scale, x, newMatrix[4], width, svgWidth);
    newMatrix[5] = getMatrixPosLimit(scale, y, newMatrix[5], height, svgHeight);
    return newMatrix;
  }

  handleFilterDefaultLevel = () => {
    const { projectUnits } = this.props;
    const { store } = this.context;

    if (projectUnits) {
      const levels = LevelFilter.getFilteredArray(projectUnits, 'streetNumberOrLevel');
      if (levels && levels.length > 1) {
        store.dispatch(Actions.setSiteplanFilterLevel(levels[1]), () => this.zoomToBlockOrLevel());
        this.setState({ hasInitialized: true });
      }
    }
  }

  zoomToBlockOrLevel = () => {
    const { filterBlock, filterLevel, projectUnits } = this.props;
    const filteredBlockUnits = [];
    const filteredLevelUnits = [];
    let filteredUnits = [];
    let totalX = 0;
    let totalY = 0;
    let totalWidth = 0;
    let totalHeight = 0;
    let totalTransform = 0;
    let totalUnitsWithTransform = 0;
    let averageTransform = 0;
    let translateX = 0;
    let translateY = 0;
    let degree = 0;

    if (projectUnits && projectUnits.length > 0) {
      projectUnits.forEach((unit) => {
        if (filterBlock && unit.zoneOrBlock !== '' && filterBlock === unit.zoneOrBlock) {
          filteredBlockUnits.push(unit.id);
          filteredUnits = filteredBlockUnits;
        }
        if (filterLevel && unit.streetNumberOrLevel !== '' && filterLevel === unit.streetNumberOrLevel) {
          filteredLevelUnits.push(unit.id);
          filteredUnits = filteredLevelUnits;
        }
        if (filteredBlockUnits.length > 0 && filteredLevelUnits.length > 0) {
          filteredUnits = _.intersection(filteredBlockUnits, filteredLevelUnits);
        } else if (!filteredBlockUnits && !filteredLevelUnits) {
          filteredUnits = [];
        }
      });

      filteredUnits.forEach((id) => {
        const el = document.getElementById(SVG_UNIT_PREFIX + id);
        if (el) {
          const elBox = el.getBBox();
          totalX += elBox.x;
          totalY += elBox.y;
          totalWidth += elBox.width;
          totalHeight += elBox.height;

          // get transform value
          const transform = el.getAttribute('transform');
          if (transform) {
            totalTransform += transform;
            totalUnitsWithTransform += 1;
          }
        }
      });

      if (totalTransform) {
        averageTransform = totalTransform / totalUnitsWithTransform;
        // get translate value
        const translateReg = /translate\((-?\d+(\.\d+)?) (-?\d+(\.\d+)?)\)/i;
        if (averageTransform.match(translateReg)) {
          translateX = parseFloat(averageTransform.match(translateReg)[1]);
          translateY = parseFloat(averageTransform.match(translateReg)[3]);
        }
        // get rotate value
        const rotateReg = /rotate\((-?\d+(\.\d+)?)\)/i;
        if (averageTransform.match(rotateReg)) {
          degree = parseFloat(averageTransform.match(rotateReg)[1]);
        }
      }

      if (filteredUnits && filteredUnits.length > 0) {
        const totalUnits = filteredUnits.length;
        const averageX = totalX / totalUnits;
        const averageY = totalY / totalUnits;
        const averageWidth = totalWidth / totalUnits;
        const averageHeight = totalHeight / totalUnits;

        const centerX = (averageX + (averageWidth / 2));
        const centerY = (averageY + (averageHeight / 2));

        const radius = Math.sqrt((centerX ** 2) + (centerY ** 2));
        const startAngle = Math.atan2(centerY, centerX);
        const newX = radius * Math.cos(startAngle + (degree * (Math.PI / 180)));
        const newY = radius * Math.sin(startAngle + (degree * (Math.PI / 180)));

        const { width, height } = this.state;
        let matrix = [1, 0, 0, 1, 0, 0];
        // view box starting position
        const newCenterX = newX + translateX;
        const newCenterY = newY + translateY;

        matrix[4] = (width / 2) - newCenterX;
        matrix[5] = (height / 2) - newCenterY;
        // zoom
        const widthScale = ((width - averageWidth) / averageWidth);
        const heightScale = ((height - averageHeight) / averageHeight);
        const minSize = _.min([widthScale, heightScale]);
        const maxSize = _.max([widthScale, heightScale]);
        let scale = 0.1 + ((averageTransform ? maxSize : minSize) * (0.15 - (averageTransform ? 0.1065 : 0)));
        if (filterBlock && !filterLevel) {
          scale = 0.1 + ((averageTransform ? maxSize : minSize) * (0.075 - (averageTransform ? 0.1065 : 0)));
        }
        if (!filterBlock && filterLevel) {
          scale = 0.1 + ((averageTransform ? maxSize : minSize) * (0.028 - (averageTransform ? 0.1065 : 0)));
        }
        matrix = zoomIn(matrix, width, height, scale);
        this.setState({
          matrix,
          dragging: false,
          zooming: false,
        });
      } else {
        this.handleRecenterSVG();
      }
    }
  }

  // zoom handlers
  zoomToUnit = (el) => {
    // move to top
    el.parentNode.insertBefore(el, null);

    // get width and height
    const elBox = el.getBBox();

    // Get the new x and y coordinates
    let translateX = 0;
    let translateY = 0;
    let degree = 0;

    // get transform value
    const transform = el.getAttribute('transform');
    if (transform) {
      // get translate value
      const translateReg = /translate\((-?\d+(\.\d+)?) (-?\d+(\.\d+)?)\)/i;
      if (transform.match(translateReg)) {
        translateX = parseFloat(transform.match(translateReg)[1]);
        translateY = parseFloat(transform.match(translateReg)[3]);
      }
      // get rotate value
      const rotateReg = /rotate\((-?\d+(\.\d+)?)\)/i;
      if (transform.match(rotateReg)) {
        degree = parseFloat(transform.match(rotateReg)[1]);
      }
    }

    // element center position
    const centerX = (elBox.x + (elBox.width / 2));
    const centerY = (elBox.y + (elBox.height / 2));

    const radius = Math.sqrt((centerX ** 2) + (centerY ** 2));
    const startAngle = Math.atan2(centerY, centerX);
    const newX = radius * Math.cos(startAngle + (degree * (Math.PI / 180)));
    const newY = radius * Math.sin(startAngle + (degree * (Math.PI / 180)));

    const { width, height } = this.state;
    let matrix = [1, 0, 0, 1, 0, 0];
    // view box starting position
    const newCenterX = newX + translateX;
    const newCenterY = newY + translateY;

    matrix[4] = (width / 2) - newCenterX;
    matrix[5] = (height / 2) - newCenterY;
    // zoom
    const widthScale = ((width - elBox.width) / elBox.width);
    const heightScale = ((height - elBox.height) / elBox.height);
    const minSize = _.min([widthScale, heightScale]);
    const maxSize = _.max([widthScale, heightScale]);
    const scale = 1 + ((transform ? maxSize : minSize) * (0.15 - (transform ? 0.1065 : 0)));
    matrix = zoomIn(matrix, width, height, scale);
    this.setState({
      matrix,
      dragging: false,
      zooming: false,
    });
  }
  zoomInMax = (e, scale) => {
    const { width, height } = this.state;
    const maxScale = 5.5;
    let { matrix } = this.state;
    if (matrix[0] < maxScale) {
      if (matrix[0] * scale >= maxScale) {
        matrix = zoomOut(matrix, width, height, matrix[0]);
        matrix = zoomIn(matrix, width, height, maxScale);
      } else {
        matrix = zoomIn(matrix, width, height, scale);
      }
      matrix = this.dragMoveLimit(matrix);
      this.setState({ matrix }, () => {
        this.setState({ zooming: false });
      });
    }
  }
  zoomOutMax = (e, scale) => {
    const {
      width, height, svgWidth, svgHeight,
    } = this.state;
    const minScale = _.min([
      width / (svgWidth * 1.4),
      height / (svgHeight * 1.4),
    ]);
    let { matrix } = this.state;
    if (matrix[0] > minScale) {
      if (matrix[0] / scale <= minScale) {
        matrix = zoomOut(matrix, width, height, matrix[0]);
        matrix = zoomIn(matrix, width, height, minScale);
      } else {
        matrix = zoomOut(matrix, width, height, scale);
      }
      matrix = this.dragMoveLimit(matrix);
      this.setState({ matrix }, () => {
        this.setState({ zooming: false });
      });
    }
  }
  handleMouseWheel = (e) => {
    this.setState({ zooming: true }, () => {
      if (e.deltaY < 0) {
        this.zoomInMax(e, ZOOM_VALUE);
      } else {
        this.zoomOutMax(e, ZOOM_VALUE);
      }
    });
  }
  handleZoomIn = (e) => {
    this.setState({ zooming: false }, () => {
      this.zoomInMax(e, BUTTON_ZOOM_VALUE);
    });
  }
  handleZoomOut = (e) => {
    this.setState({ zooming: false }, () => {
      this.zoomOutMax(e, BUTTON_ZOOM_VALUE);
    });
  }
  handlePinchZoom = (e) => {
    const { tpCache } = this.state;
    if (e.touches && e.touches.length >= 2) {
      // Check if the two target touches are the same ones that started the 2-touch
      let point1 = -1;
      let point2 = -1;
      for (let i = 0; i < tpCache.length; i += 1) {
        if (tpCache[i].identifier === e.touches[0].identifier) point1 = i;
        if (tpCache[i].identifier === e.touches[1].identifier) point2 = i;
      }

      if (point1 >= 0 && point2 >= 0) {
        // Get initial T1 coordinates
        const initX1 = tpCache[point1].clientX;
        const initY1 = tpCache[point1].clientY;
        // InitialTouch1Coordinates = (initX1, initY1)

        // Get initial T2 coordinates
        const initX2 = tpCache[point2].clientX;
        const initY2 = tpCache[point2].clientY;
        // InitialTouch2Coordinates = (initX2, initY2)

        // Get target T1 coordinates
        const targetX1 = e.touches[0].clientX;
        const targetY1 = e.touches[0].clientY;
        // TargetTouch1Coordinates = (targetX1, targetY1)

        // Get target T2 coordinates
        const targetX2 = e.touches[1].clientX;
        const targetY2 = e.touches[1].clientY;
        // TargetTouch2Coordinates = (targetX2, targetY2)

        // Distance: sqrt of [(x2 - x1)^2 + (y2 - y1)^2]
        // Calculate distance of initial T1 coordinates with initial T2 coordinates
        // Initial coordinates: (initX1, initY1), (initX2, initY2)
        const initX = (initX2 - initX1) ** 2;
        const initY = (initY2 - initY1) ** 2;
        const initialCoordinates = Math.sqrt(initX + initY);

        // Target coordinates: (targetX1, targetY1), (targetX2, targetY2)
        const targetX = (targetX2 - targetX1) ** 2;
        const targetY = (targetY2 - targetY1) ** 2;
        const targetCoordinates = Math.sqrt(targetX + targetY);

        // Difference between target and initial
        // const diff = targetCoordinates - initialCoordinates;

        this.isZooming = true;
        this.setState({ zooming: true }, () => {
          if (targetCoordinates < initialCoordinates) {
            this.zoomOutMax(e, 1.1);
          } else if (targetCoordinates > initialCoordinates) {
            this.zoomInMax(e, 1.1);
          }
        });
      }
    }
  }

  // unit handlers
  handleUnitOver = (e) => {
    const { id } = e.currentTarget;
    const hovered = id;
    if (this.state.hovered !== hovered) {
      this.setState({ hovered }, () => {
        const realEl = document.getElementById(id);
        const parent = realEl.parentNode;
        if (parent) {
          if (realEl !== parent.lastChild) {
            // FIXME: firefox wont show hover css when element move to top
            // put current element at the top of its parent AKA last child
            realEl.parentNode.insertBefore(realEl, null);
          }
        }
      });
      if (this.props.unitOnHover) {
        const unitData = this.getUnitData(id);
        this.props.unitOnHover(unitData);
      }
    }
  }
  handleUnitMouseOut = () => {
    if (this.state.hovered) {
      this.setState({ hovered: null }, () => {
        if (this.state.selected) {
          const selectedEl = document.getElementById(this.state.selected);
          if (selectedEl) {
            const parent = selectedEl.parentNode;
            if (parent) {
              if (selectedEl !== parent.lastChild) {
                // put selected at the top of its parent AKA last child
                parent.insertBefore(selectedEl, null);
              }
            }
          }
        }
      });
      if (this.props.unitOnHover) {
        this.props.unitOnHover(null);
      }
    }
  }
  handleUnitClick = (e) => {
    if (this.isDragging || this.isZooming || this.hasTouches) {
      return;
    }
    e.preventDefault();
    const { id } = e.currentTarget;
    let selected = null;
    // onSelect props
    if (this.props.onSelect) {
      if (this.state.selected !== id) {
        if (this.state.selected) {
          const selectedEl = document.getElementById(this.state.selected);
          if (selectedEl) {
            selectedEl.classList.remove('selected');
          }
        }
        selected = id;
      }

      this.setState({ selected }, () => {
        const realEl = document.getElementById(id);
        if (selected && !realEl.classList.contains('selected')) {
          realEl.classList.add('selected');
          this.zoomToUnit(realEl);
        } else if (realEl.classList.contains('selected')) {
          realEl.classList.remove('selected');
          this.handleRecenterSVG();
        }
      });

      let unitData;
      if (selected && selected === id) {
        unitData = this.getUnitData(id);
      }
      this.props.onSelect(unitData);
    }
    // unitOnClick
    if (this.props.unitOnClick) {
      const realEl = document.getElementById(id);
      this.zoomToUnit(realEl);

      const unitData = this.getUnitData(id);
      this.props.unitOnClick(unitData);
    }
  }

  reloadPage = () => {
    window.location.reload();
  }

  renderButtons = () => {
    const {
      isLoaded, imgLoaded, imgError, isError, autoReload,
    } = this.state;
    const { hideButton } = this.props;
    if (isLoaded && imgLoaded && !isError && !imgError && !hideButton) {
      return (
        <div className="buttons">
          <button type="button" onClick={this.handleZoomIn}>
            <InlineSVG src={icZoomIn} />
          </button>
          <button type="button" onClick={this.handleZoomOut}>
            <InlineSVG src={icZoomOut} />
          </button>
          <button type="button" onClick={this.handleClearSelected}>
            <InlineSVG src={icMax} />
          </button>
          <button className={autoReload ? 'active' : null} type="button" onClick={this.handleAutoReload}>
            <InlineSVG src={icRefresh} />
          </button>
        </div>
      );
    }
    return null;
  }
  renderTime = () => {
    const {
      isLoaded, imgLoaded, imgError, isError,
    } = this.state;
    if (isLoaded && imgLoaded && !imgError && !isError) {
      return <div className="time">{moment().format('ddd, L, LT')}</div>;
    }
    return null;
  }
  render() {
    const { children } = this.props;
    const {
      svgData, isLoaded, isError, imgError, imgLoaded,
    } = this.state;
    const svgContent = svgData || children;
    const classNames = this.classNames();

    return (
      <div ref={this.wrapperRef} className={classNames}>
        {
          svgContent && (
            <InlineSVG
              src={svgContent}
              onLoad={this.handleOnSVGLoaded}
              onError={this.handleOnSVGError}
              uniquifyIDs={false}
            />
          )
        }
        {
          (!isLoaded && !imgLoaded) ? (
            <div className="loading">
              {
                (isError || imgError) ? (
                  <Fragment>
                    <p style={{ marginBottom: '1rem' }}>Currently unable to load.</p>
                    <Button onClick={this.reloadPage}>Refresh</Button>
                  </Fragment>
                ) : (
                  <p>Loading...</p>
                )
              }
            </div>
          ) : null
        }

        {this.renderButtons()}
        {this.renderTime()}
      </div>
    );
  }
}

export default withTranslation(TRANSLATION_FILES.constants.unitStatus)(connect(SVGViewer.mapStateToProps)(SVGViewer));

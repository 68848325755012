import config from './config';

const AUTHENTICATE = '/authenticated/';
// project
const PROJECTS = '/siteplan/projects';
const PROJECT = projectID => `${PROJECTS}/${projectID}`;
// layouts
const PROJECT_UNIT_LAYOUTS = projectID => `${PROJECT(projectID)}/unit_layouts`;
const PROJECT_UNIT_LAYOUT = (projectID, layoutID) => `${PROJECT_UNIT_LAYOUTS(projectID)}/${layoutID}`;
// pictures
const PICTURES = '/pictures';
const PROJECT_UNIT_LAYOUT_PICTURES = (projectID, layoutID) => `${PROJECT_UNIT_LAYOUT(projectID, layoutID)}${PICTURES}`;
// units
const PROJECT_UNITS = projectID => `${PROJECT(projectID)}/units`;
const PROJECT_UNIT = (projectID, unitID) => `${PROJECT_UNITS(projectID)}/${unitID}`;
const PROJECT_UNITS_SUMMARY = projectID => `${PROJECT_UNITS(projectID)}/summary`;
// currency setting
const CURRENCY_SETTING = companyID => `/company/${companyID}/currency_setting`;
// s3
const S3_FILE = (path, bucket = config.s3.siteplan.BUCKET, region = config.s3.siteplan.REGION) => `https://s3-${region}.amazonaws.com/${bucket}/${path}`;

export default {
  AUTHENTICATE,
  CURRENCY_SETTING,
  PROJECTS,
  PROJECT,
  PROJECT_UNIT_LAYOUTS,
  PROJECT_UNIT_LAYOUT,
  PICTURES,
  PROJECT_UNIT_LAYOUT_PICTURES,
  PROJECT_UNIT,
  PROJECT_UNITS,
  PROJECT_UNITS_SUMMARY,
  S3_FILE,
};

/* eslint react/forbid-prop-types: 0 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import Selectors from '../redux/selectors';
import Actions from '../redux/actions';
import Routes from '../Routes';
import AppHeader from '../components/AppHeader';
import RotateDeviceWarning from './RotateDeviceWarning';
import { decodeSearch, isIframe, isBrowser } from '../utils/url';

import './App.css';
import '../components/Button.css';

class App extends Component {
  static mapStateToProps = state => ({
    isAuthenticated: Selectors.getAuthenticateIsAuthenticated(state),
  })
  static propTypes = {
    history: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool,
  }
  static contextTypes = {
    store: PropTypes.object,
  }
  static defaultProps = {
    isAuthenticated: false,
  }
  componentDidMount() {
    this.checkMarketplaceURL();
  }
  hasMhubDomain = target => target.includes('mhub.my') || target.includes('mhub.local');
  checkMarketplaceURL = () => {
    const query = decodeSearch(window.location.search);
    const { url, isMarketplace } = query;
    if (isMarketplace && url && isIframe) {
      let isAncestorOrigin = false;
      if (document.location.ancestorOrigins && document.location.ancestorOrigins.contains(url)) {
        const origin = document.location.ancestorOrigins.item(0);
        isAncestorOrigin = this.hasMhubDomain(origin);
      }
      const isReferrer = document.referrer.includes(url) && this.hasMhubDomain(document.referrer);
      if (isReferrer || isAncestorOrigin) {
        const { store } = this.context;
        store.dispatch(Actions.appSetMarketplaceURL(url));
      }
    }
  }
  classNames = () => {
    const names = ['App'];
    if (isIframe() || window.location.pathname.includes('/siteplan-widget/')) {
      names.push('hideHeader');
    }
    return names.join(' ');
  }
  render() {
    const { isAuthenticated } = this.props;
    const childProps = {
      className: 'App-contents',
      isAuthenticated,
    };
    const classNames = this.classNames();
    return (
      <div className={classNames}>
        <ConnectedRouter history={this.props.history}>
          <Fragment>
            <AppHeader />
            <Routes childProps={childProps} />
            {(!isIframe() && !isBrowser()) && <RotateDeviceWarning />}
          </Fragment>
        </ConnectedRouter>
      </div>
    );
  }
}

export default connect(App.mapStateToProps)(App);

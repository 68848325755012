import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import logoSpin from '../assets/logos/logo-spin.svg';
import { TRANSLATION_FILES } from '../constants/translation';

import './LoadingPage.css';

const LoadingPage = ({ isLoading, error, t }) => {
  if (isLoading) {
    return (
      <div className="LoadingPage">
        <img src={logoSpin} className="loader" alt="Loading" />
      </div>
    );
  }
  if (error) {
    return <div className="LoadingPage">{t('Sorry, there was a problem loading the page')}.</div>;
  }
  return null;
};

LoadingPage.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};

LoadingPage.defaultProps = {
  isLoading: false,
  error: null,
};

export default withTranslation(TRANSLATION_FILES.screens)(LoadingPage);

import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import './LoanAmount.css';
import { getCurrencySettingFormat } from '../utils/currencies';

const getDefaultFormat = (format, amount) => {
  let loan;
  if (format) {
    loan = numeral(amount).format(format);
  } else {
    loan = ((amount * 1) >= 1000000 && (amount * 1) < 100000000 ?
      numeral(amount).format('0.0a') :
      numeral(amount).format('0a'));
  }
  return loan;
};

const LoanAmount = ({
  amount,
  format,
  prefix,
  suffix,
  isCurrencySettingFormat,
  currencySetting,
}) => {
  const loan = isCurrencySettingFormat ? getCurrencySettingFormat(amount, currencySetting) : getDefaultFormat(format, amount);
  const tooltipAmount = currencySetting && currencySetting.isAbbreviationEnabled ? getDefaultFormat(format, amount) : null;

  return (
    <span className="LoanAmount" title={tooltipAmount}>
      {prefix ? <span className="prefix">{prefix}</span> : null}
      {loan}
      {suffix ? <span className="suffix">{suffix}</span> : null}
    </span>
  );
};

LoanAmount.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  format: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  isCurrencySettingFormat: PropTypes.bool,
  currencySetting: PropTypes.shape({
    isAbbreviationEnabled: PropTypes.bool,
    displayDecimal: PropTypes.bool,
    abbreviationDecimalPoints: PropTypes.number,
    decimalSeparator: PropTypes.string,
    thousandsSeparator: PropTypes.string,
    millionsSeparator: PropTypes.string,
    billionsSeparator: PropTypes.string,
  }),
};
LoanAmount.defaultProps = {
  amount: 0,
  format: null,
  prefix: null,
  suffix: null,
  isCurrencySettingFormat: false,
  currencySetting: {
    id: '',
    isAbbreviationEnabled: false,
    displayDecimal: true,
    abbreviationDecimalPoints: 2,
    decimalSeparator: '.',
    thousandsSeparator: ',',
    millionsSeparator: ',',
    billionsSeparator: ',',
  },
};

export default LoanAmount;

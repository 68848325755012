import qs from 'qs';
// import config from '../../config';
import Endpoints from '../../endpoints';
import fetchUrl from '../../utils/fetchUrl';
import { handleError, resJson } from '../../utils/error';
import { getToken } from '../helpers';

import Types from './types';

const projectUnitLayoutRequest = () => ({
  type: Types.PROJECT_UNIT_LAYOUT_REQUEST,
});

const projectUnitLayoutSuccess = ({ data, token }) => ({
  type: Types.PROJECT_UNIT_LAYOUT_SUCCESS,
  payload: {
    data,
    token,
  },
});

const projectUnitLayoutFailure = message => ({
  type: Types.PROJECT_UNIT_LAYOUT_FAILURE,
  payload: {
    statusText: message,
  },
});

const projectUnitLayoutReset = () => ({
  type: Types.PROJECT_UNIT_LAYOUT_RESET,
});

const setProjectUnitLayoutLoading = (loading = false) => ({
  type: Types.PROJECT_UNIT_LAYOUT_SET_LOADING,
  payload: {
    loading,
  },
});

const fetchProjectUnitLayout = (projectID, layoutID, query = {}) => {
  const q = Object.assign({}, query);
  const url = `${Endpoints.PROJECT_UNIT_LAYOUT(projectID, layoutID)}/?${qs.stringify(q)}`;
  const options = {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  let token;
  return (dispatch) => {
    dispatch(projectUnitLayoutRequest());

    return dispatch(fetchUrl(url, options))
      .then((res) => {
        token = getToken(res);
        return resJson(res);
      })
      .then((data) => {
        dispatch(projectUnitLayoutSuccess({ data, token }));
      })
      .catch(err => dispatch(handleError(err, projectUnitLayoutFailure)));
  };
};

export default {
  setProjectUnitLayoutLoading,

  projectUnitLayoutRequest,
  projectUnitLayoutSuccess,
  projectUnitLayoutFailure,

  projectUnitLayoutReset,

  fetchProjectUnitLayout,
};

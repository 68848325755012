import React from 'react';
import PropTypes from 'prop-types';

import './Button.css';
import logoSpin from '../assets/logos/logo-spin-white.svg';

const classNames = ({
  loading, disabled, small, full, text,
}) => {
  const names = ['Button'];
  if (loading) {
    names.push('loading');
  }
  if (disabled) {
    names.push('disabled');
  }
  if (small) {
    names.push('small');
  }
  if (full) {
    names.push('full');
  }
  if (text) {
    names.push('text');
  }
  return names.join(' ');
};

const Button = ({
  children,
  disabled,
  loading,
  type,
  onClick,
  small,
  full,
  text,
}) => (
  <button
    className={classNames({
      loading, disabled, small, full, text,
    })}
    disabled={disabled || loading}
    type={type}
    onClick={onClick}
  >
    <img className="Button-loader" src={logoSpin} alt="Loading" />
    {
      !loading && children
    }
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  full: PropTypes.bool,
  text: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  type: null,
  onClick: null,
  small: false,
  full: false,
  text: false,
};

export default Button;

/* eslint react/forbid-prop-types: 0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import Actions from './redux/actions';
import WidgetApp from './containers/WidgetApp';

export default class WebpackWidgetRoot extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    projectID: PropTypes.string,
    companyCode: PropTypes.string,
    marketplaceURL: PropTypes.string,
  }
  static defaultProps = {
    projectID: '',
    companyCode: '',
    marketplaceURL: '',
  }
  async componentDidMount() {
    const { store, companyCode, marketplaceURL } = this.props;
    if (marketplaceURL !== '') {
      await store.dispatch(Actions.appSetMarketplaceURL(marketplaceURL));
    }
    await store.dispatch(Actions.checkSharedWidget(companyCode))
      .catch(() => {});
  }
  render() {
    const { store, history, ...props } = this.props;
    return (
      <Provider store={store}>
        <WidgetApp history={history} {...props} />
      </Provider>
    );
  }
}

import qs from 'qs';
// import config from '../../config';
import Endpoints from '../../endpoints';
import fetchUrl from '../../utils/fetchUrl';
import { handleError, resJson } from '../../utils/error';
import { getToken } from '../helpers';

import Types from './types';

const projectRequest = () => ({
  type: Types.PROJECT_REQUEST,
});

const projectSuccess = ({ data, token }) => ({
  type: Types.PROJECT_SUCCESS,
  payload: {
    data,
    token,
  },
});

const projectFailure = message => ({
  type: Types.PROJECT_FAILURE,
  payload: {
    statusText: message,
  },
});

const projectReset = () => ({
  type: Types.PROJECT_RESET,
});

const setProjectLoading = (loading = false) => ({
  type: Types.PROJECT_SET_LOADING,
  payload: {
    loading,
  },
});

const fetchProject = (id, query = {}) => {
  const q = Object.assign({}, query);
  const url = `${Endpoints.PROJECT(id)}/?${qs.stringify(q)}`;
  const options = {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  let token;
  return (dispatch) => {
    dispatch(projectRequest());

    return dispatch(fetchUrl(url, options))
      .then((res) => {
        token = getToken(res);
        return resJson(res);
      })
      .then((data) => {
        dispatch(projectSuccess({ data, token }));
        return data;
      })
      .catch(err => dispatch(handleError(err, projectFailure)));
  };
};

export default {
  setProjectLoading,

  projectRequest,
  projectSuccess,
  projectFailure,

  projectReset,

  fetchProject,
};

export default Object.freeze({
  env: process.env.NODE_ENV,
  app: {
    server: process.env.REACT_APP_RELEASE_STAGE,
    name: `mhub-book-saleschart-web-${process.env.REACT_APP_RELEASE_STAGE}`,
    version: process.env.REACT_APP_VERSION,
    shareMode: process.env.REACT_APP_SHARE_MODE === 'true',
    shareToken: process.env.REACT_APP_SHARE_TOKEN,
  },
  cognito: {
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    REGION: process.env.REACT_APP_COGNITO_REGION,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
  cookie: {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  },
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
  },
  gateways: {
    API_URL: process.env.REACT_APP_API_URL,
    BOOK_API_URL: process.env.REACT_APP_BOOK_API_URL,
    START_URL: process.env.REACT_APP_START_URL,
    LOAN_TRACKER_URL: process.env.REACT_APP_LOAN_TRACKER_URL,
    SHOWROOM_URL: process.env.REACT_APP_SHOWROOM_URL,
    MARKETPLACE_URL: process.env.REACT_APP_MARKETPLACE_URL,
  },
  s3: {
    web: {
      BUCKET: process.env.REACT_APP_WEB_S3_BUCKET,
      REGION: process.env.REACT_APP_WEB_S3_REGION,
    },
    bookWeb: {
      BUCKET: process.env.REACT_APP_BOOK_WEB_S3_BUCKET,
      REGION: process.env.REACT_APP_BOOK_WEB_S3_REGION,
    },
    siteplan: {
      BUCKET: process.env.REACT_APP_SITEPLAN_S3_BUCKET,
      REGION: process.env.REACT_APP_SITEPLAN_S3_REGION,
    },
  },
  query: {
    limit: process.env.REACT_APP_FETCH_LIMIT,
  },
  bugsnag: {
    RELEASE_STAGE: process.env.REACT_APP_RELEASE_STAGE,
  },
});

import app from './app/actions';
import authenticate from './authenticate/actions';
import currencySetting from './currencySetting/actions';
import network from './network/actions';
import project from './project/actions';
import projects from './projects/actions';
import projectUnitLayout from './projectUnitLayout/actions';
import projectUnitLayouts from './projectUnitLayouts/actions';
import projectUnitLayoutPictures from './projectUnitLayoutPictures/actions';
import projectUnit from './projectUnit/actions';
import projectUnits from './projectUnits/actions';
import projectUnitsSummary from './projectUnitsSummary/actions';

import siteplan from './siteplan/actions';

export default {
  ...app,
  ...authenticate,
  ...currencySetting,
  ...network,
  ...project,
  ...projects,
  ...projectUnitLayout,
  ...projectUnitLayouts,
  ...projectUnitLayoutPictures,
  ...projectUnit,
  ...projectUnits,
  ...projectUnitsSummary,

  ...siteplan,
};

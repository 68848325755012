import { TRANSLATION_FILES } from '../constants/translation';

export function translateModels(t, key, modelName, defaultValue) {
  const option = {
    ns: TRANSLATION_FILES.models[modelName],
  };
  if (defaultValue) {
    option.defaultValue = defaultValue;
  }
  return t(key, option);
}

import config from './config';

// Auth config
const Auth = {
  region: config.cognito.REGION,
  userPoolId: config.cognito.USER_POOL_ID,
  identityPoolId: config.cognito.IDENTITY_POOL_ID,
  userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  cookieStorage: {
    domain: config.cookie.domain,
    secure: config.env !== 'development',
  },
  authenticationFlowType: 'USER_SRP_AUTH',
};
if (config.oauth.domain) {
  Auth.oauth = {
    domain: config.oauth.domain,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: config.gateways.START_URL,
    redirectSignOut: config.gateways.START_URL,
    // 'code' for Authorization code grant
    // 'token' for Implicit grant
    responseType: 'token',
    // optional, for Cognito hosted ui specified options
    options: {
      // Indicates if the data collection is enabled to support Cognito
      // advanced security features. By default, this flag is set to true.
      AdvancedSecurityDataCollectionFlag: false,
    },
  };
}

const amplifyConfig = {
  Auth,
};

export default amplifyConfig;

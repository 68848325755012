const getCount = state => state.count;

const getFilterStatus = state => state.filterStatus;

const getFilterLayout = state => state.filterLayout;

const getFilterBlock = state => state.filterBlock;

const getFilterLevel = state => state.filterLevel;

const getShowDetails = state => state.showDetails;

const getProjectUnitIDs = state => state.projectUnitIDs;

const getFilterCategory = state => state.filterCategory;

export default {
  getCount,
  getFilterStatus,
  getFilterLayout,
  getFilterBlock,
  getFilterLevel,
  getShowDetails,
  getProjectUnitIDs,
  getFilterCategory,
};

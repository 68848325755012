import { configureStore } from '@mhub/web-redux-utilities';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import './reset.css';
import './index.css';

import rootReducer from './redux/reducers';

export const history = createBrowserHistory();

export const store = configureStore(
  connectRouter(history)(rootReducer),
  {},
  thunk,
  routerMiddleware(history),
);

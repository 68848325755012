import { store } from '../store';

import app from './app/selectors';
import authenticate from './authenticate/selectors';
import currencySetting from './currencySetting/selectors';
import network from './network/selectors';
import project from './project/selectors';
import projects from './projects/selectors';
import projectUnitLayout from './projectUnitLayout/selectors';
import projectUnitLayouts from './projectUnitLayouts/selectors';
import projectUnitLayoutPictures from './projectUnitLayoutPictures/selectors';
import projectUnit from './projectUnit/selectors';
import projectUnits from './projectUnits/selectors';
import projectUnitsSummary from './projectUnitsSummary/selectors';

import siteplan from './siteplan/selectors';

// app
const getAppBookApiURL = state => app.getBookApiURL(state.app);
const getAppShowroomURL = state => app.getShowroomURL(state.app);
const getAppMarketplaceURL = state => app.getMarketplaceURL(state.app);
const getAppCompanyCode = state => app.getCompanyCode(state.app);
const getAppS3Bucket = state => app.getS3Bucket(state.app);
const getAppS3Region = state => app.getS3Region(state.app);

// authenticate
const getAuthenticateData = state => authenticate.getData(state.authenticate);
const getAuthenticateIsAuthenticated = state => authenticate.getIsAuthenticated(state.authenticate);
const getAuthenticateIsError = state => authenticate.getIsError(state.authenticate);
const getAuthenticateLoading = state => authenticate.getLoading(state.authenticate);
const getAuthenticateIsAdfs = state => authenticate.getIsAdfs(state.authenticate);
const getAuthenticateAdfsProvider = state => authenticate.getAdfsProvider(state.authenticate);

// currencySetting
const getCurrencySettingData = state => currencySetting.getData(state.currencySetting);
const getCurrencySettingDataIsError = state => currencySetting.getIsError(state.currencySetting);
const getCurrencySettingDataLoading = state => currencySetting.getLoading(state.currencySetting);
const getCurrencySettingDataToken = state => currencySetting.getToken(state.currencySetting);

// network
const getNetworkIsOk = state => network.getIsOk(state.network);

// project
const getProjectData = state => project.getData(state.project);
const getProjectIsError = state => project.getIsError(state.project);
const getProjectLoading = state => project.getLoading(state.project);
const getProjectToken = state => project.getToken(state.project);

// projects
const getProjectsData = state => projects.getData(state.projects);
const getProjectsIsError = state => projects.getIsError(state.projects);
const getProjectsLoading = state => projects.getLoading(state.projects);
const getProjectsPagination = state => projects.getPagination(state.projects);
const getProjectsToken = state => projects.getToken(state.projects);
const getProjectsCompanyID = state => projects.getCompanyID(state.projects);

// projectUnitLayout
const getProjectUnitLayoutData = state => projectUnitLayout.getData(state.projectUnitLayout);
const getProjectUnitLayoutIsError = state => projectUnitLayout.getIsError(state.projectUnitLayout);
const getProjectUnitLayoutLoading = state => projectUnitLayout.getLoading(state.projectUnitLayout);
const getProjectUnitLayoutToken = state => projectUnitLayout.getToken(state.projectUnitLayout);

// projectUnitLayouts
const getProjectUnitLayoutsData = state =>
  projectUnitLayouts.getData(state.projectUnitLayouts);
const getProjectUnitLayoutsIsError = state =>
  projectUnitLayouts.getIsError(state.projectUnitLayouts);
const getProjectUnitLayoutsLoading = state =>
  projectUnitLayouts.getLoading(state.projectUnitLayouts);
const getProjectUnitLayoutsPagination = state =>
  projectUnitLayouts.getPagination(state.projectUnitLayouts);
const getProjectUnitLayoutsToken = state =>
  projectUnitLayouts.getToken(state.projectUnitLayouts);

// projectUnitLayoutPictures
const getProjectUnitLayoutPicturesData = state =>
  projectUnitLayoutPictures.getData(state.projectUnitLayoutPictures);
const getProjectUnitLayoutPicturesIsError = state =>
  projectUnitLayoutPictures.getIsError(state.projectUnitLayoutPictures);
const getProjectUnitLayoutPicturesLoading = state =>
  projectUnitLayoutPictures.getLoading(state.projectUnitLayoutPictures);
const getProjectUnitLayoutPicturesPagination = state =>
  projectUnitLayoutPictures.getPagination(state.projectUnitLayoutPictures);
const getProjectUnitLayoutPicturesToken = state =>
  projectUnitLayoutPictures.getToken(state.projectUnitLayoutPictures);

// projectUnit
const getProjectUnitData = state => projectUnit.getData(state.projectUnit);
const getProjectUnitIsError = state => projectUnit.getIsError(state.projectUnit);
const getProjectUnitLoading = state => projectUnit.getLoading(state.projectUnit);
const getProjectUnitToken = state => projectUnit.getToken(state.projectUnit);

// projectUnits
const getProjectUnitsData = state => projectUnits.getData(state.projectUnits);
const getProjectUnitsIsError = state => projectUnits.getIsError(state.projectUnits);
const getProjectUnitsLoading = state => projectUnits.getLoading(state.projectUnits);
const getProjectUnitsPagination = state => projectUnits.getPagination(state.projectUnits);
const getProjectUnitsToken = state => projectUnits.getToken(state.projectUnits);
const getProjectUnitsRefreshAt = state => projectUnits.getRefreshAt(state.projectUnits);

// projectUnitsSummary
const getProjectUnitsSummaryData = state => projectUnitsSummary.getData(state.projectUnitsSummary);
const getProjectUnitsSummaryIsError = state =>
  projectUnitsSummary.getIsError(state.projectUnitsSummary);
const getProjectUnitsSummaryLoading = state =>
  projectUnitsSummary.getLoading(state.projectUnitsSummary);
const getProjectUnitsSummaryToken = state =>
  projectUnitsSummary.getToken(state.projectUnitsSummary);

// siteplan
const getSiteplanCount = state => siteplan.getCount(state.siteplan);
const getSiteplanFilterStatus = state => siteplan.getFilterStatus(state.siteplan);
const getSiteplanFilterLayout = state => siteplan.getFilterLayout(state.siteplan);
const getSiteplanFilterBlock = state => siteplan.getFilterBlock(state.siteplan);
const getSiteplanFilterLevel = state => siteplan.getFilterLevel(state.siteplan);
const getSiteplanShowDetails = state => siteplan.getShowDetails(state.siteplan);
const getSiteplanProjectUnitIDs = state => siteplan.getProjectUnitIDs(state.siteplan);
const getSiteplanFilterCategory = state => siteplan.getFilterCategory(state.siteplan);

const getStateAdfsProvider = () => getAuthenticateAdfsProvider(store.getState());
store.subscribe(getStateAdfsProvider);

const getStateBookApiURL = () => getAppBookApiURL(store.getState());
store.subscribe(getStateBookApiURL);

const getStateCompanyCode = () => getAppCompanyCode(store.getState());
store.subscribe(getStateCompanyCode);

export default {
  // app
  getAppBookApiURL,
  getAppShowroomURL,
  getAppMarketplaceURL,
  getAppCompanyCode,
  getAppS3Bucket,
  getAppS3Region,

  // authenticate
  getAuthenticateData,
  getAuthenticateIsAuthenticated,
  getAuthenticateIsError,
  getAuthenticateLoading,
  getAuthenticateIsAdfs,
  getAuthenticateAdfsProvider,

  // currencySetting
  getCurrencySettingData,
  getCurrencySettingDataIsError,
  getCurrencySettingDataLoading,
  getCurrencySettingDataToken,

  // network
  getNetworkIsOk,

  // project
  getProjectData,
  getProjectIsError,
  getProjectLoading,
  getProjectToken,

  // projects
  getProjectsData,
  getProjectsIsError,
  getProjectsLoading,
  getProjectsPagination,
  getProjectsToken,
  getProjectsCompanyID,

  // projectUnitLayout
  getProjectUnitLayoutData,
  getProjectUnitLayoutIsError,
  getProjectUnitLayoutLoading,
  getProjectUnitLayoutToken,

  // projectUnitLayouts
  getProjectUnitLayoutsData,
  getProjectUnitLayoutsIsError,
  getProjectUnitLayoutsLoading,
  getProjectUnitLayoutsPagination,
  getProjectUnitLayoutsToken,

  // projectUnitLayoutPictures
  getProjectUnitLayoutPicturesData,
  getProjectUnitLayoutPicturesIsError,
  getProjectUnitLayoutPicturesLoading,
  getProjectUnitLayoutPicturesPagination,
  getProjectUnitLayoutPicturesToken,

  // projectUnit
  getProjectUnitData,
  getProjectUnitIsError,
  getProjectUnitLoading,
  getProjectUnitToken,

  // projectUnits
  getProjectUnitsData,
  getProjectUnitsIsError,
  getProjectUnitsLoading,
  getProjectUnitsPagination,
  getProjectUnitsToken,
  getProjectUnitsRefreshAt,

  // projectUnitsSummary
  getProjectUnitsSummaryData,
  getProjectUnitsSummaryIsError,
  getProjectUnitsSummaryLoading,
  getProjectUnitsSummaryToken,

  // siteplan
  getSiteplanCount,
  getSiteplanFilterStatus,
  getSiteplanFilterLayout,
  getSiteplanFilterBlock,
  getSiteplanFilterLevel,
  getSiteplanShowDetails,
  getSiteplanProjectUnitIDs,
  getSiteplanFilterCategory,

  // state
  getStateAdfsProvider,
  getStateBookApiURL,
};

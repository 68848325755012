/* eslint react/forbid-prop-types: 0 */

import React, { PureComponent, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import App from './containers/App';
// import { getQuery } from './utils/url';
import Actions from './redux/actions';
import config from './config';
import { getSharePath } from './utils/url';

import './Root.css';
import './i18n';

export default class Root extends PureComponent {
  static propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }
  async componentDidMount() {
    const { store } = this.props;
    // const next = getQuery().next || null;
    if (config.app.server === 'staging' || config.app.server === 'development') {
      document.title = `${document.title} Demo`;
    }
    if (config.app.shareMode) {
      if (getSharePath().includes('not_found')) {
        await store.dispatch(Actions.setAuthenticateIsAuthenticated(false));
        await store.dispatch(Actions.setAuthenticateLoading(false));
      } else {
        await store.dispatch(Actions.checkShare())
          .catch(() => {});
      }
    } else {
      await store.dispatch(Actions.checkAuthenticated())
        .catch(() => {});
    }
  }
  render() {
    const { store, history } = this.props;
    return (
      <Provider className="Root" store={store}>
        <Suspense fallback={<></>}>
          <App history={history} />
        </Suspense>
      </Provider>
    );
  }
}

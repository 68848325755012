export default {
  AUTHENTICATE_SET_LOADING: 'AUTHENTICATE_SET_LOADING',
  AUTHENTICATE_SET_IS_AUTHENTICATED: 'AUTHENTICATE_SET_IS_AUTHENTICATED',

  AUTHENTICATE_REQUEST: 'AUTHENTICATE_REQUEST',
  AUTHENTICATE_FAILURE: 'AUTHENTICATE_FAILURE',
  AUTHENTICATE_SUCCESS: 'AUTHENTICATE_SUCCESS',

  AUTHENTICATE_LOGOUT_REQUEST: 'AUTHENTICATE_LOGOUT_REQUEST',
  AUTHENTICATE_LOGOUT_SUCCESS: 'AUTHENTICATE_LOGOUT_SUCCESS',
  AUTHENTICATE_LOGOUT_FAILURE: 'AUTHENTICATE_LOGOUT_FAILURE',

  AUTHENTICATE_SET_ADFS: 'AUTHENTICATE_SET_ADFS',
};

import { combineReducers } from 'redux';

import app from './app/reducers';
import authenticate from './authenticate/reducers';
import currencySetting from './currencySetting/reducers';
import network from './network/reducers';
import project from './project/reducers';
import projects from './projects/reducers';
import projectUnitLayout from './projectUnitLayout/reducers';
import projectUnitLayouts from './projectUnitLayouts/reducers';
import projectUnitLayoutPictures from './projectUnitLayoutPictures/reducers';
import projectUnit from './projectUnit/reducers';
import projectUnits from './projectUnits/reducers';
import projectUnitsSummary from './projectUnitsSummary/reducers';

import siteplan from './siteplan/reducers';

export default combineReducers({
  app,
  authenticate,
  currencySetting,
  network,
  project,
  projects,
  projectUnitLayout,
  projectUnitLayouts,
  projectUnitLayoutPictures,
  projectUnit,
  projectUnits,
  projectUnitsSummary,

  siteplan,
});

import qs from 'qs';
// import config from '../../config';
import Endpoints from '../../endpoints';
import fetchUrl from '../../utils/fetchUrl';
import { handleError, resJson } from '../../utils/error';
import { getToken } from '../helpers';

import Types from './types';

const projectUnitsSummaryRequest = () => ({
  type: Types.PROJECT_UNITS_SUMMARY_REQUEST,
});

const projectUnitsSummarySuccess = ({ data, token }) => ({
  type: Types.PROJECT_UNITS_SUMMARY_SUCCESS,
  payload: {
    data,
    token,
  },
});

const projectUnitsSummaryFailure = message => ({
  type: Types.PROJECT_UNITS_SUMMARY_FAILURE,
  payload: {
    statusText: message,
  },
});

const projectUnitsSummaryReset = () => ({
  type: Types.PROJECT_UNITS_SUMMARY_RESET,
});

const setProjectUnitsSummaryLoading = (loading = false) => ({
  type: Types.PROJECT_UNITS_SUMMARY_SET_LOADING,
  payload: {
    loading,
  },
});

const fetchProjectUnitsSummary = (projectID, query = {}) => {
  const q = Object.assign({}, query);
  const url = `${Endpoints.PROJECT_UNITS_SUMMARY(projectID)}/?${qs.stringify(q)}`;
  const options = {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  let token;
  return (dispatch) => {
    dispatch(projectUnitsSummaryRequest());

    return dispatch(fetchUrl(url, options))
      .then((res) => {
        token = getToken(res);
        return resJson(res);
      })
      .then((data) => {
        dispatch(projectUnitsSummarySuccess({ data, token }));
      })
      .catch(err => dispatch(handleError(err, projectUnitsSummaryFailure)));
  };
};

export default {
  setProjectUnitsSummaryLoading,

  projectUnitsSummaryRequest,
  projectUnitsSummarySuccess,
  projectUnitsSummaryFailure,

  projectUnitsSummaryReset,

  fetchProjectUnitsSummary,
};

// @flow

import Loadable from 'react-loadable';
import LoadingPage from './components/LoadingPage';

const loading = LoadingPage;

export const AsyncHome = Loadable({
  loader: () => import('./containers/Home'),
  loading,
});
export const AsyncPreview = Loadable({
  loader: () => import('./containers/Preview'),
  loading,
});
export const AsyncWidget = Loadable({
  loader: () => import('./containers/MHubSiteplanIframeWidget'),
  loading,
});
export const AsyncNotFound = Loadable({
  loader: () => import('./containers/NotFound'),
  loading,
});
export const AsyncSalesChart = Loadable({
  loader: () => import('./containers/SalesChart'),
  loading,
});

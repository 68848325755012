
import config from '../../config';

import Types from './types';

const appSetBookApiURL = bookApiURL => ({
  type: Types.APP_SET_BOOK_API_URL,
  payload: {
    bookApiURL,
  },
});

const appSetShowroomURL = showroomURL => ({
  type: Types.APP_SET_SHOWROOM_URL,
  payload: {
    showroomURL,
  },
});

const appSetMarketplaceURL = marketplaceURL => ({
  type: Types.APP_SET_MARKETPLACE_URL,
  payload: {
    marketplaceURL,
  },
});

const appSetCompanyCode = companyCode => ({
  type: Types.APP_SET_COMPANY_CODE,
  payload: {
    companyCode,
  },
});

const appSetS3Bucket = (
  s3Bucket = config.s3.bookWeb.BUCKET,
  s3Region = config.s3.bookWeb.REGION,
) => ({
  type: Types.APP_SET_S3_BUCKET,
  payload: {
    s3Bucket,
    s3Region,
  },
});

const appReset = () => ({
  type: Types.APP_RESET,
});

export default {
  appSetBookApiURL,
  appSetShowroomURL,
  appSetMarketplaceURL,
  appSetCompanyCode,
  appSetS3Bucket,

  appReset,
};

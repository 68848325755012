import { createReducer } from '@mhub/web-redux-utilities';
import Types from './types';

const initialState = {
  loading: false,
  statusText: null,
  isError: false,
  data: null,
  token: null,
};

export default createReducer(initialState, {
  [Types.PROJECT_UNIT_LAYOUT_SET_LOADING]: (state, payload) => (
    Object.assign({}, state, {
      loading: payload.loading,
    })
  ),
  [Types.PROJECT_UNIT_LAYOUT_REQUEST]: state => (
    Object.assign({}, state, {
      loading: true,
      isError: false,
      statusText: null,
    })
  ),
  [Types.PROJECT_UNIT_LAYOUT_SUCCESS]: (state, payload) => (
    Object.assign({}, state, {
      loading: false,
      data: payload.data,
      token: payload.token,
    })
  ),
  [Types.PROJECT_UNIT_LAYOUT_FAILURE]: (state, payload) => (
    Object.assign({}, state, {
      loading: false,
      isError: true,
      statusText: payload.statusText,
    })
  ),
  [Types.PROJECT_UNIT_LAYOUT_RESET]: () => Object.assign({}, initialState),
});

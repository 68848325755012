import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './DetailField.css';

export default class DetailField extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    capital: PropTypes.bool,
    label: PropTypes.node,
    children: PropTypes.node,
    preview: PropTypes.bool,
    header: PropTypes.bool,
    icon: PropTypes.string,
  }
  static defaultProps = {
    className: null,
    capital: false,
    label: null,
    children: null,
    preview: false,
    header: false,
  }
  classNames = () => {
    const { className, capital, preview, header, icon } = this.props;
    const names = ['DetailField'];
    if (className) {
      names.push(className);
    }
    if (icon) {
      names.push('icon');
    }
    if (capital) {
      names.push('capital');
    }
    if (preview) {
      names.push('preview');
    }
    if (header) {
      names.push('header');
    }
    return names.join(' ');
  }
  render() {
    const { header, label, children, icon } = this.props;
    const className = this.classNames();
    return (
      <div className={className}>
        {header ? children : null}
        {(!header && (label || icon)) && (
          <div className="label">
            {icon && <img src={icon} alt={label} />}
            {label}
          </div>
        )}
        {!header && <div className="content">{children}</div>}
      </div>
    );
  }
}

/* eslint react/prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { encodeRedirect } from '../utils/url';

const RouteAuthenticated = ({ component: C, props: cProps, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      cProps.isAuthenticated
        ? <C {...props} {...cProps} />
        : <Redirect to={`/?redirect=${encodeRedirect(`${props.location.pathname}${props.location.search}`)}`} />
    )}
  />
);

RouteAuthenticated.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  props: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }),
};

RouteAuthenticated.defaultProps = {
  props: {
    isAuthenticated: false,
  },
};

export default RouteAuthenticated;

// namespace
export const SVGNS = 'http://www.w3.org/2000/svg';
export const XLINKNS = 'http://www.w3.org/1999/xlink';

// element id
export const SVG_MAIN = 'mhub-svg';
export const SVG_SITEPLAN = 'mhub-site-plan';
export const SVG_IMAGE = 'mhub-svg-img';
export const SVG_UNITS = 'mhub-units';
export const SVG_UNIT_PREFIX = 'mhub-id:';

// unit element id
export const MHUB_IDENTIFIER = 'mhub-';

import { createReducer } from '@mhub/web-redux-utilities';
import Types from './types';

import config from '../../config';

const initialState = {
  bookApiURL: config.gateways.BOOK_API_URL,
  showroomURL: config.gateways.SHOWROOM_URL,
  marketplaceURL: config.gateways.MARKETPLACE_URL,
  companyCode: '',
  s3Bucket: config.s3.bookWeb.BUCKET,
  s3Region: config.s3.bookWeb.REGION,
};

export default createReducer(initialState, {
  [Types.APP_SET_BOOK_API_URL]: (state, payload) => (
    Object.assign({}, state, {
      bookApiURL: payload.bookApiURL,
    })
  ),
  [Types.APP_SET_SHOWROOM_URL]: (state, payload) => (
    Object.assign({}, state, {
      showroomURL: payload.showroomURL,
    })
  ),
  [Types.APP_SET_MARKETPLACE_URL]: (state, payload) => (
    Object.assign({}, state, {
      marketplaceURL: payload.marketplaceURL,
    })
  ),
  [Types.APP_SET_COMPANY_CODE]: (state, payload) => (
    Object.assign({}, state, {
      companyCode: payload.companyCode,
    })
  ),
  [Types.APP_SET_S3_BUCKET]: (state, payload) => (
    Object.assign({}, state, {
      s3Bucket: payload.s3Bucket,
      s3Region: payload.s3Region,
    })
  ),
  [Types.APP_RESET]: () => Object.assign({}, initialState),
});

/* eslint-disable no-alert */
import Bugsnag from '@bugsnag/js';

import config from '../../config';
import Endpoints from '../../endpoints';
import fetchUrl from '../../utils/fetchUrl';
import { getSharePath } from '../../utils/url';
import { handleError, resJson } from '../../utils/error';

import AppActions from '../app/actions';

import { SUPER_ADMIN } from '../../constants/userGroups';
import { SHOWROOM } from '../../constants/apps';

import Types from './types';

const authenticateRequest = () => ({
  type: Types.AUTHENTICATE_REQUEST,
});

const authenticateSuccess = (data) => {
  Bugsnag.setUser(data.id, data.email, data.name);
  return {
    type: Types.AUTHENTICATE_SUCCESS,
    payload: {
      data,
    },
  };
};

const authenticateFailure = message => ({
  type: Types.AUTHENTICATE_FAILURE,
  payload: {
    statusText: message,
  },
});

const authenticateLogoutRequest = () => ({
  type: Types.AUTHENTICATE_LOGOUT_REQUEST,
});

const authenticateLogoutSuccess = () => ({
  type: Types.AUTHENTICATE_LOGOUT_SUCCESS,
});

const authenticateLogoutFailure = message => ({
  type: Types.AUTHENTICATE_LOGOUT_FAILURE,
  payload: {
    statusText: message,
  },
});


const setAuthenticateLoading = (loading = false) => ({
  type: Types.AUTHENTICATE_SET_LOADING,
  payload: {
    loading,
  },
});

const setAuthenticateIsAuthenticated = (isAuthenticated = false) => ({
  type: Types.AUTHENTICATE_SET_IS_AUTHENTICATED,
  payload: {
    isAuthenticated,
  },
});

const setAuthenticateAdfs = (provider = '') => ({
  type: Types.AUTHENTICATE_SET_ADFS,
  payload: {
    provider,
  },
});

const checkAuthenticated = () => {
  const url = `${config.gateways.API_URL}${Endpoints.AUTHENTICATE}`;
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
  };
  return (dispatch) => {
    dispatch(authenticateRequest());

    return dispatch(fetchUrl(url, options))
      .then(resJson)
      .then((payload) => {
        // app url
        const company = payload.affiliate &&
        payload.affiliate.company ? payload.affiliate.company : null;
        if (company) {
          let bookApiURL;
          const apps = company.apps || [];
          const groups = payload.groups || [];
          if (groups.some(group => group.code === SUPER_ADMIN)) {
            bookApiURL = prompt('Enter Book API:', config.gateways.BOOK_API_URL);
          } else if (apps.length > 0) {
            const app = apps.find(a => a.appID === SHOWROOM && a.hasAccess);
            if (app) {
              const { webURL, apiURL } = app;
              bookApiURL = apiURL;
              if (webURL) {
                dispatch(AppActions.appSetShowroomURL(webURL));
              }
            }
          }
          if (bookApiURL) {
            dispatch(AppActions.appSetBookApiURL(bookApiURL));
          }

          // update bucket
          let s3Bucket = config.s3.bookWeb.BUCKET;
          let s3Region = config.s3.bookWeb.REGION;
          dispatch(fetchUrl('/app/', options))
            .then(resJson)
            .then((result) => {
              if (result) {
                const { webAwsBucket, webAwsRegion } = result;
                if (webAwsBucket) {
                  s3Bucket = webAwsBucket;
                }
                if (webAwsRegion) {
                  s3Region = webAwsRegion;
                }
              } else if (company.bucketName) {
                s3Bucket = company.bucketName;
              }
              if (s3Bucket || s3Region) {
                dispatch(AppActions.appSetS3Bucket(s3Bucket, s3Region));
              }
            })
            .catch(err => dispatch(handleError(err, authenticateFailure)));
        }


        dispatch(authenticateSuccess(payload));
        return payload;
      })
      .catch(err => dispatch(handleError(err, authenticateFailure)));
  };
};

const checkShare = () => {
  const url = `${config.gateways.API_URL}/share/${getSharePath()}/`;
  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'no-cache',
      'X-Share-Token': config.app.shareToken,
      Pragma: 'no-cache',
    },
  };
  return (dispatch) => {
    dispatch(authenticateRequest());
    return dispatch(fetchUrl(url, options))
      .then(resJson)
      .then((payload) => {
        dispatch(AppActions.appSetBookApiURL(payload.showroomAPIURL));
        dispatch(AppActions.appSetCompanyCode(`/${getSharePath()}`));

        // update bucket
        let s3Bucket = config.s3.bookWeb.BUCKET;
        let s3Region = config.s3.bookWeb.REGION;
        dispatch(fetchUrl('/app/', options))
          .then(resJson)
          .then((result) => {
            if (result) {
              const { webAwsBucket, webAwsRegion } = result;
              if (webAwsBucket) {
                s3Bucket = webAwsBucket;
              }
              if (webAwsRegion) {
                s3Region = webAwsRegion;
              }
            }
            if (s3Bucket || s3Region) {
              dispatch(AppActions.appSetS3Bucket(s3Bucket, s3Region));
            }
          })
          .catch(err => dispatch(handleError(err)));

        dispatch(setAuthenticateIsAuthenticated(true));
        dispatch(setAuthenticateLoading(false));
        return payload;
      })
      .catch(err => dispatch(handleError(err, authenticateFailure)));
  };
};

const checkSharedWidget = (companyCode) => {
  const url = `${config.gateways.API_URL}/share/${companyCode}/`;
  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'no-cache',
      'X-Share-Token': config.app.shareToken,
      Pragma: 'no-cache',
    },
  };
  return (dispatch) => {
    dispatch(authenticateRequest());
    return dispatch(fetchUrl(url, options))
      .then(resJson)
      .then((payload) => {
        dispatch(AppActions.appSetBookApiURL(payload.showroomAPIURL));
        dispatch(AppActions.appSetCompanyCode(`/${companyCode}`));

        // update bucket
        let s3Bucket = config.s3.bookWeb.BUCKET;
        let s3Region = config.s3.bookWeb.REGION;
        dispatch(fetchUrl('/app/', options))
          .then(resJson)
          .then((result) => {
            if (result) {
              const { webAwsBucket, webAwsRegion } = result;
              if (webAwsBucket) {
                s3Bucket = webAwsBucket;
              }
              if (webAwsRegion) {
                s3Region = webAwsRegion;
              }
            }
            if (s3Bucket || s3Region) {
              dispatch(AppActions.appSetS3Bucket(s3Bucket, s3Region));
            }
          })
          .catch(err => dispatch(handleError(err)));

        dispatch(setAuthenticateIsAuthenticated(true));
        dispatch(setAuthenticateLoading(false));
        return payload;
      })
      .catch(err => dispatch(handleError(err, authenticateFailure)));
  };
};

const logoutAndRedirect = () => async (dispatch) => {
  await dispatch(authenticateLogoutRequest());
  window.location.href = `${config.gateways.START_URL}/logout`;
  return Promise.resolve();
};

export default {
  setAuthenticateIsAuthenticated,
  setAuthenticateLoading,
  setAuthenticateAdfs,

  authenticateRequest,
  authenticateSuccess,
  authenticateFailure,

  authenticateLogoutRequest,
  authenticateLogoutSuccess,
  authenticateLogoutFailure,

  checkAuthenticated,
  checkShare,
  checkSharedWidget,
  logoutAndRedirect,
};

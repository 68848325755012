import Types from './types';

const setSiteplanCount = count => ({
  type: Types.SITEPLAN_SET_COUNT,
  payload: { count },
});

const setSiteplanFilterStatus = filterStatus => ({
  type: Types.SITEPLAN_SET_FILTER_STATUS,
  payload: { filterStatus },
});

const setSiteplanFilterLayout = filterLayout => ({
  type: Types.SITEPLAN_SET_FILTER_LAYOUT,
  payload: { filterLayout },
});

const setSiteplanFilterBlock = filterBlock => ({
  type: Types.SITEPLAN_SET_FILTER_BLOCK,
  payload: { filterBlock },
});

const setSiteplanFilterLevel = filterLevel => ({
  type: Types.SITEPLAN_SET_FILTER_LEVEL,
  payload: { filterLevel },
});

const setSiteplanShowDetails = showDetails => ({
  type: Types.SITEPLAN_SET_SHOW_DETAILS,
  payload: { showDetails },
});

const setSiteplanFilterCategory = filterCategory => ({
  type: Types.SITEPLAN_SET_FILTER_CATEGORY,
  payload: { filterCategory },
});

const siteplanReset = () => ({
  type: Types.SITEPLAN_RESET,
});

const siteplanAddProjectUnitID = unitID => ({
  type: Types.SITEPLAN_ADD_PROJECT_UNIT_ID,
  payload: { unitID },
});

export default {
  setSiteplanCount,
  setSiteplanFilterStatus,
  setSiteplanFilterLayout,
  setSiteplanFilterBlock,
  setSiteplanFilterLevel,
  setSiteplanShowDetails,
  setSiteplanFilterCategory,

  siteplanReset,
  siteplanAddProjectUnitID,
};

import 'whatwg-fetch';
import clone from 'clone';
import Auth from '@aws-amplify/auth';
import Bugsnag from '@bugsnag/js';

import Actions from '../redux/actions';
import config from '../config';
import Selectors from '../redux/selectors';
import { parseParams } from './url';
import { FetchError } from './error';

const asyncTimeout = duration => new Promise(resolve => setTimeout(resolve, duration));

const signInOauth = (provider, nextBase64) => new Promise(async (resolve, reject) => {
  const authConfig = Auth.configure();
  const { oauth, userPoolWebClientId: clientId } = authConfig;
  if (!oauth) {
    return reject();
  }

  const {
    domain,
    redirectSignIn,
    scope,
    responseType,
  } = oauth;

  const query = {
    client_id: clientId,
    response_type: responseType,
    scope: scope.join(' '),
    redirect_uri: redirectSignIn,
  };

  if (provider) {
    query.identity_provider = provider.toUpperCase();
  }
  if (nextBase64) {
    query.state = nextBase64;
  }

  // The url of the Cognito Hosted UI
  const url = parseParams(`https://${domain}/oauth2/authorize`, query);

  // Launch hosted UI
  window.location.assign(url);
  return resolve();
});

export function redirectToLogin(next = '/') {
  return () => {
    let nextBase64 = '';
    if (next) {
      try {
        nextBase64 = btoa(`${window.location.origin}${next}`);
      } catch (e) {
        window.bugsnagClient.notify(e);
      }
    }
    const adfsProvider = Selectors.getStateAdfsProvider();
    if (adfsProvider) {
      signInOauth(adfsProvider, nextBase64);
    } else {
      window.location.href = `${config.gateways.START_URL}/login?redirect=${nextBase64}`;
    }
  };
}

const isShareMode = config.app.shareMode;

const setHeaderOrigin = (opts = {}) => {
  const headers = opts.headers ? clone(opts.headers) : {};
  let { origin } = window.location;
  const { protocol, hostname, port } = window.location;
  if (!origin) {
    origin = `${protocol}//${hostname}`;
    origin += `${(port ? `:${port}` : '')}`;
  }
  headers['X-Origin'] = origin;
  return Object.assign({}, opts, { headers });
};

export default function fetchUrl(url, options = {}, retry = true) {
  let adfsProvider = '';
  let user;
  return async (dispatch) => {
    if (!isShareMode) {
      try {
        user = await Auth.currentAuthenticatedUser({ bypassCache: !retry });
      } catch (error) {
        if (
          retry && (
            (typeof error === 'string' && !error.match(/not authenticate/i)) || (
              typeof error === 'object' && error.code !== 'PasswordResetRequiredException'
              && (error.message.match(/not authenticate/i)
                || error.message.match(/token/i))
            )
          )
        ) {
          // delay retry to check new token
          await asyncTimeout(1000);
          return dispatch(fetchUrl(url, options, false));
        }
        await dispatch(redirectToLogin(window.location.pathname));
        return Promise.reject(typeof error === 'string' ? new FetchError(error) : error);
      }

      if (user) {
        const attributes = user.getSignInUserSession().getIdToken().decodePayload();
        if (attributes) {
          let { identities } = attributes;
          if (identities) {
            if (typeof identities === 'string') {
              identities = JSON.parse(identities);
            }
            if (Array.isArray(identities)) {
              adfsProvider = identities.reduce((result, identity) => {
                if (result === '' && identity.providerName) {
                  return identity.providerName;
                }
                return result;
              }, adfsProvider);
            }
          }

          // bugsnag set user
          const { email, name } = attributes;
          const bugsnagUser = Bugsnag.getUser();
          if (!bugsnagUser || !bugsnagUser.id) {
            Bugsnag.setUser(
              attributes['cognito:username'],
              email,
              name,
            );
          }
        }
      }


      const stateAdfsProvider = Selectors.getStateAdfsProvider();
      if (adfsProvider && adfsProvider !== stateAdfsProvider) {
        dispatch(Actions.setAuthenticateAdfs(adfsProvider));
      }
    }

    let newURL = url;
    if (!/^(f|ht)tps?:\/\//i.test(url)) {
      // add BOOK_API_URL if there's no https in original url
      const bookApiURL = Selectors.getStateBookApiURL();
      if (isShareMode) {
        newURL = `${bookApiURL}/share${url}`;
      } else {
        newURL = `${bookApiURL}${url}`;
      }
    }
    const opts = setHeaderOrigin(options);
    if (isShareMode) {
      delete opts.credentials;
    }

    let res;
    try {
      res = await fetch(newURL, opts);
    } catch (err) {
      if (err.message.match(/failed to fetch/i)) {
        await dispatch(Actions.networkError());
      } else if (retry) {
        return dispatch(fetchUrl(url, options, false));
      }
      return Promise.reject(err);
    }

    await dispatch(Actions.networkOK());
    if (!res.ok) {
      let message = 'An error occurred';
      if (res.status === 401) {
        if (isShareMode) {
          message = 'Access denied';
        } else {
          await dispatch(redirectToLogin(window.location.pathname));
        }
      }
      if (res.status === 403) {
        message = 'Access denied';
      }
      if (res.status === 404) {
        message = 'Not found';
      }
      if (res.status === 428 && retry) {
        return dispatch(fetchUrl(url, options, false));
      }
      return Promise.reject(new FetchError(message, res));
    }
    return res;
  };
}

import _ from 'underscore';
import { createReducer } from '@mhub/web-redux-utilities';
import Types from './types';

const initialState = {
  loading: false,
  statusText: null,
  isError: false,
  data: [],
  pagination: {},
  token: null,
  refreshAt: null,
};

function refreshData(state, payload) {
  const { data } = state;
  let newData = data;
  const updatedUnitIDs = _.intersection(data.map(u => u.id), payload.data.map(u => u.id));
  if (updatedUnitIDs.length > 0) {
    newData = data.map((unit) => {
      if (updatedUnitIDs.some(id => id === unit.id)) {
        return payload.data.find(u => u.id === unit.id);
      }
      return unit;
    });
  }
  return newData;
}


export default createReducer(initialState, {
  [Types.PROJECT_UNITS_SET_LOADING]: (state, payload) => (
    Object.assign({}, state, {
      loading: payload.loading,
    })
  ),
  [Types.PROJECT_UNITS_REQUEST]: state => (
    Object.assign({}, state, {
      loading: true,
      isError: false,
      statusText: null,
    })
  ),
  [Types.PROJECT_UNITS_SUCCESS]: (state, payload) => (
    Object.assign({}, state, {
      loading: false,
      data: payload.pagination.offset === 0 ? payload.data : state.data.concat(payload.data),
      pagination: payload.pagination,
      token: payload.token,
      refreshAt: payload.refreshAt,
    })
  ),
  [Types.PROJECT_UNITS_FAILURE]: (state, payload) => (
    Object.assign({}, state, {
      loading: false,
      isError: true,
      statusText: payload.statusText,
    })
  ),
  [Types.PROJECT_UNITS_REFRESH]: (state, payload) => (
    Object.assign({}, state, {
      loading: false,
      data: refreshData(state, payload),
      pagination: payload.pagination,
      token: payload.token,
      refreshAt: payload.refreshAt,
    })
  ),
  [Types.PROJECT_UNITS_RESET]: () => Object.assign({}, initialState),
});

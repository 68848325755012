export const getHeader = (response, key, defaultValue) => {
  if (response && response.headers) {
    return response.headers.get(key);
  }
  return defaultValue;
};

export const getToken = response => getHeader(response, 'X-Csrf-Token');

export const getCompanyID = response => getHeader(response, 'X-Company-ID');

export const getCount = response => getHeader(response, 'm-data-count', 0);

export const getRefreshAt = response => getHeader(response, 'm-refresh-at');

export const getData = (payload, limit = 0) => {
  if (!payload) {
    return [];
  }
  if (limit < 1) {
    return payload;
  }
  return payload.slice(0, limit - 1);
};

export const getHasMore = (data, limit = 0) => data.length > 0 && data.length === limit;

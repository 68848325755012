import React from 'react';
import { Portal } from '@mhub/web-components';

import imgRotate from '../assets/icons/ic-rotate.svg';
import './RotateDeviceWarning.css';

const RotateDeviceWarning = () => (
  <Portal node={document.body}>
    <div className="RotateDeviceWarning">
      <img src={imgRotate} alt="Rotate Device" />
      <h4>Rotate device</h4>
      <p>This ensures a better viewing experience of the Siteplan.</p>
    </div>
  </Portal>
);

export default RotateDeviceWarning;

export function getMatrixPosLimit(scale, value, oriValue, size, svgSize) {
  const matrixValue = svgSize * scale;
  const diff = matrixValue - size;
  const min = matrixValue * 0.5;
  const max = -(diff + min);

  let newValue = oriValue;
  if (value !== null && value !== undefined) {
    newValue = value;
  }
  if (newValue <= max) {
    newValue = max;
  }
  if (newValue >= min) {
    newValue = min;
  }
  if (matrixValue + (min * 2) <= size) {
    // center svg if svg width is smaller than viewbox
    newValue = Math.abs(diff) / 2;
  }
  return newValue;
}

export function zoomIn(matrix, width, height, scale = 1) {
  const newMatrix = [].concat(matrix);
  for (let i = 0; i < newMatrix.length; i += 1) {
    newMatrix[i] *= scale;
  }
  newMatrix[4] += ((1 - scale) * (width / 2));
  newMatrix[5] += ((1 - scale) * (height / 2));
  return newMatrix;
}

export function zoomOut(matrix, width, height, scale = 1) {
  const newMatrix = [].concat(matrix);
  newMatrix[4] -= ((1 - scale) * (width / 2));
  newMatrix[5] -= ((1 - scale) * (height / 2));
  for (let i = 0; i < newMatrix.length; i += 1) {
    newMatrix[i] /= scale;
  }
  return newMatrix;
}

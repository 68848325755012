const defaultCurrencySetting = {
  displayDecimal: true,
  isAbbreviationEnabled: false,
  abbreviationDecimalPoints: 2,
  decimalSeparator: '.',
  thousandsSeparator: ',',
  millionsSeparator: ',',
  billionsSeparator: ',',
};

const getAbbreviationFormat = (amountWOCArr, dp) => {
  let abbSplice = -3;
  let abbreviation = 'K';

  if (amountWOCArr.length > 6 && amountWOCArr.length <= 9) {
    abbSplice = -6;
    abbreviation = 'M';
  } else if (amountWOCArr.length > 9) {
    abbSplice = -9;
    abbreviation = 'B';
  }

  amountWOCArr.splice(abbSplice, 0, '.');
  const a = amountWOCArr.join('');
  const splits = a.split('.');

  if (dp <= 0) {
    return `${splits[0]}${abbreviation}`;
  }

  let sb = splits[1].split('');
  if (sb.length > dp) {
    sb = sb.splice(0, dp);
  } else if (sb.length < dp) {
    let maxLength = 3;
    if (abbreviation === 'M') maxLength = 6;
    if (abbreviation === 'B') maxLength = 9;
    const fillLength = dp.length <= maxLength ? dp - sb.length : maxLength - sb.length;
    sb = [...sb, ...Array(fillLength).fill('0')];
  }
  return `${splits[0]}.${sb.join('')}${abbreviation}`;
};

// getCurrencySettingFormat returns amount in currency setting format
export function getCurrencySettingFormat(amount = 0, currencySetting = defaultCurrencySetting) {
  const cs = currencySetting && currencySetting.id ? currencySetting : defaultCurrencySetting;
  const {
    displayDecimal,
    isAbbreviationEnabled,
    abbreviationDecimalPoints,
    decimalSeparator: ds,
    thousandsSeparator: ts,
    millionsSeparator: ms,
    billionsSeparator: bs,
  } = cs;
  let fullAmount = amount.toString();

  const amountSplits = fullAmount.split('.');
  const amountWOcents = amountSplits[0].toString();
  const cents = amountSplits.length > 1 ? amountSplits[1].toString() : '00';
  const lenAmountWOcents = amountWOcents.length;

  const a = amountWOcents.split('');
  if (isAbbreviationEnabled && lenAmountWOcents > 3) {
    return getAbbreviationFormat(a, abbreviationDecimalPoints);
  } else if (lenAmountWOcents > 3 && lenAmountWOcents <= 6) {
    a.splice(-3, 0, ts);
  } else if (lenAmountWOcents > 6 && lenAmountWOcents <= 9) {
    a.splice(-6, 0, ms);
    a.splice(-3, 0, ts);
  } else if (lenAmountWOcents > 9) {
    a.splice(-9, 0, bs);
    a.splice(-6, 0, ms);
    a.splice(-3, 0, ts);
  }
  fullAmount = a.join('');

  if (displayDecimal) {
    fullAmount = `${fullAmount}${ds}${cents}`;
  }

  return fullAmount;
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Dropdown, Label, utils } from '@mhub/web-components';

import Selectors from '../redux/selectors';
import { TRANSLATION_FILES } from '../constants/translation';

import './LayoutFilter.css';

class LayoutFilter extends PureComponent {
  static mapStateToProps = state => ({
    auth: Selectors.getAuthenticateData(state),
    unitLayouts: Selectors.getProjectUnitLayoutsData(state),
  })
  static propTypes = {
    auth: PropTypes.shape({
      groups: PropTypes.array,
    }),
    onChange: PropTypes.func,
    value: PropTypes.string,
    floating: PropTypes.bool,
    // redux
    unitLayouts: PropTypes.arrayOf(PropTypes.object),
  }
  static contextTypes = {
    store: PropTypes.object,
  }
  static defaultProps = {
    auth: null,
    onChange: null,
    value: null,
    floating: false,
    // redux
    unitLayouts: [],
  }
  classNames = () => {
    const { floating } = this.props;
    const names = ['LayoutFilter'];
    if (floating) {
      names.push('floating');
    }
    return names.join(' ');
  }
  render() {
    const { onChange, value, floating, unitLayouts, t } = this.props;
    if (!utils.arrayHasItems(unitLayouts)) {
      return null;
    }
    const placeholder = t(floating ? 'Select {{name}}' : 'None', { name: t('Type')});
    const classNames = this.classNames();
    const child = (
      <Dropdown
        className={classNames}
        bordered
        fluid={!floating}
        withPortal
        name="layout"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        options={[
          {
            title: placeholder,
            value: null,
          },
        ].concat(unitLayouts.map(l => ({
          title: l.name,
          value: l.id,
        })))}
      />
    );
    if (floating) {
      return child;
    }
    return (
      <Label content={t('Type')} noHelper>
        {child}
      </Label>
    );
  }
}

export default withTranslation(TRANSLATION_FILES.screens)(connect(LayoutFilter.mapStateToProps)(LayoutFilter));

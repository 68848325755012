import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Image, utils } from '@mhub/web-components';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';

import Selectors from '../redux/selectors';

import icBath from '../assets/icons/ic-bath-grey.svg';
import icBed from '../assets/icons/ic-bed-grey.svg';
import icCar from '../assets/icons/ic-car-grey.svg';
import iconBack from '../assets/icons/ic-back-small.svg';
import iconNext from '../assets/icons/ic-next.svg';
import iconClose from '../assets/icons/ic-close.svg';

import AppMenuButton from './AppMenuButton';
import LoadImage from './LoadImage';
import './ImageViewer.css';
import './Modal.css';

class ImageViewer extends PureComponent {
  static mapStateToProps = state => ({
    unitLayout: Selectors.getProjectUnitLayoutData(state),
    pictures: Selectors.getProjectUnitLayoutPicturesData(state),
    picturesLoading: Selectors.getProjectUnitLayoutPicturesLoading(state),
    bucket: Selectors.getAppS3Bucket(state),
    region: Selectors.getAppS3Region(state),
  })
  static propTypes = {
    unitLayout: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      specifications: PropTypes.arrayOf(PropTypes.object),
    }),
    pictures: PropTypes.arrayOf(PropTypes.object),
    picturesLoading: PropTypes.bool,
    onClose: PropTypes.func,
    active: PropTypes.bool,
    headerNode: PropTypes.func,
    bucket: PropTypes.string,
    region: PropTypes.string,
  }
  static defaultProps = {
    unitLayout: null,
    pictures: [],
    picturesLoading: false,
    headerNode: () => <h4>Layout Type</h4>,
    onClose: null,
    active: false,
    bucket: null,
    region: null,
  }
  constructor(props) {
    super(props);
    const { pictures, picturesLoading, unitLayout } = props;
    const unitLayoutID = unitLayout ? unitLayout.id : null;
    let currentPicture = null;
    if (!picturesLoading && utils.arrayHasItems(pictures)) {
      [currentPicture] = pictures;
    }
    this.state = {
      prevProps: { unitLayoutID },
      showViewer: false,
      currentPicture,
      isNew: true,
    };
  }
  static getDerivedStateFromProps(props, state) {
    const { prevProps } = state;
    const { pictures, picturesLoading, unitLayout } = props;
    const unitLayoutID = unitLayout ? unitLayout.id : null;
    let { currentPicture, isNew } = state;

    if (prevProps.unitLayoutID !== unitLayoutID) {
      isNew = true;
    }
    if (isNew && !picturesLoading) {
      if (utils.arrayHasItems(pictures)) {
        [currentPicture] = pictures;
      } else {
        currentPicture = null;
      }
    }
    return {
      prevProps: { unitLayoutID },
      currentPicture,
      isNew,
    };
  }
  getSpecificationIcon = (name) => {
    switch (name) {
      case 'bathrooms':
        return icBath;
      case 'bedrooms':
        return icBed;
      case 'parking Lots':
        return icCar;
      default:
        return null;
    }
  }
  // specification filter
  filterSpecification = (spec) => {
    switch (spec.name.toLowerCase()) {
      case 'bathrooms':
      case 'bedrooms':
      case 'parking lots':
        return true;
      default:
        return false;
    }
  }
  filterSpecificationBuiltUpArea = spec => spec.name.toLowerCase() === 'built-up area'

  handlePreviousPicture = () => {
    const { pictures } = this.props;
    const { currentPicture } = this.state;
    const currentIndex = pictures.findIndex(p => p.id === currentPicture.id);
    let previousIndex = currentIndex - 1;
    if (previousIndex < 0) {
      previousIndex = pictures.length - 1;
    }
    this.handleChangePicture(pictures[previousIndex]);
  }
  handleNextPicture = () => {
    const { pictures } = this.props;
    const { currentPicture } = this.state;
    const currentIndex = pictures.findIndex(p => p.id === currentPicture.id);
    let nextIndex = currentIndex + 1;
    if (nextIndex >= pictures.length) {
      nextIndex = 0;
    }
    this.handleChangePicture(pictures[nextIndex]);
  }
  handleChangePicture = currentPicture => this.setState({ currentPicture, isNew: false })
  handleOnChange = (pic, idx) => {
    const { pictures } = this.props;
    this.handleChangePicture(pictures[idx].id);
  }
  handleShowViewer = () => this.setState({ showViewer: true })
  handleCloseViewer = () => this.setState({ showViewer: false })

  // render
  renderHeader = () => {
    const { headerNode, unitLayout, onClose } = this.props;
    return (
      <div className="header">
        {
          window.FlutterApp && window.FlutterApp.postMessage
            ? <div className="placeholder" />
            : (
              <AppMenuButton
                icon={iconBack}
                onClick={onClose}
              />
            )
        }
        {headerNode({ unitLayout, onClose })}
        {
          window.FlutterApp && window.FlutterApp.postMessage
            ? (
              <AppMenuButton
                icon={iconClose}
                onClick={onClose}
              />
            ) : <div className="placeholder" />
        }
      </div>
    );
  }
  renderSpecifications = (specifications) => {
    if (
      utils.arrayHasItems(specifications) &&
      specifications.filter(this.filterSpecification).length > 0
    ) {
      return (
        <div className="specifications">
          {
            specifications.reduce((result, spec) => {
              const iconSrc = this.getSpecificationIcon(spec.name.toLowerCase());
              if (iconSrc) {
                result.push((
                  <div key={spec.name}>
                    {spec.value}
                    {iconSrc ? <img className="icon" src={iconSrc} alt={spec.name} /> : null}
                  </div>
                ));
              }
              return result;
            }, [])
          }
        </div>
      );
    }
    return null;
  }
  renderLayout = () => {
    const { unitLayout } = this.props;
    if (unitLayout) {
      const builtUpAreaField = utils.arrayHasItems(unitLayout.specifications) ?
        unitLayout.specifications.find(this.filterSpecificationBuiltUpArea) : null;
      return (
        <div className="layout">
          <h4 className="title">{unitLayout.name}</h4>
          {builtUpAreaField ? <div className="built-up">{builtUpAreaField.value}</div> : null}
          {this.renderSpecifications(unitLayout.specifications)}
        </div>
      );
    }
    return null;
  }
  renderPicture = () => {
    const { currentPicture } = this.state;
    if (currentPicture) {
      const { key, label } = currentPicture;
      return (
        <div className="picture">
          {label && <div className="label">{label}</div>}
          <LoadImage
            pictureKey={key}
            onClick={this.handleShowViewer}
            alt={label || 'Unit Layout'}
          />
        </div>
      );
    }
    return null;
  }
  renderNavigation = () => {
    const { pictures } = this.props;
    if (pictures && pictures.length > 1) {
      const { currentPicture } = this.state;
      return (
        <div className="navigation">
          <AppMenuButton
            icon={iconBack}
            onClick={this.handlePreviousPicture}
          />
          <div className="dots">
            {
              pictures.map((picture, idx) => (
                <div
                  key={idx}
                  className={`dot ${currentPicture && picture.id === currentPicture.id ? 'selected' : ''}`}
                  onClick={() => this.handleChangePicture(picture.id)}
                >
                  <div />
                </div>
              ))
            }
          </div>
          <AppMenuButton
            icon={iconNext}
            onClick={this.handleNextPicture}
          />
        </div>
      );
    }
    return null;
  }
  render() {
    const { active, onClose, bucket, region } = this.props;
    const { showViewer, currentPicture } = this.state;
    return (
      <Modal
        isOpen={active}
        onRequestClose={onClose}
        closeTimeoutMS={500}
        className="modal"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="ImageViewer">
          {this.renderHeader()}
          <div className="content">
            {this.renderLayout()}
            {this.renderPicture()}
            {this.renderNavigation()}
          </div>
        </div>
        {
          currentPicture && (
            <Modal
              isOpen={showViewer}
              closeTimeoutMS={500}
              className="modal"
              overlayClassName="modal-overlay"
              ariaHideApp={false}
            >
              <PinchZoomPan
                position="center"
                maxScale={3}
              >
                <img
                  src={Image.s3File(`${currentPicture.key}100.jpg`, { bucket, region })}
                  alt={currentPicture.label || 'Unit Layout'}
                />
              </PinchZoomPan>
              <AppMenuButton
                className="viewer-close"
                icon={iconClose}
                onClick={this.handleCloseViewer}
              />
            </Modal>
          )
        }
      </Modal>
    );
  }
}

export default connect(ImageViewer.mapStateToProps)(ImageViewer);

const getData = state => state.data;

const getIsError = state => state.isError;

const getLoading = state => state.loading;

const getPagination = state => state.pagination;

const getToken = state => state.token;

const getCompanyID = state => state.companyID;

export default {
  getData,
  getIsError,
  getLoading,
  getPagination,
  getToken,
  getCompanyID,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Selectors from '../redux/selectors';

import AppMenu from './AppMenu';
import FilterDetails from './FilterDetails';
import FilterMenu from './FilterMenu';
import './AppHeader.css';
import config from '../config';
import { isIframe } from '../utils/url';
import { namespaces, TRANSLATION_FILES } from '../constants/translation';

class AppHeader extends PureComponent {
  static mapStateToProps = state => ({
    pathname: state.router.location.pathname,
    auth: Selectors.getAuthenticateData(state),
    project: Selectors.getProjectData(state),
    projectIsError: Selectors.getProjectIsError(state),
  })
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    title: PropTypes.node,
    project: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    projectIsError: PropTypes.bool,
  }
  static contextTypes = {
    store: PropTypes.object,
  }
  static defaultProps = {
    title: config.app.shareMode ? '' : 'Projects',
    project: null,
    projectIsError: false,
  }
  classNames = () => {
    const { pathname, projectIsError } = this.props;
    const names = ['AppHeader'];
    if (pathname.includes('/preview/') && !projectIsError) {
      names.push('preview');
    }
    if (window.FlutterApp && window.FlutterApp.postMessage) {
      names.push('flutter');
    }
    return names.join(' ');
  }
  renderTitle = () => {
    const { pathname, project, projectIsError, title, t } = this.props;
    if (pathname.includes('/not_found/')) {
      return null;
    }
    if ((pathname.includes('/preview/') || pathname.includes('/siteplan-widget/')) && !projectIsError) {
      return <h4 className="title">{project ? project.name : `${t('Loading', { ns: TRANSLATION_FILES.translation })}...`}</h4>;
    }
    return <h4 className="title">{title && t(title)}</h4>;
  }
  render() {
    const { pathname } = this.props;
    const classNames = this.classNames();
    const isSimpleMode = isIframe() || pathname.includes('/siteplan-widget/');
    return (
      <div className={classNames}>
        <div className="row">
          {!isSimpleMode && <AppMenu />}
          {this.renderTitle()}
          <FilterDetails
            floating={isSimpleMode}
          />
          <FilterMenu
            floating={isSimpleMode}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation(namespaces)(connect(AppHeader.mapStateToProps)(AppHeader));

import { createReducer } from '@mhub/web-redux-utilities';
import Types from './types';

const initialState = {
  count: 0,
  filterStatus: null,
  filterLayout: null,
  filterBlock: null,
  filterLevel: null,
  showDetails: true,
  projectUnitIDs: [],
  filterCategory: null,
};

export default createReducer(initialState, {
  [Types.SITEPLAN_SET_COUNT]: (state, payload) => (
    Object.assign({}, state, {
      count: payload.count,
    })
  ),
  [Types.SITEPLAN_SET_FILTER_STATUS]: (state, payload) => (
    Object.assign({}, state, {
      filterStatus: payload.filterStatus,
    })
  ),
  [Types.SITEPLAN_SET_FILTER_LAYOUT]: (state, payload) => (
    Object.assign({}, state, {
      filterLayout: payload.filterLayout,
    })
  ),
  [Types.SITEPLAN_SET_FILTER_BLOCK]: (state, payload) => (
    Object.assign({}, state, {
      filterBlock: payload.filterBlock,
    })
  ),
  [Types.SITEPLAN_SET_FILTER_LEVEL]: (state, payload) => (
    Object.assign({}, state, {
      filterLevel: payload.filterLevel,
    })
  ),
  [Types.SITEPLAN_SET_SHOW_DETAILS]: (state, payload) => (
    Object.assign({}, state, {
      showDetails: payload.showDetails,
    })
  ),
  [Types.SITEPLAN_ADD_PROJECT_UNIT_ID]: (state, payload) => {
    const projectUnitIDs = state.projectUnitIDs;
    if (payload.unitID) {
      if (!projectUnitIDs.some((id) => id === payload.unitID)) {
        projectUnitIDs.push(payload.unitID);
      }
    }
    return (
      Object.assign({}, state, {
        projectUnitIDs,
      })
    );
  },
  [Types.SITEPLAN_SET_FILTER_CATEGORY]: (state, payload) => (
    Object.assign({}, state, {
      filterCategory: payload.filterCategory,
    })
  ),
  [Types.SITEPLAN_RESET]: state => (
    Object.assign({}, initialState, {
      showDetails: state.showDetails,
    })
  ),
});
